
import React, { useCallback, useEffect, useState } from "react"
import { handlePromise } from "../../../utils/handlePromisse"
import { getAllTutorial } from "../../../services/tutorialService"
import { TutorialModel } from "../../../models/TutorialModel"
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import palette from "../../../theme/palette";
import { AdmLayout } from "../../../layouts/base/adm.layout";
import { FiberNew } from "@material-ui/icons";
import { Alert } from "@material-ui/core";
import styled from "styled-components";

const YoutubeContainer = styled.iframe`
  width: 560px;
  height: 315px;
  margin: auto;

  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
`

const ExplanationBox = styled.div`
  .alert-info {
    background-color: palette.primary.main;
    margin-top: 10px;
    svg {
      color: palette.primary.main;
    }
  }
`

export const TutorialPage = () => {
    const [allTutorial, setAllTutorial] = React.useState<TutorialModel[]>([]);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);
    
    const fetchAllTutorial = useCallback(async() => {
        const [result, error] = await handlePromise(getAllTutorial())
        setAllTutorial(result.data as TutorialModel[])
    },[])

    useEffect(() => {
        fetchAllTutorial()
    },[fetchAllTutorial])

    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedAccordion(isExpanded ? panel : false);
    };
    
    return (
        <AdmLayout hasLogo={true} loading={openBackdrop}>
            <h2 style={{textAlign: 'center', color: palette.secondary.main}}>Tutoriais da plataforma</h2>
                <ExplanationBox style={{ display: 'flex', justifyContent: 'center', marginBottom:'10px', width: '100%' }}>
                    <Alert className="alert-info" severity="info" sx={{width: '100%'}}>Explore como a plataforma pode revolucionar a sua maneira de buscar ativos e prescrever fórmulas. Clique no tópico que você deseja conhecer melhor e assista nossos tutoriais.</Alert>
                </ExplanationBox>
            {allTutorial
                .filter(tutorial => tutorial.enabled != false)
                .map((tutorial, index) => (
                <Accordion 
                    key={index}
                    expanded={expandedAccordion === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}
                    sx={{backgroundColor: palette.primary.main, marginBottom: '4px', width: '100%', }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        sx={{position: 'relative'}}
                    > 
                       
                    <Typography sx={{color: 'white', fontWeight: 'bold', display: 'flex', alignContent:'center'}}>{tutorial.title} 
                        {
                            tutorial.new_icon? <FiberNew sx={{color: '#19253D', marginLeft: '5px'}}/> : <></>
                        }
                    </Typography>
                    
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Typography sx={{marginBottom: '10px',fontWeight: 'bold', color: palette.secondary.main, fontSize: '24px'}}>{tutorial.subtitle}</Typography> 
                        <Typography sx={{color: palette.secondary.main, marginBottom: '10px'}} >{tutorial.description}</Typography>
                        <YoutubeContainer
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share;"
                            className='video'
                            title={`${tutorial.title}`}
                            allowFullScreen 
                            src={`https://youtube.com/embed/${tutorial.url}?autoplay=0`}>
                        </YoutubeContainer>
                    </AccordionDetails>
                </Accordion>
            ))}
            
        </AdmLayout>
    )
}