import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Space from "../../../../components/Space";
import { Patient } from "../../../../services/patient.service";

type Props = {
    open: boolean
    onClose: () => void
    patient: Patient
    onSave: (patient: Patient) => void
}

const ConsultButton = styled(Button)`
  width: 100%;
  padding: 8px;
  margin: 8px;
  align-content: center;
`

export const ViewPatientDialog: React.FC<Props> = ({ open, onClose, onSave, patient }) => {
    const [name, setName] = useState(patient.name)
    const [observation, setObservation] = useState(patient.observation)

    const [canEdit, setCanEdit] = useState(false)

    useEffect(() => {
        setCanEdit(patient.observation === observation && patient.name === name)
    }, [setCanEdit, patient, observation, name])

    return (
        <Dialog open={open} onClose={() => onClose} sx={{ maxHeight: '80vh' }}>
            <DialogTitle>Paciente</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Space pixels={16} direction={"v"}/>
                    <TextField
                        fullWidth
                        id="name"
                        color="primary"
                        variant="outlined"
                        label="NOME COMPLETO"
                        sx={{ marginBottom: '16px' }}
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value)
                        }}
                    />

                    <TextField
                        fullWidth
                        id="observations"
                        color="primary"
                        multiline
                        rows={5}
                        variant="outlined"
                        label="OBSERVAÇÕES"
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ConsultButton
                    variant={"outlined"}
                    fullWidth={true}
                    onClick={onClose}>VOLTAR
                </ConsultButton>
                <ConsultButton
                    disabled={canEdit}
                    variant={"contained"}
                    fullWidth={true}
                    onClick={() => {
                        onSave({ consults: 0, id: patient.id, name: name, observation: observation })
                    }}>SALVAR
                </ConsultButton>
            </DialogActions>
        </Dialog>
    )
}