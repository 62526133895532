import styled from "styled-components";

export const MainContent = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    width: 600px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1240px;
  height: 100%;
  margin: 0 auto;
`
