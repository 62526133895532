import React, { FC } from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';

interface SpaceProps {
  pixels: number,
  direction: 'v' | 'h'
}

const Space: FC<SpaceProps> = ({pixels, direction}) => {
  const SpaceStyled = styled('div')(() => ({
    width: direction === 'h' ? `${pixels}px` : '1px',
    height: direction === 'v' ? `${pixels}px` : '1px',
  }))
  return (
    <SpaceStyled/>
  )
}

export default Space
