export function isValidCPF(strCPF: string) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    return Resto === parseInt(strCPF.substring(10, 11));

}


export function isValidDate(value: string, type: 'normal' | 'birthdate' = 'normal') {
    const splited = value.split('/')
    if (splited.length === 2) {
        const validMonthes = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        return type === 'birthdate' ?
            validMonthes.includes(splited[0]) && +splited[1] <= Number(new Date().getFullYear()) :
            validMonthes.includes(splited[0])
    } else if (splited.length === 3) {
        const date = `${splited[1]}/${splited[0]}/${splited[2]}`
        return type === 'normal' ?
            new Date(date).toString() !== 'Invalid Date' :
            new Date(date).toString() !== 'Invalid Date' && +splited[2] <= Number(new Date().getFullYear())
    }

    return false
}