import { Box, Container } from '@material-ui/core'
import React, { FC, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { withTheme } from '../../../theme'
import { flexColumn } from '../../../utils/MUIShortcuts'
import NewPasswordForm, { NewPasswordState } from './NewPasswordForm'
import { handlePromise } from '../../../utils/handlePromisse'
import { newPassword } from '../../../services/authService'
import { useModal } from '../../../contexts/modal'
import MCAlert from '../../../components/ModalContents/MCAlert'
import { Title } from "../../../theme/globalStyles";

const containerStyle = withTheme((theme) => ({
  ...flexColumn('center', 'center'),
  minHeight: '100vh', 
  background: theme.palette.primary.main, 
  position: 'fixed'
}))

const boxStyle = withTheme((theme) => ({
  ...flexColumn('center', 'space-around'),
  width: '90%',
  maxWidth: '450px',
  minHeight: '300px',
  borderRadius: '2px',
  boxShadow: '0px 3px 9px rgba(0, 0, 0, .15)',
  background: 'white',
  padding: theme.spacing(2)
}))

const CreateNewPassword: FC = () => {

  const params = useParams()
  const [ loading, setLoading ] = useState<boolean>(false)
  const { setModalContent, setModalOpen } = useModal()
  const navigate = useNavigate()

  const onSubmit = useCallback(async (values: NewPasswordState) => {
    if(params.token){
      setLoading(true)
      const [ result, error ] = await handlePromise(newPassword(params.token, values.password))
      if(error){
        setModalContent(
          <MCAlert
            title="Erro ao criar nova senha"
            onClose={() => setModalOpen(false)}
          />
        )
        setModalOpen(true)
        console.log({error})
        return
      }
      setModalContent(
        <MCAlert
          title="Senha alterada com sucesso"
          buttonText='IR PARA LOGIN'
          onClose={() => {
            setModalOpen(false)
            navigate('/login')
          }}
        />
      )
      setModalOpen(true)
      setLoading(false)
    }
  }, [ params.token, setModalContent, setModalOpen, navigate ])

  return (
    <Container sx={ containerStyle }>
      <Box sx={ boxStyle }>
        <Title>CRIAR NOVA SENHA</Title>
        <NewPasswordForm onSubmit={ onSubmit } loading={ loading } />
      </Box>
    </Container>
  )
}

export default CreateNewPassword