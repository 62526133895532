import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik'
import * as Yup from "yup"
import { Link as RouterLink } from "react-router-dom"
import { Icon } from "@iconify/react"
import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, IconButton, InputAdornment, Link, TextField, useMediaQuery } from "@material-ui/core"
import palette from '../../../theme/palette'
import * as CSSType from 'csstype'
import CSS from '../../../utils/CSSShortCuts'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { LoadingButton } from "@material-ui/lab"
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login'
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { allowNewUser } from '../../../services/userService'

import eyeFill from "@iconify/icons-eva/eye-fill"
import eyeOffFill from "@iconify/icons-eva/eye-off-fill"
import googleFill from '@iconify/icons-eva/google-fill'
import facebook from '@iconify/icons-eva/facebook-fill'
import { useModal } from "../../../contexts/modal";
import MCAlert from "../../../components/ModalContents/MCAlert";
import { btnFacebook, spanFacebook } from "../../../styles/styles";

export interface ILoginValues {
  email: string,
  password: string
}

export interface ILoginSocial {
  email: string,
  name: string
}

export interface ISocialLoginActions {
  onSuccessGoogle: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void,
  onFailureGoogle: (error: any) => void,
  onFacebook: (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => void
}

interface ILoginFormProps {
  handleSubmitProp: (values: ILoginValues, actions: FormikHelpers<ILoginValues>) => void,
  socialLoginActions: ISocialLoginActions
}

const LoginForm: React.FC<ILoginFormProps> =
  ({ handleSubmitProp, socialLoginActions: { onSuccessGoogle, onFailureGoogle, onFacebook } }) => {
    const [showError, setError] = useState(false)
    const [showSuccess, setSuccess] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const LoginSchema = Yup.object().shape({
      email: Yup.string().email('Endereço de e-mail inválido').required('E-mail obrigatório'),
      password: Yup.string().required('Senha obrigatória')
    })

    const formik = useFormik({
      initialValues: {
        email: '',
        password: ''
      },
      validationSchema: LoginSchema,
      onSubmit: (values, actions) => handleSubmitProp(values, actions)
    })

    useEffect(() => {
      if (showError) setTimeout(() => setError(false), 2000);
      if (showSuccess) setTimeout(() => setSuccess(false), 2000);
    }, [showError, showSuccess])

    const buttonColorStyles = {
      background: useMediaQuery((theme: any) => theme.breakpoints.down('md')) ? palette.primary.main : palette.primary.lighter,
      color: useMediaQuery((theme: any) => theme.breakpoints.down('md')) ? palette.primary.lighter : palette.primary.dark
    }

    const textColorStyles = {
      color: useMediaQuery((theme: any) => theme.breakpoints.down('md')) ? palette.primary.main : palette.primary.lighter
    }

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

    const handleShowPassword = () => {
      setShowPassword((show) => !show)
    }

    const formStyles: CSSType.StandardProperties = {
      ...CSS.flexColumn('center', 'space-evenly'),
      width: '100%',
      maxWidth: '400px',
      margin: '20px 0',
      height: 'auto',
      minHeight: '70vh'
    }

    const RecoverPasswordWrapper = styled('div')(({ theme }) => {
      return {
        ...CSS.flexRow('flex-end', 'flex-start'),
        width: '100%',
        heigth: 'auto',
        minHeight: '56px',
      }
    })

    const RecoverPasswordText = styled('p')(({ theme }) => {
      return {
        color: palette.primary.lighter,
        textDecoration: 'underline',
        [theme.breakpoints.down('md')]: {
          color: palette.primary.main
        }
      }
    })

    const SocialButtonsWrapper = styled('div')(({ theme }) => {
      return {
        ...CSS.flexRow('space-between', 'center'),
        width: '100%',
        maxWidth: '400px',
        height: 'auto',
        minHeight: '56px'
      }
    })

    const googleButtonStyles: CSSType.StandardProperties = {
      width: '45%',
      height: 'auto',
      minWidth: '128px',
      marginRight: '5px',
      minHeight: '46px',
      backgroundColor: '#4285F4',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, .15)',
      ...CSS.flexRow('space-around', 'center'),
      borderRadius: '2px'
    }

    const facebookButtonStyles: CSSType.StandardProperties = {
      width: '100%',
      padding: '0 8px',
      margin: '0',
      minHeight: '46px',
      backgroundColor: '#3B5998',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, .15)',
      ...CSS.flexRow('space-around', 'center'),
      borderRadius: '2px',
      lineHeight: '46px',
      fontFamily: '"Poppins", sans-serif'
    }

    const facebookButtonContainerStyles: React.CSSProperties = {
      width: '50%',
      height: 'auto',
      marginLeft: '25px',
      padding: '0',
      minHeight: '46px',
      ...CSS.flexColumn('center', 'center')
    }
    const { setModalContent, setModalOpen } = useModal()
    const urlRedirect = `${window.location.protocol}//${window.location.host}/dashboard/app`;
    return (
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={formStyles}>
          <p style={{ ...textColorStyles, width: '90%', textAlign: 'center' }}>
            Não possui uma conta?
            <Link
              to="/signup"
              variant='subtitle1'
              component={RouterLink}
              sx={{ color: palette.primary.lighter, fontWeight: '400', marginTop: '12px' }}
            >
              <strong style={{ marginLeft: '8px', ...textColorStyles }}>Inscreva-se</strong>
            </Link>
          </p>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            variant="filled"
            color='primary'
            sx={{
              background: palette.primary.lighter,
            }}
            inputProps={{ 'data-testid': 'email' }}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="password"
            type={showPassword ? "text" : "password"}
            label="Senha"
            {...getFieldProps('password')}
            variant="filled"
            color='primary'
            sx={{
              background: palette.primary.lighter,
            }}
            inputProps={{ 'data-testid': 'password' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <RecoverPasswordWrapper>
            <Link
              component={RouterLink}
              variant='subtitle1'
              to="/recoverPassword"
              sx={{ marginTop: '12px' }}
            >
              <RecoverPasswordText> Esqueci minha senha </RecoverPasswordText>
            </Link>
          </RecoverPasswordWrapper>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            loadingIndicator={
              <CircularProgress color='secondary' size={16} />
            }
            style={{ ...buttonColorStyles, fontSize: '1.2rem', borderRadius: '2px', boxShadow: '0 4px 12px rgba(0, 0, 0, .15)' }}
          >
            {isSubmitting ? '' : 'LOGIN'}
          </LoadingButton>

          <SocialButtonsWrapper>

            <GoogleLogin

              // 396789891518-4e5oa0q5pctpndq3bgnr4vf9r5sucehu.apps.googleusercontent.com
              clientId="499953388492-3t0ovu7ph7mgqdqrjseq44igk15a3jta.apps.googleusercontent.com" //another project id
              onSuccess={onSuccessGoogle}
              onFailure={(error) => console.log('google failure', error)}
              render={renderProps => (
                <Button
                  variant="contained"
                  style={googleButtonStyles}
                  onClick={async () => {
                    renderProps.onClick();
                  }}
                >
                  <Icon
                    icon={googleFill}
                    color='#fff'
                    style={{ ...CSS.circle(25), margin: '0 6px', minHeight: '25px', minWidth: '25px' }}
                  />
                  GOOGLE
                </Button>
              )}
            />

            <FacebookLogin
              appId="253404613487769"
              fields="name,email"
              callback={(response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => onFacebook(response)}
              textButton="FACEBOOK"
              buttonStyle={facebookButtonStyles}
              containerStyle={facebookButtonContainerStyles}
              redirectUri={urlRedirect}
              render={(renderProps: any) => (
                <span style={spanFacebook}>
                  <button style={btnFacebook} onClick={async (e) => {
                    e.preventDefault()
                    renderProps.onClick();
                  }}><Icon
                      icon={facebook}
                      color='#fff'
                      style={{ ...CSS.circle(27), margin: '0 6px' }}
                    />Facebook</button>
                </span>
              )}
              disableMobileRedirect
              icon={(
                <Icon
                  icon={facebook}
                  color='#fff'
                  style={{ ...CSS.circle(27), margin: '0 6px' }}
                />
              )}
            />

          </SocialButtonsWrapper>
        </Form>
      </FormikProvider>
    )
  }

export default LoginForm