import * as React from 'react'
import { useState } from 'react'
import { Dialog, TextField } from "@material-ui/core"
import { getQuery, setQuery } from "../../../../services/local-session-storage"
import palette from "../../../../theme/palette";
import { QueryContainer } from "../index.styled";
import VehiclesModel, { vehiclesToProductDetail } from "../../../../models/vehicles.model";
import { ListWithInfo } from "../components/list-with-info";
import { ProductDetail } from "../products/product-detail";
import { flexFilter, orderObjectArray } from "../../../../utils";
import styled from "styled-components";
import { CircleButton, SpacedIconsContainer } from "../../../../styles/styles";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import Space from "../../../../components/Space";
import { BaseLayout } from '../../../../layouts/base/base.layout';
import TemporaryDrawer from '../products/sidebar';

type Props = {
    vehicles: VehiclesModel[]
    onFinish: () => void
    onBack: () => void
}

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  max-height: 65vh;
  overflow-y: scroll;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

const Title = styled.h2`
  color: ${palette.secondary.main};
  text-align: center;
  margin-bottom: 48px; 
  min-width: 350px;
  position: relative;
  
  @media screen and (max-height: 668px) {
    margin-bottom: 3px;
  }

`

export const Vehicles: React.FC<Props> = ({ onFinish, onBack, vehicles }) => {

    const model = getQuery()
    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState('')
    const [toOpenVehicle, setToOpenVehicle] = useState<VehiclesModel | null>()
    const [selectedVehicle, setSelectedVehicle] = useState<VehiclesModel | null>(model.vehicles || null)

    const handleClickVehicles = (id: number) => {
        setOpen(true)
        const model = vehicles.find(elem => elem.id === id)
        setToOpenVehicle(model)
    }

    const handleSelectVehicles = (id: number) => {
        if (selectedVehicle?.id === id) {
            setSelectedVehicle(null)
            return
        }
        const model = vehicles.find(elem => elem.id === id)
        if (model) setSelectedVehicle(model)
    }

    const handleContinue = () => {
        setQuery({ vehicles: selectedVehicle })
        onFinish()
    }

    const isElementChecked = (vehicle: VehiclesModel) => {
        return selectedVehicle?.id === vehicle.id
    }

    return (
        <BaseLayout>
         <QueryContainer>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <ProductDetail
                    detail={vehiclesToProductDetail(toOpenVehicle)}
                    onClose={() => setOpen(false)}/>
            </Dialog>
            <div>
                <Title>Veículo (Opcional)
                    <TemporaryDrawer/>
                </Title>
                <TextField
                    label={'Buscar...'}
                    variant="outlined"
                    color="primary"
                    onChange={(e) => setFilter(!!e.target.value ? e.target.value : '')}
                    value={filter}
                    sx={{ width: '100%' }}
                />

                <ListContainer>
                    {
                        orderObjectArray(flexFilter(vehicles, "name", filter))
                            .by('name')
                            .asc()
                            .map((vehicle) =>
                                <ListWithInfo
                                    id={vehicle.id}
                                    name={vehicle.name}
                                    checked={isElementChecked(vehicle)}
                                    onClick={(id) => handleClickVehicles(id)}
                                    onSelect={(id) => handleSelectVehicles(id)}
                                />
                            )
                    }
                </ListContainer>
            </div>

            <Space pixels={16} direction={'v'}/>

            <SpacedIconsContainer>
                <CircleButton onClick={onBack}><ArrowBack/></CircleButton>
                <CircleButton onClick={handleContinue}><ArrowForward/></CircleButton>
            </SpacedIconsContainer>
         </QueryContainer>
        </BaseLayout>
    )
}
