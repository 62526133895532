import { Consultation, Patient } from "../../../../services/patient.service"
import * as React from "react"
import { useCallback, useState } from "react"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"
import { Delete, Edit, PictureAsPdf } from "@material-ui/icons"
import Collapse from "@mui/material/Collapse"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import { Checkbox, FormControlLabel } from "@material-ui/core"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { MoreOptions } from "./more-options"
import { treatString } from "../../../../utils";

type Props = {
    patient: Patient
    isMobile: boolean
    onEdit: (patient: Patient) => void
    onDelete: (patient: Patient) => void
    onPdfGenerate: (consults: number[]) => void
    onEditConsult: (consult: number) => void
    onDeleteConsult: (consult: number) => void
}

export const PatientRow: React.FC<Props> = ({
    patient,
    isMobile,
    onEdit,
    onDelete,
    onPdfGenerate,
    onEditConsult,
    onDeleteConsult,
}) => {

    const [open, setOpen] = useState(false)
    const [consults, setConsults] = useState<number[]>([])

    const handleOnChange = useCallback((checkable, consultation: Consultation) => {
        setConsults(prev => {
            if (checkable.target.checked) {
                return [...prev, consultation.id]
            }
            return prev.filter(id => id !== consultation.id)
        })
    }, [consults])

    const createMenu = () => {
        return (
            <React.Fragment>
                <IconButton onClick={() => onEdit(patient)}>
                    <Edit color={"primary"}/>
                </IconButton>
                <IconButton onClick={() => onDelete(patient)}>
                    <Delete color={"primary"}/>
                </IconButton>
                <IconButton disabled={consults.length == 0} onClick={() => onPdfGenerate(consults)}>
                    <PictureAsPdf color={consults.length > 0 ? "primary" : 'disabled'}/>
                </IconButton>
            </React.Fragment>
        )
    }

    const createMobileMenu = () => {
        return (
            <MoreOptions
                onEdit={() => onEdit(patient)}
                onDelete={() => onDelete(patient)}
                canGeneratePdf={consults.length > 0}
                onPdfGenerate={() => onPdfGenerate(consults)}
            />
        )
    }

    const createRow = () => {
        return (
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                    {isMobile ? treatString(patient.name, 19) : patient.name}
                </TableCell>
                {!isMobile && <TableCell>{patient.consults}</TableCell>}
                <TableCell sx={{ textAlign: 'center' }}>
                    {isMobile ? createMobileMenu() : createMenu()}
                </TableCell>
            </TableRow>
        )
    }

    const createConsultationRow = (consultation: Consultation) => {
        const label = consultation.name !== null && consultation.name.length !== 0 ? isMobile ? treatString(consultation.name, 19) : consultation.name : consultation.date;

        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox onChange={(event) => handleOnChange(event, consultation)} name={consultation.date} />
                    }
                    label={
                        <div>
                            <div>{label}</div>
                            <div style={{ fontSize: '11px', color: 'gray' }}>
                                {consultation.date}
                            </div>
                        </div>
                    }
                />
            </>
        )
    }

    return (
        <React.Fragment>
            {createRow()}
            {
                patient.consultations && <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell style={{ padding: 0 }} colSpan={6}>
                        <Collapse in={open}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {patient.consultations.map((consultation) => (
                                        <TableRow key={consultation.id}>
                                            <TableCell sx={{
                                                width: isMobile ? '68%' : '70%',
                                                paddingLeft: isMobile ? '1rem' : '3rem'
                                            }}>
                                                {createConsultationRow(consultation)}
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'end' }}>
                                                <IconButton onClick={() => onEditConsult(consultation.id)}>
                                                    <Edit color={"primary"}/>
                                                </IconButton>
                                                <IconButton onClick={() => onDeleteConsult(consultation.id)}>
                                                    <Delete color={"primary"}/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </React.Fragment>
    )
}
