import React, { useState } from "react";
import VehiclesModel from "../../models/vehicles.model";
import { ProductModel, productModelToDetails } from "../../models/Product.model";
import { VehiclesItem } from "../../pages/main/query/dose/vehicles-item";
import { ProductItem } from "./product-item";
import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { ProductDetail } from "../../pages/main/query/products/product-detail";

type Props = {
    observation: string
    products: ProductModel[]
    vehicles: VehiclesModel|null
    onRemoveItem?: (id: number) => void
    onDoseChange: (id: number, value: string) => void
    onObservationChange: (observation: string) => void
}

const ListContainer = styled.div`
  display: grid;
  grid-auto-rows: minmax(160px, auto);
  width: 100%;
  gap: 16px;

  @media (min-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr))
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr))
  }
`

export const ProductList: React.FC<Props> = (
    { vehicles, products, observation, onDoseChange, onObservationChange, onRemoveItem }
) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [itemSelected, setItemSelected] = useState<ProductModel|null>(null)

    return (
        <ListContainer>
            {
                itemSelected && <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                    <ProductDetail
                        detail={productModelToDetails(itemSelected)}
                        onClose={() => setModalOpen(false)}/>
                </Dialog>
            }
            {
                vehicles &&
                <VehiclesItem
                    vehicles={vehicles}
                    observation={observation}
                    onObservationChange={onObservationChange}
                />
            }
            {
                products && products.map((product) =>
                    <ProductItem
                        id={product.idProduct || 0}
                        key={product.idProduct}
                        name={product.productName}
                        percentage={product.percentage || ''}
                        description={product.description}
                        minDosage={product.minimumDosage}
                        maxDosage={product.maxDosage}
                        onRemoveItem={onRemoveItem}
                        onItemDoseChange={onDoseChange}
                        onItemClick={(id) => {
                            setItemSelected(products.find(p => p.idProduct === id) || null)
                            setModalOpen(true)
                        }}
                    />
                )
            }
        </ListContainer>
    )
}