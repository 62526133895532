import { FC } from "react";
import { Background } from "./list-with-info.styled";
import palette from "../../../../theme/palette";
import { Box, Checkbox, FormControl } from "@material-ui/core";
import { treatString } from "../../../../utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { btnInfo } from "../products/active-item";

type DataProps = {
    id: number
    name: string
    checked: boolean
    description?: string
    onClick: (id: number) => void
    onSelect: (id: number, checked: boolean) => void,
}

export const ListWithInfo: FC<DataProps> = ({ id, name, description, checked, onClick, onSelect }) => {

    return (
        <Background>
            <FormControl>
                <Checkbox
                    size="medium"
                    color="secondary"
                    checked={checked}
                    onChange={(_, check) => onSelect(id, check)}
                />
            </FormControl>

            <Box style={{ width: '70%' }}>
                <h2 style={{ fontSize: '1.2rem' }}>
                    {treatString(name.toLowerCase(), 20, { capitalize: true })}
                </h2>
                {
                    description &&
                    <p style={{ fontSize: '.9rem', cursor: 'pointer' }}>
                        {description && treatString(description, 30)}
                    </p>
                }
            </Box>

            <Box sx={btnInfo} style={{cursor: 'pointer'}} onClick={() => onClick(id)}>
                <VisibilityIcon style={{color: palette.primary.main, width: '24px', height: '24px'}} />
            </Box>
        </Background>
    )
}
