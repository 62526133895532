import styled from "styled-components";
import { Box } from "@material-ui/core";

export const ScrollBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  min-height: 50vh;
  max-height: 50vh;
`
