import React, { useCallback, useEffect, useState } from "react";
import { disableActive, enableActive } from "../../../services/decisionFlow";
import styled from "styled-components";
import { BaseLayout } from "../../../layouts/base/base.layout";
import { NewPlan } from "./new-plan";
import { Title } from "../../../theme/globalStyles";
import { PlansTable } from "./plans-table";
import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import { disablePlan, editPlan, enablePlan, getPlans, newPlan } from "../../../services/plan-service";
import PlanModel from "../../../models/plan.model";
import { Loading } from "../../../components/loading";
import { LogoLayout } from "../../../layouts/base/logo.layout";
import { AdmLayout } from "../../../layouts/base/adm.layout";

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 16px;
`

const MainContent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`

type State = {
    open: boolean
    plan?: PlanModel
}

export const ManagePlans: React.FC = () => {
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [plans, setPlans] = useState<PlanModel[]>([])
    const [state, setState] = useState<State>({ open: false })

    const fetchPlans = useCallback(async () => {
        setOpenBackdrop(true)
        const result = await getPlans()
        const response = result.data as PlanModel[];

        setPlans(response)
        setOpenBackdrop(false)
    }, [setOpenBackdrop, setPlans])

    useEffect(() => {
        fetchPlans()
    }, [fetchPlans])

    const handleEnablePlans = useCallback(async (plan: PlanModel) => {
        if (plan.enabled) {
            await disablePlan(plan.id)
        } else {
            await enablePlan(plan.id)
        }
        await fetchPlans()
    }, [disableActive, enableActive, fetchPlans])

    const handleNewPlan = useCallback(async (plan: PlanModel, id?: number) => {
        if (id) {
            await editPlan(id, plan)
        } else {
            await newPlan(plan)
        }
        await fetchPlans()
        setState({ open: false })
    }, [setState, editPlan, newPlan, fetchPlans])

    return (
        <AdmLayout loading={openBackdrop} hasLogo={true}>
            {state.open && <NewPlan
                open={state.open}
                plan={state.plan}
                onClose={() => setState({ open: false })}
                onConfirm={(plan, id) => handleNewPlan(plan, id)}/>}

            <Title>Gestão de Planos</Title>
            <PlansTable
                data={plans}
                onEditItem={(plan) => {
                    setState({
                        plan: { ...plan },
                        open: true,
                    })
                }}
                switchActive={(active) => handleEnablePlans(active)}/>
            <ActionContainer>
                <Button variant="contained" onClick={() => setState({ open: true })}>Novo Plano</Button>
            </ActionContainer>
        </AdmLayout>
    )
}