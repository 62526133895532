export interface PaginatedResponse<T> {
    data: T[];
    pagination: Pagination;
}

export interface Pagination {
    total: number;
    perPage: number;
    currentPage: number;
}

export const initialPagination: PaginatedResponse<any> = { data: [], pagination: { total: 0, perPage: 0, currentPage: 0 } }
