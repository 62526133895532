import { FormikProvider, useFormik, Form, FormikHelpers } from 'formik';
import * as Yup from "yup";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from 'react';
import { TextField, IconButton, InputAdornment, Link, useMediaQuery, CircularProgress, FormControl, Box, Checkbox, Stack, Typography } from "@material-ui/core";
import palette from '../../../theme/palette';
import * as CSSType from 'csstype';
import CSS from '../../../utils/CSSShortCuts';
// import { experimentalStyled as styled } from '@material-ui/core/styles';
import { LoadingButton } from "@material-ui/lab";

import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { checkboxWrapper } from './styles';
import { stackFlexColumn } from '../../../utils/MUIShortcuts';
import { useModal } from '../../../contexts/modal';
import MCAlert from '../../../components/ModalContents/MCAlert';

export interface ISignupValues {
  email: string,
  fullName: string,
  password: string,
  passwordRepeat: string
}

interface ISignupFormProps {
  handleSubmitProp: (values: ISignupValues, actions: FormikHelpers<ISignupValues>) => void
  typedValues?: ISignupValues
}

const SignupForm: React.FC<ISignupFormProps> = ({ typedValues, handleSubmitProp }) => {

  const location = useLocation()
  const baseURL = window.location.href.replace(location.pathname, '')
  const { setModalContent, setModalOpen } = useModal()
  const [showError, setError] = useState(false)
  const [showSuccess, setSuccess] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
  const [ loading, setLoading ] = useState<boolean>(false)
  const [values, setValues] = useState<ISignupValues>(typedValues || {} as ISignupValues)
  const [ termsAccepted, setTermsAccepted ] = useState<boolean>(!!typedValues?.email)

  const SignupSchema = Yup.object().shape({
    fullName: Yup.string().required('Nome completo obrigatório').min(3, 'Mínimo de 3 letras'),
    email: Yup.string().email('Endereço de e-mail inválido').required('E-mail obrigatorio'),
    password: Yup.string()
    .required('Senha obrigatória')
    .min(6, 'A senha deve ter no mínimo 6 dígitos')
    .matches(/([a-z])+/g, 'Digite ao menos uma letra minúscula')
    .matches(/([A-Z])+/g, 'Digite ao menos uma letra maiúscula')
    .matches(/([0-9])+/g, 'Digite ao menos um dígito numérico')
    .matches(/([!@#$%¨^&*()\-[\]{}+=/;:'"._])+/g, 'Digite ao menos um símbolo ex. @#!'),
    passwordRepeat: Yup.string()
    .required('Senha obrigatória')
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
  })

  const formik = useFormik({
    initialValues: {
      email: values.email,
      fullName: values.fullName,
      password: values.password,
      passwordRepeat: values.passwordRepeat
    },
    validationSchema: SignupSchema,
    onSubmit: (values: ISignupValues, actions: FormikHelpers<ISignupValues>) => {
      if(termsAccepted){
        setLoading(true)
        setValues(values)
        handleSubmitProp(values, actions)
      }else{
        setModalContent(<MCAlert title='Para criar sua conta aceite os termos de uso e prolítica de privacidade' />)
        setLoading(false)
        setModalOpen(true)
      }
    }
  })

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess])

  const buttonColorStyles = {
    background: useMediaQuery((theme: any) => theme.breakpoints.down('md')) ? palette.primary.main : palette.primary.lighter,
    color: useMediaQuery((theme: any) => theme.breakpoints.down('md')) ? palette.primary.lighter : palette.primary.dark
  }

  const textColorStyles = {
    color: useMediaQuery((theme: any) => theme.breakpoints.down('md')) ? palette.primary.main : palette.primary.lighter
  }

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps} = formik

  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }

  const handleShowPasswordRepeat = () => {
    setShowPasswordRepeat((show) => !show)
  }

  const formStyles: CSSType.StandardProperties = {
    ...CSS.flexColumn('center', 'space-evenly'),
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    margin: '20px 0',
    minHeight: '86vh',
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={formStyles}>
        <Stack { ...stackFlexColumn('center', 'flex-start') } spacing={ 2 } sx={{ width: '100%' }}>
        <TextField 
          fullWidth
          autoComplete="fullName"
          type="fullName"
          label="Nome Completo"
          {...getFieldProps('fullName')}
          variant="filled"
          color='primary'
          inputProps={{'data-testid': 'fullName'}}
          sx={{
            background: palette.primary.lighter,
          }}
          error={Boolean(touched.fullName && errors.fullName)}
          helperText={touched.fullName && errors.fullName}
        />
        <TextField 
          fullWidth
          autoComplete="email"
          type="email"
          label="Email"
          variant="filled"
          color='primary'
          {...getFieldProps('email')}
          sx={{
            background: palette.primary.lighter,
          }}
          inputProps={{'data-testid': 'email'}}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
        />
        <TextField 
          fullWidth
          autoComplete="password"
          type={showPassword ? "text" : "password"}
          label="Senha"
          {...getFieldProps('password')}
          variant="filled"
          color='primary'
          inputProps={{'data-testid': 'password'}}
          sx={{
            background: palette.primary.lighter,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
        />
        <TextField 
          fullWidth
          autoComplete="passwordRepeat"
          type={showPasswordRepeat ? "text" : "password"}
          label="Confirmar senha"
          {...getFieldProps('passwordRepeat')}
          variant="filled"
          color='primary'
          inputProps={{'data-testid': 'passwordRepeat'}}
          sx={{
            background: palette.primary.lighter,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPasswordRepeat} edge="end">
                  <Icon icon={showPasswordRepeat ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(touched.passwordRepeat && errors.passwordRepeat)}
          helperText={touched.passwordRepeat && errors.passwordRepeat}
        />
        </Stack>

        <FormControl>
          <Box sx={ checkboxWrapper }>
            <Checkbox 
              checked={termsAccepted} 
              size="medium" 
              color="primary"
              onChange={() => setTermsAccepted(curr => !curr)} 
            />
            {/* <Typography>
              {'Declaro que li e concordo com os '}
              <Typography component='span' sx={{ cursor: 'pointer' }} onClick={() => window.open(baseURL + '/use-terms', '_blank')}>
                Termos de uso
              </Typography>
            </Typography>           */}
            <p> <a href={baseURL + '/use-terms'} rel="noreferrer" target="_blank">Termos de uso</a> e <a href={baseURL + '/privacy-policy'} rel="noreferrer" target="_blank">Política de privacidade</a> </p>
          </Box>
        </FormControl>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting && termsAccepted && loading}
          loadingIndicator={
            <CircularProgress color='secondary' size={16} />
          }
          style={{...buttonColorStyles, fontSize: '1.2rem', borderRadius: '2px', boxShadow: '0 4px 12px rgba(0, 0, 0, .15)', marginTop: '15px'}}
        >
          {isSubmitting && termsAccepted && loading ? '' : 'CADASTRAR'}
        </LoadingButton>
<br />
        <p style={{...textColorStyles, width: '90%', textAlign: 'center'}}>
          Já possui uma conta?
          <Link
            component={RouterLink}
            variant='subtitle1'
            to="/login"
            sx={{color: palette.primary.lighter, fontWeight: '400', marginTop: '12px'}}
          >
            <strong style={{marginLeft: '8px', ...textColorStyles}}>Acesse aqui</strong>
          </Link>
        </p>
      </Form>
    </FormikProvider>
  )
}

export default SignupForm