import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Dialog, Tab, Tabs, TextField, Typography } from "@material-ui/core"
import { favoriteProduct, getProducts, unFavoriteProduct } from "../../../../services/productService"
import { getQuery, setQuery } from "../../../../services/local-session-storage"
import { toProductEntries } from "../query.model"
import { handlePromise } from "../../../../utils/handlePromisse"
import { useAuth } from "../../../../contexts/auth2";
import { arrayWithoutOneElement, flexFilter, orderObjectArray } from "../../../../utils";
import palette from "../../../../theme/palette";
import { ScrollBox } from "./product.styled";
import { QueryContainer } from "../index.styled";
import styled from "styled-components";
import { ProductModel, productModelToDetails } from "../../../../models/Product.model";
import ActiveItem from "./active-item";
import { ProductDetail } from "./product-detail";
import Space from "../../../../components/Space";
import { CircleButton, SpacedIconsContainer } from "../../../../styles/styles";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { flexMultiFilter } from '../../../../utils';
import { BaseLayout } from '../../../../layouts/base/base.layout'
import TemporaryDrawer from './sidebar'
import { AnnotationDialog } from '../../actives/annotation.dialog'
import { postAnnotation } from '../../../../services/productService'
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
    filter: string
    setFilter: (filter: string) => void
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, filter, setFilter, ...other } = props

    return (
        <div
            style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}>
            {value === index && (
                <div>
                    <TextField
                        label={'Buscar...'}
                        variant="outlined"
                        color="primary"
                        onChange={(e) => setFilter(!!e.target.value ? e.target.value : '')}
                        value={filter}
                        sx={{ width: '100%', marginTop: '5px' }}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    sx={{ cursor: 'pointer',}}
                                    onClick={() => setFilter('')}>
                                    <CloseIcon fontSize="small"/>
                                </IconButton> 
                            ),
                        }}
                        />

                    <Typography>{children}</Typography>
                </div>
            )}
        </div>
    )
}

type Props = {
    onFinish: () => void
    onAddMoreItem: () => void
}

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  max-height: 70vh;
  overflow-y: scroll;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

export const Products: React.FC<Props> = ({ onFinish, onAddMoreItem }) => {

    const model = getQuery()
    const { user: { idUser } } = useAuth()
    const [value, setValue] = useState(0)
    const [open, setOpen] = useState(false)
    const [products, setProducts] = useState<ProductModel[]>([])
    const [filter, setFilter] = useState('')
    const [selectedProducts, setSelectedProducts] = useState<ProductModel[]>(model.products || [])
    const [selectedProduct, setSelectedProduct] = useState<ProductModel>({} as ProductModel)
    const [annotationOpen, setAnnotationOpen] = useState(false)
    const [activeSelected, setActiveSelected] = useState<ProductModel|null>(null)
    const [onPopUp, setOnPopUp] = useState(false)

    const fetchProducts = useCallback(async () => {
        const request = toProductEntries(model)
        const [result, _] = await handlePromise(getProducts(request))
        setProducts(result.data as ProductModel[])

    }, [toProductEntries, handlePromise, getProducts, setProducts])

    useEffect(() => {
        fetchProducts()
    }, [fetchProducts])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setFilter('')
        setValue(newValue)
    }

    const handleClickProduct = (product: ProductModel) => {
        setOpen(true)
        setSelectedProduct(product)
    }

    const handleSelectProduct = (product: ProductModel, checked: boolean) => {
        if (!checked) {
            const index = selectedProducts.findIndex(prod => prod.idProduct === product.idProduct)
            setSelectedProducts(arrayWithoutOneElement(selectedProducts, index))
            const indexQuery = model.products.findIndex(prod => prod.idProduct === product.idProduct)
            setQuery({products:arrayWithoutOneElement(model.products, indexQuery)})
        } else {
            setSelectedProducts([...selectedProducts, product])
            setQuery({ products:[...selectedProducts, product]})
        }
    }

    const handleAddMoreItems = () => {
        setQuery({ products: selectedProducts })
        onAddMoreItem()
    }

    const handleContinue = () => {
        setQuery({ products: selectedProducts })
        onFinish()
    }

    const handleClickOpen = useCallback(async (active: ProductModel) => {
        setActiveSelected(active)
        setAnnotationOpen(true)
    }, [setActiveSelected])

    const saveAnnotation = useCallback(async (newAnnotation: string) => {
        if (!activeSelected) return
        if (!activeSelected.idProduct) return

        const [_, error] = await handlePromise(postAnnotation(activeSelected?.idProduct, { annotation: newAnnotation }))
        if (!!error) {
            console.log('error', error, error.stack)
            return
        }

        fetchProducts()
        setAnnotationOpen(false)
    }, [activeSelected, postAnnotation])

    const popUpOpen = useCallback(async() => {
        setOnPopUp(true);
        console.log(onPopUp)
    },[onPopUp]);

    const handleClose = () => {
        setOnPopUp(false);
    };

    const handleFavorite = useCallback(async (active: ProductModel) => {
        
        if(active.idProduct){
            if (active.favorite) {
                await unFavoriteProduct(idUser, active.idProduct)
            } else {
                await favoriteProduct({
                  id_Product: active.idProduct, id_User: idUser
                })
         }
       }
       fetchProducts()
    }, [unFavoriteProduct, favoriteProduct, fetchProducts, setProducts, products])

    return (
        <BaseLayout>
            <QueryContainer>
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <ProductDetail
                        detail={productModelToDetails(selectedProduct)}
                        onClose={() => setOpen(false)} />
                </Dialog>
                <div>
                    <h2 style={{ color: palette.secondary.main, textAlign: 'center', position: 'relative', }}>
                        ATIVOS  
                        <TemporaryDrawer />
                    </h2>
                    
                    <Tabs value={value} onChange={handleChange} centered>
                        <Tab label="Todos" />
                        <Tab label="Favoritos" />
                        <Tab label="Selecionados" />
                    </Tabs>

                <ListContainer>
                    <TabPanel value={value} index={0} filter={filter} setFilter={setFilter}>
                        {
                            orderObjectArray(flexMultiFilter(products, ["productName", "annotation"], filter))
                                .by('productName')
                                .asc()
                                .map((product, index) =>
                                    <ActiveItem
                                        key={index}
                                        props={product}
                                        onClick={handleClickProduct}
                                        onPopUp={() => onPopUp}
                                        onSelect={handleSelectProduct}
                                        annotationOpen={(model) => handleClickOpen(model)}
                                        onFavorite={(model) => handleFavorite(model)}
                                        checked={!!selectedProducts.find(prod => prod.idProduct === product.idProduct)} />
                                )
                        }
                    </TabPanel>

                    <TabPanel value={value} index={1} filter={filter} setFilter={setFilter}>
                        <ScrollBox>
                            {
                                orderObjectArray(flexFilter(products.filter(prod => prod.favorite === 1), "productName", filter))
                                    .by('productName')
                                    .asc()
                                    .map((product, index) =>
                                        <ActiveItem
                                            key={index}
                                            props={product}
                                            onClick={handleClickProduct}
                                            onPopUp={() => popUpOpen}
                                            onSelect={handleSelectProduct}
                                            onFavorite={(model) => handleFavorite(model)}
                                            checked={!!selectedProducts.find(prod => prod.idProduct === product.idProduct)} />
                                    )
                            }
                        </ScrollBox>
                    </TabPanel>
                    <TabPanel value={value} index={2} filter={filter} setFilter={setFilter}>
                        <ScrollBox>
                            {
                                orderObjectArray(flexFilter(selectedProducts, "productName", filter))
                                    .by('productName')
                                    .asc()
                                    .map((product, index) =>
                                        <ActiveItem
                                            key={index}
                                            props={product}
                                            onClick={handleClickProduct}
                                            onPopUp={() => popUpOpen()}
                                            onSelect={handleSelectProduct}
                                            onFavorite={(model) => handleFavorite(model)}
                                            checked={!!selectedProducts.find(prod => prod.idProduct === product.idProduct)} />
                                    )
                            }
                        </ScrollBox>
                    </TabPanel>
                </ListContainer>
            </div>

            {
                annotationOpen && <AnnotationDialog
                    annotation={activeSelected?.annotation}
                    open={annotationOpen}
                    onSaveAnnotation={saveAnnotation}
                    onClose={() => setAnnotationOpen(false)}
                />
            }

            { onPopUp && <div>
                <Dialog
                    open={onPopUp}
                    onClose={handleClose}
                    >
                    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {"Opção não disponível."}
                            <CloseIcon sx={{ cursor: 'pointer', }} onClick={handleClose}/>
                        </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                Você não pode fazer anotações no ativo selecionado. 
                                Volte para a listagem de todos ativos para incluir anotações.
                                </DialogContentText>
                        </DialogContent>
                </Dialog>
            </div>
            }

            <Space pixels={16} direction={'v'} />

            <SpacedIconsContainer>
                <CircleButton onClick={handleAddMoreItems}><ArrowBack /></CircleButton>
                <CircleButton
                    onClick={handleContinue}
                    disabled={selectedProducts.length === 0}><ArrowForward />
                </CircleButton>
            </SpacedIconsContainer>
        </QueryContainer>
        </BaseLayout>
    )
}
