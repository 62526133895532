import PropTypes from "prop-types";
import { useMemo } from "react";

// material
import { CssBaseline, Theme } from "@material-ui/core";
import { ThemeProvider, createTheme, StyledEngineProvider } from "@material-ui/core/styles";

import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import GlobalStyles from "./globalStyles";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import { SxProps } from "@material-ui/system";

//=====================================================

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

const themeOptions: any = {
  palette,
  shape,
  typography,
  breakpoints,
  shadows,
  customShadows,
}

let theme = createTheme({
  ...themeOptions,
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'red',
        borderRadius: '2px'
      },
      MuiOutlinedInput: {
        borderRadius: '2px'
      }
    }
  }
} as any );

export const withTheme = (fn: (theme: Theme) => SxProps) => fn(theme)

export default function ThemeConfig({ children }: any) {
  
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
