import React, { FC, useCallback, useEffect, useState } from "react";
import palette from "../../../theme/palette";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { getRanking } from "../../../services/userService";
import { handlePromise } from "../../../utils/handlePromisse";
import { useAuth } from "../../../contexts/auth2";
import { AlertItem } from "../plans/plan-item";
import { getUserProfile } from "../../../services/profileService";
import styled from "styled-components";

interface RankingData {
  idUser: number;
  ranking: number;
  countConsultation: number;
  countPatient: number;
}

const RankingDiv = styled.div`
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  max-width: 460px;
  text-transform: uppercase;
  margin-top: 60px;
`

const TitleRank = styled.h2`
  display: flex;
  justify-content: center; 

  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${palette.primary.dark};
  margin: 0 auto;
  margin-bottom: 20px;
`

const NumberBox = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center; 

  background-color: ${palette.primary.light};
  font-weight: bold;
  font-size: 1.4rem;
  width: 18%;
`

const TextBox = styled(Paper)`
  align-items: center; 
  display: flex;
  justify-content: center;  

  width: 82%;
`

const AlertBox = styled(Box)`
 padding: 10px;
`

export const Ranking: React.FC = () => {
  const [ranking, setRanking] = useState<RankingData | undefined>(undefined);
  const { user: { idUser } } = useAuth();
  const {user} = useAuth()
  const [dueDate, setDueDate] = useState<number>(0)

  const fetchProfile = () => {
    getUserProfile(idUser)
        .then(value => {
            const response = value.data;

            const lastDay = new Date(response.dat_end_plan).getTime();
            const today = new Date().getTime();
            const diffTime = Math.abs(lastDay - today);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            setDueDate(diffDays)
        })
}

  const handleGetRanking = useCallback(async (idUser: number) => {
    const [result, error] = await handlePromise(getRanking(idUser));
    if (error) {
      console.log('erro');
    } else {
      setRanking(result.data);
      return
    }
  }, [getRanking, handlePromise]);

  useEffect(() => {
    handleGetRanking(idUser)
    fetchProfile()
  },[])

  return (
    <RankingDiv>
      <TitleRank>Ranking ProAtivos</TitleRank>
      <div>
        <Box sx={{ display: 'flex', '& > :not(style)': { m: 1, height: '4rem', boxShadow: 3, }, }}>
          <NumberBox variant="outlined">{ranking?.ranking ?? 0}°</NumberBox>
          <TextBox variant="outlined" square sx={{ textAlign: 'center', }}>Você está no TOP {ranking?.ranking ?? 0}º formuladores da ProAtivos</TextBox>
        </Box>
        <Box sx={{ display: 'flex', '& > :not(style)': { m: 1, height: '4rem', boxShadow: 3, }, }}>
          <NumberBox variant="outlined">{ranking?.countConsultation ?? 0}</NumberBox>
          <TextBox variant="outlined" square >Fórmulas Montadas</TextBox>
        </Box>
        <Box sx={{ display: 'flex', '& > :not(style)': { m: 1, height: '4rem', boxShadow: 3,}, }}>
          <NumberBox variant="outlined">{ranking?.countPatient ?? 0}</NumberBox>
          <TextBox square >Clientes impactados</TextBox>
        </Box>
        
        {
          dueDate && dueDate < 7 && dueDate !== 0
          && (
            <AlertBox>
              <AlertItem severity="warning" variant="outlined">Seu plano expira em {dueDate} dias!</AlertItem>
            </AlertBox>
        )
        }
        
      </div>
    </RankingDiv>
  )
}

export default Ranking;