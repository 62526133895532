import styled from "styled-components";

export const QueryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 16px;

  @media screen and (min-width: 768px) {
    width: 600px;
  }
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
`

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  max-height: 55vh;
  overflow-y: scroll;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`