import React, { useCallback, useEffect, useState } from "react";
import { BaseLayout } from "../../../layouts/base/base.layout";
import { ActiveTable } from "./active-table";
import { ActiveResponse, disableActive, editActive, enableActive, getActivesPaginated, newActive } from "../../../services/decisionFlow";
import { initialPagination, PaginatedResponse } from "../../../domain/models/paginated-response.model";
import { NewActive } from "./new-active";
import { Title } from "../../../theme/globalStyles";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { AdmLayout } from "../../../layouts/base/adm.layout";

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 16px;
`
type State = {
    open: boolean
    active?: ActiveResponse
}
export const ManageActives: React.FC = () => {
    const [state, setState] = useState<State>({ open: false })
    const [actives, setActives] = useState<PaginatedResponse<ActiveResponse>>(initialPagination)

    const fetchActives = useCallback(async (newPage: number = 0, newSize: number = 10) => {
        const result = await getActivesPaginated(newPage + 1, newSize)
        const response = result.data as PaginatedResponse<ActiveResponse>;

        setActives(response)
    }, [])

    useEffect(() => {
        fetchActives(0, 10)
    }, [fetchActives])

    const handlePageChange = useCallback(async (newPage: number, size: number) => {
        await fetchActives(newPage, size)
    }, [fetchActives])

    const handleSizeChange = useCallback(async (newSize: number, page: number) => {
        await fetchActives(page, newSize)
    }, [fetchActives])

    const handleEnableActive = useCallback(async (active: ActiveResponse) => {
        if (active.enabled) {
            await disableActive(active.id)
        } else {
            await enableActive(active.id)
        }
        await fetchActives()
    }, [disableActive, enableActive])

    const handleNewActive = useCallback(async (name: string, id?: number) => {
        if (id) {
            await editActive(id, name)
        } else {
            await newActive(name)
        }
        await fetchActives()
        setState({ open: false })
    }, [setState, editActive, newActive, fetchActives])

    return (
        <AdmLayout hasLogo={true} loading={false}>
            {state.open && <NewActive
                open={state.open}
                active={state.active}
                onClose={() => setState({ open: false })}
                onConfirm={(name, id) => handleNewActive(name, id)}/>}

            <Title>Gestão de Classes</Title>
            <ActiveTable
                data={actives.data}
                onPageChange={handlePageChange}
                onSizeChange={handleSizeChange}
                total={actives.pagination.total}
                size={actives.pagination.perPage}
                page={actives.pagination.currentPage}
                onEditItem={(active) => {
                    setState({
                        active: { ...active },
                        open: true,
                    })
                }}
                switchActive={(active) => handleEnableActive(active)}/>
            <ActionContainer>
                <Button variant="contained" onClick={() => setState({ open: true })}>Novo Ativo</Button>
            </ActionContainer>
        </AdmLayout>
    )
}
