import React, { useCallback, useEffect, useState } from 'react'
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import palette from '../../../../theme/palette'
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { handlePromise } from '../../../../utils/handlePromisse';
import { Patient as PatientModel , getPatients } from "../../../../services/patient.service";

export interface IPatientModalValues {
    id: number,
    consults: number,
    patientName: string,
    observations: string
}

interface PatientProps {
    onClose: (values?: IPatientModalValues) => void
    reloadPatients: boolean
    patientSelected: (id: number) => void
}

const isValidState = (state: Partial<IPatientModalValues>) => !!state.patientName && state.patientName.length > 3

const handleSave = (state: Partial<IPatientModalValues>, callback: Function) => {
    if (isValidState(state)) {
        callback(state)
    }
}

export const AddPatient: React.FC<PatientProps> = ({ onClose, reloadPatients, patientSelected }) => {
    const [state, setState] = useState<Partial<IPatientModalValues>>({})
    const [disabled, setDisabled] = useState(true)
    const [patients, setPatients] = useState<PatientModel[]>([])
    const [selected, setSelected] = useState<PatientModel | null>(null)
    const [observationState, setObservationState] = useState(false)

    const filter = createFilterOptions<PatientModel>();

    const fetchPatients = useCallback(async () => {
        const [result, error] = await handlePromise(getPatients())
        setPatients(result.data.data as PatientModel[])
    }, [])

    useEffect(() => {
        fetchPatients()
        setDisabled(!isValidState(state))
    }, [fetchPatients, state, reloadPatients])

    const autoCompleteStyle = {
        width: "100%",
        background: palette.primary.lighter,
    };

    return (
        <>
            <DialogTitle style={{minWidth: '481px'}} color={palette.primary.dark}>CADASTRO DE PACIENTE</DialogTitle>
            <DialogContent>
                <Autocomplete
                    freeSolo
                    autoSelect
                    options={patients}
                    style={autoCompleteStyle}
                    limitTags={1}
                    clearOnBlur={false}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.name) {
                          return option.name;
                        }
                        return option.name;
                      }}
                    value={selected}
                    onChange={(event, newValue) => {
                         if (typeof newValue === 'object' && newValue!== null && newValue.name) {
                            setObservationState(true)
                            setState({
                                id: newValue.id,
                                patientName: newValue.name,
                                observations: newValue.observation,
                                consults: newValue.consults
                            });
                        } else if(typeof newValue === 'object' && newValue!== null) {
                            setState({patientName:newValue?.name});
                        }
                      }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params)
                        return filtered;
                      }}
                    renderOption={(props, option)=> {
                        return(
                            <li {...props} key={option.id}>
                                {option.name ? option.name : ''}
                            </li>
                        )
                    }}
                    renderInput={(params) => 
                        <TextField
                            {...params}
                            fullWidth
                            id="patientName"
                            color="primary"
                            variant="outlined"
                            label="NOME COMPLETO"
                            sx={{ marginTop: "8px", background: palette.primary.lighter }}
                            onChange={(e) => {
                                setState({ ...state, patientName: e.target.value, observations: ''})
                                setObservationState(false)
                            }}
                        />}
                />

                <TextField
                    fullWidth
                    focused={observationState}
                    id="observations"
                    color="primary"
                    multiline
                    rows={5}
                    variant="outlined"
                    label="OBSERVAÇÕES"
                    value={state.observations}
                    sx={{ marginTop: "16px", background: palette.primary.lighter }}
                    onChange={(e) => setState({ ...state, observations: e.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>
                    VOLTAR
                </Button>
                <Button
                    disabled={disabled}
                    onClick={() => handleSave(state as IPatientModalValues, onClose)}
                >
                    SALVAR
                </Button>
            </DialogActions>
        </>
    )
}
