import React from "react";
import { Alert } from "@material-ui/core";
import Space from "./Space";

// todo - remove this component after 30/06/2024
export const Instability: React.FC = () => {
    const currentDate = new Date();
    const endDisplayDate = new Date(2024, 5, 30, 23, 59, 59, 999);

    if (currentDate > endDisplayDate) {
        return null;
    }

    return (
        <div>
            <Space pixels={8} direction={'v'}/>

            <Alert severity="info">A plataforma ProAtivos passou por instabilidade em nosso
                sistema na sexta-feira (21 de junho de 2024). Devido a essa instabilidade, seu histórico de consulta dos
                dias 20 e 21 pode ter sofrido impacto. Estamos trabalhando para garantir a sua melhor experiência.
                Pedimos desculpa pelo transtorno e agradecemos sua compreensão!</Alert>
        </div>
    )
}
