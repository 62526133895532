import React from "react";
import palette from "../theme/palette";
import styled from "styled-components";

export const IconsContainer = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SpacedIconsContainer = styled.div`
  bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const CircleButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: ${palette.primary.main};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  svg {
    color: ${palette.primary.contrastText};
    width: 24px;
    height: 24px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

// @ts-ignore
export const btnFacebook: React.CSSProperties = {
  width: '100%',
  padding: '0px 8px',
  margin: '0px',
  minHeight: '46px',
  backgroundColor: 'rgb(59, 89, 152)',
  boxShadow: 'rgb(0 0 0 / 15%) 0px 4px 12px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  borderRadius: '2px',
  lineHeight: '46px',
  fontFamily: 'Poppins, sans-serif',
  fontSize: 'calc(.27548vw + 12.71074px)',
  textDecoration: 'none',
  textTransform: 'uppercase',
  transition: 'background-color .3s,border-color .3s',
  border: 'calc(.06887vw + .67769px) solid #4c69ba',
  fontWeight: 700,
  color: '#fff',
  cursor: 'pointer',
}

export const spanFacebook: React.CSSProperties = {
  transition: 'opacity 0.5s ease 0s',
  width: '50%',
  height: 'auto',
  marginLeft: '25px',
  padding: '0px',
  minHeight: '46px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}