import React, { useCallback, useState } from "react";
import { clearQuery, setQuery } from "../../../../services/local-session-storage";
import { addPatient } from "../../../../services/decisionFlow";
import QueryForm from "../form/query-form";
import { Button, Dialog } from "@material-ui/core";
import { AddPatient, IPatientModalValues } from "./add-patient";
import { ActionContainer, QueryContainer } from "../index.styled";
import { Person, PlayCircleFilled } from "@material-ui/icons";
import { BaseLayout } from "../../../../layouts/base/base.layout";
import { handlePromise } from "../../../../utils/handlePromisse";
import { getPatients, savePatient } from "../../../../services/patient.service";

type Props = {
    onFinish: () => void
}

export const Patient: React.FC<Props> = ({ onFinish }) => {
    const [open, setOpen] = useState(false)
    const [patientId, setPatientId] = useState(0)
    const [reloadPatient, setReloadPatient] = useState(false)

    const handleAdd = () => setOpen(!open)

    const handleInitiate = (patient?: number) => {
        clearQuery()
        setQuery({
            canEdit: true,
            patientId: !!patient ? patient : patientId,
        })
        onFinish()
    }

    const handlePatient = useCallback(async (patient?: IPatientModalValues) => {
        setOpen(false)
        const [result, error] = await handlePromise(getPatients())
        const filteredPatients = result.data.data.filter((data: IPatientModalValues) => data.id === patient?.id)

        if(filteredPatients.length > 0) {
            if (patient) {
                const data = {
                    id: filteredPatients[0].id,
                    name: patient.patientName,
                    observation: patient.observations,
                    consults: patient.consults
                } 
                await savePatient(Number(patient.id), data )

                    setReloadPatient(true)
                    handleInitiate(data.id)
                }

            } else if (!!patient) {
            const result = await addPatient({
                name: patient.patientName,
                observation: patient.observations,
            })

            setReloadPatient(true)
            handleInitiate(result.data.patientId)
        }
    }, [setOpen, setReloadPatient, setPatientId, handleInitiate])

    return (
        <BaseLayout hasLogo={true}>
            <QueryContainer style={{ height: '100%' }}>
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <AddPatient onClose={handlePatient} reloadPatients={reloadPatient} patientSelected={setPatientId}/>
                </Dialog>

                <QueryForm reloadPatients={reloadPatient} patientSelected={setPatientId}/>

                <ActionContainer>
                    <Button
                        size={"large"}
                        fullWidth={true}
                        onClick={handleAdd}
                        variant="contained"
                        startIcon={<Person/>}>CADASTRAR NOVO PACIENTE</Button>

                    <Button
                        fullWidth={true}
                        size={"large"}
                        color={"secondary"}
                        style={{ marginTop: '16px' }}
                        onClick={() => handleInitiate()}
                        variant="contained"
                        startIcon={<PlayCircleFilled/>}>INICIAR CONSULTA</Button>
                </ActionContainer>
            </QueryContainer>
        </BaseLayout>
    )
}
