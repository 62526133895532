import React, { useEffect, useState, useCallback } from 'react'
import {
    Autocomplete,
    Button,
    Dialog,
    MenuItem,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";
import { Edit, Visibility } from "@material-ui/icons";
import PageTitle from "../../../components/PageTitle";
import { stackFlexColumn } from "../../../utils/MUIShortcuts";
import Space from "../../../components/Space";
import {
    MessageDialog,
    messageErrorConfirmDialog,
    messageSuccessConfirmDialog,
    PropsMessageDialog
} from "../../../components/message-dialog";
import { Title } from "../../../theme/globalStyles";
import { Formik, useFormik } from 'formik';
import * as Yup from "yup";
import { AdmLayout } from "../../../layouts/base/adm.layout";
import styled from "styled-components";
import { handlePromise } from '../../../utils/handlePromisse';
import Select from '@mui/material/Select';
import { TutorialModel } from '../../../models/TutorialModel';
import { saveTutorial, getAllTutorial, getById } from '../../../services/tutorialService';
import { ItemBox, MainBox } from '../../main/query/definitions/definitions.styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;

  width: 100%;
  height: auto;
  min-height: 90vh;
`

interface Column {
    id: 'title'|'subtitle'|'description'|'actions';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'title', label: 'Titulo', minWidth: 170 },
    { id: 'subtitle', label: 'Subtitulo', minWidth: 170 },
    { id: 'description', label: 'Descrição', minWidth: 170 },
    { id: 'actions', label: 'Ações', minWidth: 230 },
]

export const Tutorial = () => {

    const productViewInitial: TutorialModel  = {
        id: undefined,
        enabled: true,
        title: "",
        subtitle: "",
        description: "",
        url: "",
        new_icon: true,
    }

    const productSchema = Yup.object().shape({
        title: Yup.string().required('Este campo é obrigatório'),
        subtitle: Yup.string().required('Este campo é obrigatório'),
        description: Yup.string().required('Este campo é obrigatório'),
        url: Yup.string().required('Este campo é obrigatório'),
        new_icon: Yup.string().required('Este campo é obrigatório'),
    })

    const formik = useFormik({
        initialValues: productViewInitial,
        validationSchema: productSchema,
        onSubmit: (values, actions) => {
            registerTutorial()
        }
    })

    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [tutorialViewModel, setTutorialViewModel] = React.useState<TutorialModel>(productViewInitial);
    const [btnSaveDisabled, setBtnSaveDisabled] = React.useState<boolean>(false)
    const [selectedTutorial, setSelectedTutorial] = useState<string[]>([]);
    const [tutorialDisabled, setTutorialDisabled] = React.useState<boolean>(false)
    const { errors, touched, handleSubmit, getFieldProps } = formik
    const [allTutorial, setAllTutorial] = React.useState<TutorialModel[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [dialogModel, setDialogModel] = React.useState<PropsMessageDialog>({
        onConfirm: () => {
            dialogModel.open = false
            setDialogModel({ ...dialogModel })
        },
        open: false
    } as PropsMessageDialog)

    const fetchAllTutorial = useCallback(async() => {
        const[result, error] = await handlePromise(getAllTutorial())
        setAllTutorial(result.data as TutorialModel[])
    },[])

    useEffect(() => {
        fetchAllTutorial()
    },[fetchAllTutorial])

    const resetForm = () => {
        setSelectedTutorial([])
        setTutorialViewModel({ ...productViewInitial })
        setTutorialDisabled(false)
    }

    const registerTutorial = () => {
        setBtnSaveDisabled(true)
        setOpenBackdrop(true)
        const tutorial: TutorialModel = {
            ...tutorialViewModel,
            }
            saveTutorial(tutorial)
            .then(v => {
                const message = tutorial.id == null ? "Tutorial cadastrado com sucesso!" : "Tutorial alterado com sucesso"
                setDialogModel({ ...messageSuccessConfirmDialog(dialogModel.onConfirm, message) })
                resetForm()
            })
            .catch(v => setDialogModel({ ...messageErrorConfirmDialog(dialogModel.onConfirm) }))
            .finally(() => {
                fetchAllTutorial()
                setOpenBackdrop(false)
                setBtnSaveDisabled(false)
            })
    }

    const updateFormikValues = (formik: any, values: any) => {
        Object.entries(values).map(([key, val]) => formik.values[key] = val)
    }

    const handleSearchChange = (event:any) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        updateFormikValues(formik, tutorialViewModel)
    }, [tutorialViewModel])

    const editTutorial = async(tutorial: TutorialModel) => {
        setOpenBackdrop(true)
        if (tutorial.id) {
            Promise.all([getById(tutorial.id)]).then(response => {
                if (response[0].status !== 200) {
                    setDialogModel({ ...messageErrorConfirmDialog(dialogModel.onConfirm) })
                    return;
                }
                tutorialViewModel.id = tutorial.id
                tutorialViewModel.title = tutorial.title
                tutorialViewModel.url = tutorial.url
                tutorialViewModel.subtitle = tutorial.subtitle
                tutorialViewModel.description = tutorial.description
                tutorialViewModel.new_icon = tutorial.new_icon

                
                setTutorialViewModel({ ...tutorialViewModel })
                setOpenBackdrop(false)
            })
        }
    }

    const handleClickEnable = useCallback(async(tutorial: TutorialModel) => {
        setOpenBackdrop(true)
        if(tutorial.enabled == true) {
            tutorial.enabled = false
            await saveTutorial(tutorial)
        } else {
            tutorial.enabled = true
           await saveTutorial(tutorial)
        }
       await fetchAllTutorial()
       setOpenBackdrop(false)
    },[])

    return (
        <AdmLayout hasLogo={true} loading={openBackdrop}>
            <div style={{ width: '90%' }}>
                <PageTitle title="ADM Users"/>
                <Title>Gestão de conteúdo de mídias - Tutoriais</Title>

            <Formik initialValues={productViewInitial} validationSchema={productSchema} onSubmit={() => {
                }}>

                <form noValidate onSubmit={handleSubmit}>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>    

                        <TextField
                            label={'Título'}
                            {...getFieldProps('title')}
                            value={tutorialViewModel.title}
                            variant="outlined"
                            color="primary"
                            sx={{ width: '40%', maxWidth: '400px', marginRight: '10px' }}
                            onChange={(event) => {
                                tutorialViewModel.title = event.target.value
                                setTutorialViewModel({ ...tutorialViewModel })
                            }}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                        />
                    
                        <TextField
                            label={'URL do video do Youtube'}
                            {...getFieldProps('url')}
                            value={tutorialViewModel.url}
                            variant="outlined"
                            color="primary"
                            sx={{ width: '40%', maxWidth: '400px', marginBottom: '10px' }}
                            onChange={(event) => {
                                const url = event.target.value
                                const videoId: string | undefined = url.split("v=")[1]?.split("&")[0];
                                tutorialViewModel.url = videoId
                                setTutorialViewModel({ ...tutorialViewModel })
                            }}
                            error={Boolean(touched.url && errors.url)}
                            helperText={touched.url && errors.url} 
                                />
                    </div>

                    <div style={{display: 'flex'}}>
                        <TextField
                            label={'Descrição do subtítulo'}
                            {...getFieldProps('subtitle')}
                            multiline
                            fullWidth
                            rows={6}
                            value={tutorialViewModel.subtitle}
                            variant="outlined"
                            color="primary"
                            sx={{ width: '100%', marginBottom: '10px' }}
                            onChange={(event) => {
                                tutorialViewModel.subtitle = event.target.value
                                setTutorialViewModel({ ...tutorialViewModel })
                            }}
                            error={Boolean(touched.subtitle && errors.subtitle)}
                            helperText={touched.subtitle && errors.subtitle}
                                />
                    </div>

                    <div style={{display: 'flex'}}>
                        <TextField
                            label={'Descrição do texto secundário'}
                            {...getFieldProps('description')}
                            multiline
                            fullWidth
                            rows={6}
                            value={tutorialViewModel.description}
                            variant="outlined"
                            color="primary"
                            sx={{ width: '100%', marginBottom: '10px'}}
                            onChange={(event) => {
                                tutorialViewModel.description = event.target.value
                                setTutorialViewModel({ ...tutorialViewModel })
                            }}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                                />
                    </div>

                    <div style={{ width: '100%', maxWidth: '400px', marginTop: '24px' }}>
                            <MainBox>
                                <ItemBox>
                                    <p>{"MOSTRAR ICONE DE 'NOVO?'" }</p>
                                    <Switch checked={tutorialViewModel.new_icon == true}
                                            onChange={(event) => {
                                                tutorialViewModel.new_icon = event.target.checked
                                                setTutorialViewModel({ ...tutorialViewModel })
                                            }}
                                            name={"new_icon"}/>
                                </ItemBox>
                            </MainBox>
                        </div>
                    
                    <Container style={{ minHeight: "15vh", display: "inline" }}>
                            <Stack {...stackFlexColumn('center', 'flex-end')} sx={{ width: '30%', float: "left" }}>
                                <Space pixels={50} direction="v"/>
                                <Button
                                    type="submit"
                                    size={"large"}
                                    fullWidth={true}
                                    color={"primary"}
                                    variant="contained"
                                    disabled={btnSaveDisabled}
                                >SALVAR</Button>
                            </Stack>

                            <Stack {...stackFlexColumn('center', 'flex-end')} sx={{ width: '30%', float: "right" }}>
                                <Space pixels={50} direction="v"/>
                                <Button
                                    size={"large"}
                                    fullWidth={true}
                                    color={"secondary"}
                                    variant="contained"
                                    onClick={() => resetForm()}
                                >Limpar Formulário</Button>
                            </Stack>
                        </Container>
                </form>
            </Formik>

            <Space pixels={200} direction="v"/>
                
                <Title>Pequisa por nome</Title>

                <TextField
                    sx={{width: '35%'}}
                    type="text"
                    placeholder="Buscar por nome"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    />
                
                <Space pixels={30} direction="v"/>

                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {allTutorial
                            .filter((row) =>
                                row.title.toLowerCase().includes(searchTerm.toLowerCase())
                                )
                            .map((row: any) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns
                                        .filter((column) => ['title', 'subtitle', 'description'].includes(column.id))
                                        .map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                <TableCell align={columns[0].align}>
                                    <Button onClick={() => editTutorial(row)}>
                                        <Edit />
                                    </Button>

                                    <Switch
                                        checked={row.enabled != false}
                                        onClick={() => handleClickEnable(row)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>

            </div>
            <MessageDialog
                emoji={dialogModel.emoji}
                open={dialogModel.open}
                title={dialogModel.title}
                message={dialogModel.message}
                onConfirm={dialogModel.onConfirm}
                confirmButtonText={dialogModel.confirmButtonText}/>
        </AdmLayout>
    )
}