import styled from "styled-components";

const QueryFormStyles = styled.div`
  .alert-info {
    background-color: rgb(229, 246, 253);
    margin-top: 10px;
    svg {
      color: #03a9f4;
    }
  }
`

export default QueryFormStyles;