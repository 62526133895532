import { withTheme } from "../../../theme";
import { flexRow } from "../../../utils/MUIShortcuts";

export const checkboxWrapper = withTheme((theme) => ({
  ...flexRow('center', 'center'),
  width: '100%',
  height: 'auto',
  minHeight: '80px',
  margin: `${theme.spacing(2)} 0px`,
  "p, svg": {
    color: 'white',
    "a": {
      fontWeight: 500,
      textDecoration: 'underline',
      color: 'white',
    }
  },
  [theme.breakpoints.down('md')]: {
    'p, a, svg': {
      color: `${theme.palette.primary.main} !important`
    }
  },
}))
