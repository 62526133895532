import { ProductEntries } from "../../../services/productService"
import { ActiveResponse, SubClassesObject } from "../../../services/decisionFlow";
import VehiclesModel from "../../../models/vehicles.model";
import { ConsultationModel } from "../../../domain/models/consultation.model";
import { ProductModel } from "../../../models/Product.model";

export type QueryModel = {
    vegan: boolean
    period: string
    organic: boolean
    pregnant: boolean
    patientId?: number
    actives: ActiveResponse[]
    subclass: SubClassesObject[]
    products: ProductModel[]
    vehicles: VehiclesModel|null
    observation?: string
    observations?: string[]
    activeList: boolean
    canEdit?: boolean
    consultationName: string
}

export const toProductEntries = (model: QueryModel) => {
    const both = model.period === 'AMBOS'
    let subclass: number[] = []
    if (model.subclass !== undefined) {
        subclass = model.subclass.map(value => value.id)
    }
    return {
        daytime: model.period === 'DIURNO' || both,
        nighttime: model.period === "NOTURNO" || both,
        actives: model.actives == null ? [] : model.actives.map(value => value.id),
        subclass: subclass,
        pregnant: model.pregnant || false,
        vegan: model.vegan || false,
        organic: model.organic || false
    } as ProductEntries
}

export const toConsultation = (consultation: ConsultationModel) => {
    let period = "NOTURNO";

    if (consultation.daytime && consultation.nighttime) {
        period = "AMBOS"
    } else if(consultation.daytime) {
        period = "DIURNO"
    }
    
    return {
        vegan: consultation.vegan,
        period: period,
        organic: consultation.organic,
        pregnant: consultation.pregnant,
        patientId: consultation.idPatient,
        products: consultation.products,
        actives: consultation.actives.map(active => {
            return {
                id: active
            } as ActiveResponse
        }),
        subclass: consultation.subclasses.map(value => {
            return {
                id: value
            } as SubClassesObject
        }),
        observations: consultation.observations,
        vehicles: consultation.vehicle,
        observation: consultation.observation,
    } as QueryModel
}
