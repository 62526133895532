import React from "react";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { TablePagination } from "@material-ui/core";
import { GiftModel } from "./gift.model";
import moment from 'moment';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import styled from "styled-components";
import Space from "../../../../components/Space";

interface Column {
    id: 'email'|'plan'|'usedIn'|'createdAt'|'endPlanDate';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (key: Date) => string;
}

const columns: readonly Column[] = [
    { id: 'email', label: 'Email', minWidth: 150 },
    { id: 'plan', label: 'Plano', minWidth: 100 },
    { id: 'createdAt', label: 'Enviado', minWidth: 100 },
    { id: 'usedIn', label: 'Utilizado', minWidth: 100 },
    { id: 'endPlanDate', label: 'Fim do plano', minWidth: 100 },
];

type Props = {
    page: number
    size: number
    total: number
    data: GiftModel[]
    onPageChange: (newPage: number, size: number) => void
    onSizeChange: (newSize: number, page: number) => void
}

const EmailRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const GiftTable: React.FC<Props> = ({
   data, page, size, total,
   onPageChange, onSizeChange,
}) => {

    const handleChangePage = (event: unknown, newPage: number) => {
        onPageChange(newPage + 1, size)
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSizeChange(+event.target.value, 0);
    };

    const date = (date: Date) => {
        if (!!date) {
            return moment(date).utcOffset(-180).format('DD/MM/YYYY')
        }
    }

    const email = (name: string, isNewUser: boolean) => {
        return (
            <EmailRow>
                {isNewUser && <FiberNewIcon/>}
                <Space pixels={8} direction={'h'}/>
                {name}
            </EmailRow>
        )
    }

    const createRows = (column: Column, model: GiftModel) => {
        const value = model[column.id]
        return (
            <TableCell key={column.id} align={column.align}>
                {
                    column.id === "usedIn" ? date(model.usedIn) :
                        column.id === "createdAt" ? date(model.createdAt) :
                            column.id === "endPlanDate" ? date(model.endPlanDate) :
                                column.id === "email" ? email(model.email, model.isNewUser) :
                                    value
                }
            </TableCell>
        )
    }

    const tableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}>
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    const tableBody = () => {
        return (
            <TableBody>
                {
                    data.map((gift) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={gift.email}>
                                {columns.map((column) => createRows(column, gift))}
                            </TableRow>
                        );
                    })}
            </TableBody>
        )
    }

    return (
        <Paper>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="active table">
                    {tableHeader()}
                    {tableBody()}
                </Table>
            </TableContainer>

            <TablePagination
                page={page - 1}
                component="div"
                count={total}
                rowsPerPage={size}
                rowsPerPageOptions={[5, 10, 25]}
                onPageChange={handleChangePage}
                labelRowsPerPage={"Linhas por página"}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
        </Paper>
    );
}