

// Material Icons

// Eva Icons
import homeOutline from "@iconify/icons-eva/home-outline"
import peopleOutline from "@iconify/icons-eva/people-outline"
import cash from '@iconify-icons/mdi/cash'
import search from '@iconify/icons-ant-design/file-search-outlined'
import history from '@iconify-icons/mdi/history'
import actives from '@iconify-icons/mdi/arrow-up-thick'
import account from '@iconify-icons/mdi/account'
import helpCircle from '@iconify-icons/mdi/help-circle'
import shopping from '@iconify-icons/mdi/shopping'
import baselineAppRegistration from '@iconify/icons-ic/baseline-app-registration'
import CardGiftcardIcon from '@iconify/icons-eva/gift-outline'
import SchoolIcon from '@iconify-icons/mdi/school'
import MdiCamera from '@iconify-icons/mdi/camera';
import youtube from '@iconify-icons/mdi/youtube';
import fibernew from '@iconify-icons/mdi/fiber-new';

import {getIcon} from "../../utils/IconUtil";
// ----------------------------------------------------------------------

export const commonUserOptions: any[] = [
  {
    title: "início",
    path: "/dashboard/app",
    icon: getIcon(homeOutline),
  },
  {
    title: "perfil",
    path: "/dashboard/profile",
    icon: getIcon(account),
  },
  {
    title: 'planos',
    path: '/dashboard/pricing',
    icon: getIcon(cash)
  },
  {
    title: 'consulta',
    path: '/dashboard/query',
    icon: getIcon(search)
  },
  {
    title: 'histórico de consultas',
    path: '/dashboard/history',
    icon: getIcon(history)
  },
  {
    title: 'ativos',
    path: '/dashboard/actives',
    icon: getIcon(actives)
  },
  {
    title: 'tutoriais',
    path: '/dashboard/tutorial',
    icon: getIcon(fibernew)
  },
  {
    title: 'suporte',
    path: '/dashboard/suport',
    icon: getIcon(helpCircle)
  }
]

export const admUserOptions: Array<any> = [
  {
    title: "usuários",
    path: "/adm/users",
    icon: getIcon(peopleOutline),
  },
  {
    title: "gestão de classes",
    path: "/adm/actives",
    icon: getIcon(actives),
  },
  {
    title: "gestão de subclasses",
    path: "/adm/subclass",
    icon: getIcon(actives),
  },
  {
    title: "gestão de ativos",
    path: "/adm/products",
    icon: getIcon(baselineAppRegistration),
  },
  {
    title: "gestão de planos",
    path: "/adm/plans",
    icon: getIcon(shopping),
  },
  {
    title: "gestão de presentes",
    path: "/adm/gifts",
    icon: getIcon(CardGiftcardIcon),
  },
  {
    title: "DCO",
    path: "/adm/dco",
    icon: getIcon(SchoolIcon),
  },
  {
    title: "Gestão de mídia",
    path: "/adm/tutorial",
    icon: getIcon(MdiCamera)
  },
]
