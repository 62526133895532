import React, { useEffect, useRef, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Dialog, TextField } from "@material-ui/core";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

type Props = {
    annotation?: string
    open: boolean
    onSaveAnnotation: (annotation: string) => void
    onClose: () => void
}

export const AnnotationDialog: React.FC<Props> = ({ annotation, open, onClose, onSaveAnnotation }) => {
    const [text, setText] = useState(annotation || '')

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>Minhas anotações
                <CloseIcon sx={{ cursor: 'pointer', }} onClick={onClose}/>
            </DialogTitle>

            <DialogContent>

                <DialogContentText>
                    Coloque as anotações sobre esse ativo aqui. Essa anotação só será visível pra você e pode ser usada nos mecanismos de busca dentro da plataforma.
                </DialogContentText>

                <TextField
                    autoFocus
                    margin="dense"
                    id="outlined-multiline-flexible"
                    label="Observação"
                    multiline
                    fullWidth
                    type="text"
                    maxRows={8}
                    value={text}
                    variant="outlined"
                    inputProps={{maxLenght: 250}}
                    helperText={`${text.length}/${250}`}
                    onChange={(e) => {
                        setText(e.target.value)
                    }}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={() => onSaveAnnotation(text.trim())}>Salvar</Button>
            </DialogActions>

        </Dialog>
    )
}