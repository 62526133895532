import React, { useCallback, useEffect, useState } from "react";
import { TextField, useMediaQuery } from "@material-ui/core";
import Space from "../../../components/Space";
import { handlePromise } from "../../../utils/handlePromisse";
import { deletePatient, getPatientsPaginated2, Order, Patient, PatientFilter, savePatient } from "../../../services/patient.service";
import { initialPagination, PaginatedResponse } from "../../../domain/models/paginated-response.model";
import { ViewConsultsDialog } from "./modals/view-consults.dialog";
import { ViewPatientDialog } from "./modals/view-patient.dialog";
import { ConfirmDeleteDialog } from "./modals/confirm-delete.dialog";
import styled from "styled-components";
import palette from "../../../theme/palette";
import { PatientTable } from "./table/patient-table";
import { AdmLayout } from "../../../layouts/base/adm.layout";
import { SimpleSnackbar } from "../../../components/snackbar";
import { getPdfByConsultationId } from "../../../services/decisionFlow";
import { redirectToPDF } from "../../../utils/pdf.util";
import { useNavigate } from "react-router";
import { deleteConsult } from "../../../services/history";
import { MessageDialog } from "../../../components/message-dialog";
import { Instability } from "../../../components/instability";

const Title = styled.h2`
  font-size: 1.5rem;
  color: ${palette.primary.dark};
  width: 100%;
  text-align: center;
`

export const HistoryPage: React.FC = () => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width: 850px)')

    const [filter, setFilter] = useState<PatientFilter>({ page: 1, size: 5 })
    const [viewConsultDialog, setViewConsultDialog] = useState(false)
    const [editPatientDialog, setEditPatientDialog] = useState(false)
    const [deletePatientDialog, setDeletePatientDialog] = useState(false)
    const [deleteConsultDialog, setDeleteConsultDialog] = useState(false)
    const [patients, setPatients] = useState<PaginatedResponse<Patient>>(initialPagination)
    const [patientSelected, setPatientSelected] = useState<Patient|null>(null)
    const [consultSelected, setConsultSelected] = useState<number|null>(null)
    const [conflictDialog, setConflictDialog] =
        useState<{show: boolean, message: string, consults: number[]}>({ message: "", show: false, consults: [] })

    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')

    const [loading, setLoading] = useState(false)

    const fetchPatients = useCallback(async () => {
        setLoading(true)
        const [result, error] = await handlePromise(getPatientsPaginated2(filter))
        setLoading(false)

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setPatients(result.data as PaginatedResponse<Patient>)
    }, [filter])

    const handleSavePatient = useCallback(async (patient: Patient) => {
        const [_, error] = await handlePromise(savePatient(patient.id, patient))

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setEditPatientDialog(false)
        await fetchPatients()
    }, [savePatient, setEditPatientDialog, fetchPatients])

    const handleDeletePatient = useCallback(async (patient: number) => {
        const [_, error] = await handlePromise(deletePatient(patient))

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setDeletePatientDialog(false)
        await fetchPatients()
    }, [deletePatient, setDeletePatientDialog, fetchPatients])

    const handleDeleteConsult = async (consult: number) => {
        const [_, error] = await handlePromise(deleteConsult(consult))

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }
        setDeleteConsultDialog(false)
        await fetchPatients()
    }

    useEffect(() => {
        fetchPatients()
    }, [fetchPatients])

    const handlePageChange = useCallback(async (newPage: number, size: number) => {
        setFilter({ ...filter, page: newPage, size })
    }, [filter])

    const handleSizeChange = useCallback(async (newSize: number, page: number) => {
        setFilter({ ...filter, page, size: newSize })
    }, [filter])

    const handleOrderChange = useCallback(async (orderBy: keyof Patient, order: Order) => {
        setFilter({ ...filter, orderBy: orderBy, order : order })
    }, [filter])

    const handleFilter = useCallback(async (query?: string) => {
        setFilter({ ...filter, search: query })
    }, [filter])

    const handleConsultsPatient = useCallback(async (patient: Patient) => {
        setPatientSelected(patient)
        setViewConsultDialog(true)
    }, [setPatientSelected, setViewConsultDialog])

    const showEditDialog = useCallback(async (patient: Patient) => {
        setPatientSelected(patient)
        setEditPatientDialog(true)
    }, [setPatientSelected, setEditPatientDialog])

    const showDeleteDialog = useCallback(async (patient: Patient) => {
        setPatientSelected(patient)
        setDeletePatientDialog(true)
    }, [setPatientSelected, setEditPatientDialog])

    const handleCloseConsultDialog = useCallback(async (reload: boolean) => {
        setViewConsultDialog(false)
        if (reload) await fetchPatients()
    }, [setViewConsultDialog, fetchPatients])

    const handlePdfGenerate = async (consults: number[], skipe: boolean = false) => {
        setConflictDialog({ show: false, message: "", consults: [] })
        setLoading(true)
        const [result, error] = await handlePromise(getPdfByConsultationId(consults, skipe))
        if (error) {
            if (error.response && error.response.data && error.response.data.error === 'HAS_CONFLICT') {
                const message = error.response.data.message
                setConflictDialog({ show: true, message, consults })
            } else {
                setSnackbarMessage('Erro ao gerar PDF');
                setShowSnackbar(true)
            }
            setLoading(false)
            return
        }

        const base64 = result.data.pdf
        redirectToPDF(base64)
        setLoading(false)
    }

    const onNavigateReview = (id: number) => {
        navigate(`/dashboard/reviewConsult/${id}`)
    }

    const onDeleteConsult = async (id: number) => {
        setConsultSelected(id)
        setDeleteConsultDialog(true)
    }

    return (
        <AdmLayout hasLogo={true} loading={loading}>
            {/*Dialogs*/}
            {!!patientSelected && viewConsultDialog &&
                <ViewConsultsDialog
                    patientId={patientSelected.id}
                    open={viewConsultDialog}
                    onClose={handleCloseConsultDialog}
                />
            }
            {!!patientSelected && deletePatientDialog &&
                <ConfirmDeleteDialog
                    open={deletePatientDialog}
                    resourceId={patientSelected.id}
                    onConfirm={(id) => handleDeletePatient(id)}
                    onClose={() => setDeletePatientDialog(false)}
                    title={'TEM CERTEZA QUE DESEJA REMOVER ESSE PACIENTE E SUAS CONSULTAS?'}
                    message={'Essa ação é IRREVERSSÍVEL! E estes dados não poderão ser recuperados.'}
                />
            }

            {!!consultSelected && deleteConsultDialog &&
                <ConfirmDeleteDialog
                    open={deleteConsultDialog}
                    resourceId={consultSelected}
                    onConfirm={(id) => handleDeleteConsult(id)}
                    onClose={() => setDeleteConsultDialog(false)}
                    title={'TEM CERTEZA QUE DESEJA REMOVER ESSA CONSULTA?'}
                    message={'Essa ação é IRREVERSSÍVEL! E estes dados não poderão ser recuperados.'}
                />
            }

            {!!patientSelected && editPatientDialog &&
                <ViewPatientDialog
                    open={editPatientDialog}
                    patient={patientSelected}
                    onSave={(patient) => handleSavePatient(patient)}
                    onClose={() => setEditPatientDialog(false)}/>
            }
            {conflictDialog.show &&
                <MessageDialog
                    open={conflictDialog.show}
                    message={conflictDialog.message}
                    onConfirm={() => handlePdfGenerate(conflictDialog.consults, true)}
                    onClose={() => setConflictDialog({ show: false, message: '', consults: [] })}
                    cancelButtonText={'revisar minhas fórmulas'}
                    confirmButtonText={'continuar mesmo assim'}
                />
            }
            {/*Dialogs*/}

            <SimpleSnackbar
                show={showSnackbar}
                message={snackbarMessage}
                onDismiss={() => setShowSnackbar(false)}
            />

            <Title>HISTÓRICO</Title>

            <Instability/>

            <Space pixels={8} direction={'v'}/>

            <TextField
                label={'Buscar...'}
                variant="outlined"
                color="primary"
                onChange={(e) => handleFilter(e.target.value)}
                value={filter.search}
                sx={{ width: '100%' }}
            />

            <Space pixels={8} direction={'v'}/>

            <PatientTable
                isMobile={isMobile}
                patients={patients.data}
                onPageChange={handlePageChange}
                onSizeChange={handleSizeChange}
                onOrderChange={handleOrderChange}
                onPdfGenerate={handlePdfGenerate}
                total={patients.pagination.total}
                size={patients.pagination.perPage}
                page={patients.pagination.currentPage}
                onEdit={showEditDialog}
                onDelete={showDeleteDialog}
                onEditConsult={onNavigateReview}
                onDeleteConsult={onDeleteConsult}
            />
        </AdmLayout>
    )
}
