import React, { useEffect, useState } from 'react'
import {
    Autocomplete,
    Button,
    Dialog,
    FormControl,
    MenuItem,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@material-ui/core";
import PageTitle from "../../../components/PageTitle";
import ActiveModel from "../../../models/ActiveModel";
import { getAllActives } from "../../../services/activeService";
import { getSubclassByActive } from "../../../services/subclassService";
import { stackFlexColumn } from "../../../utils/MUIShortcuts";
import Space from "../../../components/Space";
import { ItemBox, MainBox } from "../../main/query/definitions/definitions.styled";
import Periods from "../../../utils/periods";
import {
    disableProduct,
    enableProduct,
    getActiveProducts,
    getProductsWithFilterNPagination,
    getSubclassProducts,
    ProductsWithFilterNPaginationResponse,
    saveProduct
} from "../../../services/productService";
import SubclassActiveCompositeModel from "../../../models/SubclassActiveComposite.model";
import { ProductDetail } from "../../main/query/products/product-detail";
import { ProductViewModel } from "../../../models/ProductView.model";
import { getDescriptionPeriodByProduct } from "../../../services/periodService";
import {
    MessageDialog,
    messageErrorConfirmDialog,
    messageSuccessConfirmDialog,
    PropsMessageDialog
} from "../../../components/message-dialog";
import { Title } from "../../../theme/globalStyles";
import { Edit, Visibility } from "@material-ui/icons";
import { Formik, useFormik } from 'formik';
import * as Yup from "yup";
import { ProductModel, productModelToDetails } from "../../../models/Product.model";
import SubclassModel from "../../../models/SubclassModel";
import { SearchProductModel } from "../../../models/SearchProduct.model";
import { AdmLayout } from "../../../layouts/base/adm.layout";
import styled from "styled-components";

const autocompleteWrapper: React.CSSProperties = {
    float: 'left',
    width: '50%',
    //height: 'auto',
    margin: '24px 0',
    maxHeight: '50px'
}

interface Column {
    id: 'name_Product'|'description_Product'|'mechanismAction_Product'|'actions';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'name_Product', label: 'Nome', minWidth: 170 },
    { id: 'description_Product', label: 'Descrição', minWidth: 170 },
    { id: 'mechanismAction_Product', label: 'Mecanismo de ação', minWidth: 170 },
    { id: 'actions', label: 'Ações', minWidth: 230 },
]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  
  width: 100%;
  height: auto;
  min-height: 90vh;
`

export const ProductPage = () => {

    const productViewInitial: ProductViewModel = {
        idProduct: undefined,
        idBody: 2,
        idTypeUse: 1,
        description: "",
        active: [],
        subclass: [],
        productName: "",
        mechanismAction: "",
        phProduct: "",
        minimumDosage: "",
        maxDosage: "",
        source: "",
        incompatibility: "",
        pregnant: false,
        vegan: false,
        organic: false,
        period: ""
    }

    const productSchema = Yup.object().shape({
        description: Yup.string().required('Este campo é obrigatório'),
        active: Yup.mixed().test({
            message: 'Este campo é obrigatório',
            test: val => val.length > 0
        }),
        productName: Yup.string().required('Este campo é obrigatório'),
        mechanismAction: Yup.string().required('Este campo é obrigatório'),
        phProduct: Yup.string().required('Este campo é obrigatório'),
        minimumDosage: Yup.string().required('Este campo é obrigatório'),
        maxDosage: Yup.string().required('Este campo é obrigatório'),
        source: Yup.string().required('Este campo é obrigatório'),
        incompatibility: Yup.string().required('Este campo é obrigatório'),
        period: Yup.string().required('Este campo é obrigatório'),
        pregnant: Yup.boolean().required('Este campo é obrigatório'),
        vegan: Yup.boolean().required('Este campo é obrigatório'),
        organic: Yup.boolean().required('Este campo é obrigatório')
    })

    const formik = useFormik({
        initialValues: productViewInitial,
        validationSchema: productSchema,
        onSubmit: (values, actions) => {
            registerProduct()
        }
    })

    const { errors, touched, handleSubmit, getFieldProps } = formik

    const [subclassDisabled, setSubclassDisabled] = React.useState<boolean>(true)
    const [activeDisabled, setActiveDisabled] = React.useState<boolean>(false)
    const [actives, setActives] = React.useState<ActiveModel[]>([])
    const [subclasses, setSubclasses] = React.useState<SubclassActiveCompositeModel[]>([])
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10)
    const [page, setPage] = React.useState<number>(0)
    const [rowsProducts, setRowsProducts] = React.useState<ProductsWithFilterNPaginationResponse[]>([])
    const [open, setOpen] = useState(false)
    const [selectedProduct, setSelectedProduct] = React.useState<ProductModel>({} as ProductModel)
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [productViewModel, setProductViewModel] = React.useState<ProductViewModel>(productViewInitial);
    const [searchProductModel, setSearchProductModel] = React.useState({
        disabledProduct: 'AMBOS',
        period: 'AMBOS'
    } as SearchProductModel)
    const [btnSaveDisabled, setBtnSaveDisabled] = React.useState<boolean>(false)
    const [dialogModel, setDialogModel] = React.useState<PropsMessageDialog>({
        onConfirm: () => {
            dialogModel.open = false
            setDialogModel({ ...dialogModel })
        },
        open: false
    } as PropsMessageDialog)

    const resetForm = () => {
        setProductViewModel({ ...productViewInitial })
        setActiveDisabled(false)
    }

    const registerProduct = () => {
        setBtnSaveDisabled(true)
        setOpenBackdrop(true)
        const product: ProductModel = {
            ...productViewModel,
            pH: productViewModel.phProduct,
            daytime: null, classes: null, nighttime: null,
            subclass: productViewModel.subclass ? productViewModel.subclass.map(v => {
                return { id: v.idSubclass, name: v.nameSubclass } as SubclassModel
            }) : []
        }

        saveProduct(product)
            .then(v => {
                const message = product.idProduct == null ? "Ativo cadastrado com sucesso!" : "Ativo alterado com sucesso"
                setDialogModel({ ...messageSuccessConfirmDialog(dialogModel.onConfirm, message) })
                resetForm()
                getProductsByPagination(page, rowsPerPage, searchProductModel);
            })
            .catch(v => setDialogModel({ ...messageErrorConfirmDialog(dialogModel.onConfirm) }))
            .finally(() => {
                setOpenBackdrop(false)
                setBtnSaveDisabled(false)
            })
    }

    const updateFormikValues = (formik: any, values: any) => {
        Object.entries(values).map(([key, val]) => formik.values[key] = val)
    }

    const handleChangePage = async (event: unknown, newPage: number) => {
        setPage(newPage);
        getProductsByPagination((newPage * rowsPerPage), rowsPerPage, searchProductModel)
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        getProductsByPagination(0, +event.target.value, searchProductModel)
    };

    const getProductsByPagination = (page: number, rowsPerPage: number, searchProduct: SearchProductModel, callbackFinally?: Function) => {
        getProductsWithFilterNPagination(page, rowsPerPage, searchProduct).then(result => {
            if (result.status === 200) {
                setRowsProducts(result.data)
            }
        }).catch(result => {
            setDialogModel({ ...messageErrorConfirmDialog(dialogModel.onConfirm) })
        }).finally(() => {
            if (callbackFinally != null) callbackFinally()
        })
    }

    const handleClickEnableProduct = (product: ProductsWithFilterNPaginationResponse) => {
        let message = "Ativo desabilitado com sucesso"
        let promise = disableProduct

        if (product.disabled_Product === 1) {
            message = "Ativo habilitado com sucesso"
            promise = enableProduct;
        }

        promise(product.id_Product.toString()).then(v => {
            getProductsByPagination(page, rowsPerPage, searchProductModel);
            setDialogModel({ ...messageSuccessConfirmDialog(dialogModel.onConfirm, message) })
        }).catch(v => {
            setDialogModel({ ...messageErrorConfirmDialog(dialogModel.onConfirm) })
        })
    }

    const handleClickViewProduct = (product: ProductsWithFilterNPaginationResponse) => {
        setSelectedProduct(product as unknown as ProductModel)
        setOpen(true)
    }

    const handleClickEditProduct = (product: ProductsWithFilterNPaginationResponse) => {
        setOpenBackdrop(true)

        Promise.all([getActiveProducts(product.id_Product.toString()), getSubclassProducts(product.id_Product.toString())]).then(response => {
            if (response[0].status !== 200 || response[1].status !== 200) {
                setDialogModel({ ...messageErrorConfirmDialog(dialogModel.onConfirm) })
                return;
            }

            productViewModel.active = response[0].data.activeModel
            productViewModel.subclass = response[1].data.subclassModel
            productViewModel.productName = product.name_Product
            productViewModel.phProduct = product.pH_Product
            productViewModel.minimumDosage = product.minimumDosage_Product
            productViewModel.maxDosage = product.maxDosage_Product
            productViewModel.incompatibility = product.incompatibility_Product
            productViewModel.source = product.source_Product
            productViewModel.mechanismAction = product.mechanismAction_Product
            productViewModel.description = product.description_Product
            productViewModel.pregnant = product.pregnant_Product === 1
            productViewModel.organic = product.organic_Product === 1
            productViewModel.vegan = product.vegan_Product === 1
            productViewModel.idProduct = product.id_Product
            productViewModel.period = getDescriptionPeriodByProduct(product)

            setActiveDisabled(true)
            setProductViewModel({ ...productViewModel })
            setOpenBackdrop(false)
        })
    }

    const searchProducts = () => {
        setOpenBackdrop(true)
        getProductsByPagination(page, rowsPerPage, searchProductModel, () => setOpenBackdrop(false))
    }

    useEffect(() => {
        getAllActives().then(result => {
            if (result.status === 200) {
                setActives(result.data.data)
            }
        }).catch(v => {
            setDialogModel({ ...messageErrorConfirmDialog(dialogModel.onConfirm) })
        });

        searchProducts();
    }, [])

    useEffect(() => {
        if (productViewModel.active != null && productViewModel.active.length > 0) {
            const idsActives = productViewModel.active.filter(v => v.id != null).map(v => v.id).join(',')

            getSubclassByActive(idsActives).then(result => {
                if (result.status === 200) {
                    setSubclasses(result.data.data)
                }
            }).catch(v => {
                setDialogModel({ ...messageErrorConfirmDialog(dialogModel.onConfirm) })
            })
        }
    }, [productViewModel.active])

    useEffect(() => {
        updateFormikValues(formik, productViewModel)
    }, [productViewModel])

    return (
        <AdmLayout hasLogo={true} loading={openBackdrop}>
            <div style={{ width: '90%' }}>
                <PageTitle title="ADM Users"/>
                <Title>Gestão de Ativos</Title>

                <Formik initialValues={productViewInitial} validationSchema={productSchema} onSubmit={() => {
                }}>
                    <form noValidate onSubmit={handleSubmit}>
                        <div style={{ width: '100%' }}>
                            <div style={autocompleteWrapper}>
                                <Autocomplete
                                    multiple
                                    value={productViewModel.active}
                                    disabled={activeDisabled}
                                    id="combo-box-demo"
                                    options={actives}
                                    sx={{ width: 400 }}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Classes"
                                                                        {...getFieldProps('active')}
                                                                        error={Boolean(touched.active && errors.active)}
                                                                        helperText={touched.active && errors.active}/>}
                                    onChange={(event: any, newValue: any, reason: any) => {
                                        const disabledSubclass = newValue == null || newValue.length == 0
                                        productViewModel.active = newValue
                                        productViewModel.subclass = disabledSubclass ? [] : productViewModel.subclass
                                        setSubclassDisabled(disabledSubclass)
                                        setProductViewModel({ ...productViewModel })

                                    }}
                                />
                            </div>

                            <div style={autocompleteWrapper}>
                                <Autocomplete
                                    multiple
                                    value={productViewModel.subclass}
                                    disabled={subclassDisabled}
                                    id="combo-box-demo"
                                    options={subclasses}
                                    sx={{ width: 400 }}
                                    groupBy={(option) => option.nameActive}
                                    getOptionLabel={(option) => option.nameSubclass}
                                    renderInput={(params) => <TextField {...params} label="Subclasses"/>}
                                    onChange={(event: any, newValue: any, reason: any) => {
                                        productViewModel.subclass = newValue
                                        setProductViewModel({ ...productViewModel });
                                    }}
                                />
                            </div>

                            <div style={autocompleteWrapper}>
                                <TextField
                                    label={'Nome do produto'}
                                    {...getFieldProps('productName')}
                                    value={productViewModel.productName}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ width: '100%', maxWidth: '400px' }}
                                    onChange={(event) => {
                                        productViewModel.productName = event.target.value
                                        setProductViewModel({ ...productViewModel })
                                    }}
                                    error={Boolean(touched.productName && errors.productName)}
                                    helperText={touched.productName && errors.productName}
                                />
                            </div>

                            <div style={autocompleteWrapper}>
                                <TextField
                                    label={'PH de estabilidade'}
                                    {...getFieldProps('phProduct')}
                                    value={productViewModel.phProduct}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ width: '100%', maxWidth: '400px' }}
                                    onChange={(event) => {
                                        productViewModel.phProduct = event.target.value
                                        setProductViewModel({ ...productViewModel })
                                    }}
                                    error={Boolean(touched.phProduct && errors.phProduct)}
                                    helperText={touched.phProduct && errors.phProduct}
                                />
                            </div>

                            <div style={autocompleteWrapper}>
                                <TextField
                                    label={'Dosagem minima'}
                                    {...getFieldProps('minimumDosage')}
                                    value={productViewModel.minimumDosage}
                                    variant="outlined"
                                    type="number"
                                    color="primary"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    sx={{ width: '100%', maxWidth: '400px' }}
                                    onChange={(event) => {
                                        productViewModel.minimumDosage = event.target.value
                                        setProductViewModel({ ...productViewModel })
                                    }}
                                    error={Boolean(touched.minimumDosage && errors.minimumDosage)}
                                    helperText={touched.minimumDosage && errors.minimumDosage}
                                />
                            </div>

                            <div style={autocompleteWrapper}>
                                <TextField
                                    label={'Dosagem máxima'}
                                    {...getFieldProps('maxDosage')}
                                    value={productViewModel.maxDosage}
                                    variant="outlined"
                                    type="number"
                                    color="primary"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    sx={{ width: '100%', maxWidth: '400px' }}
                                    onChange={(event) => {
                                        productViewModel.maxDosage = event.target.value
                                        setProductViewModel({ ...productViewModel })
                                    }}
                                    error={Boolean(touched.maxDosage && errors.maxDosage)}
                                    helperText={touched.maxDosage && errors.maxDosage}
                                />
                            </div>

                            <div style={autocompleteWrapper}>
                                <TextField
                                    label={'Incompatibilidade'}
                                    {...getFieldProps('incompatibility')}
                                    value={productViewModel.incompatibility}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ width: '100%', maxWidth: '400px' }}
                                    onChange={(event) => {
                                        productViewModel.incompatibility = event.target.value
                                        setProductViewModel({ ...productViewModel })
                                    }}
                                    error={Boolean(touched.incompatibility && errors.incompatibility)}
                                    helperText={touched.incompatibility && errors.incompatibility}
                                />
                            </div>

                            <div style={autocompleteWrapper}>
                                <TextField
                                    label={'Fonte'}
                                    {...getFieldProps('source')}
                                    value={productViewModel.source}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ width: '100%', maxWidth: '400px' }}
                                    onChange={(event) => {
                                        productViewModel.source = event.target.value
                                        setProductViewModel({ ...productViewModel })
                                    }}
                                    error={Boolean(touched.source && errors.source)}
                                    helperText={touched.source && errors.source}
                                />
                            </div>

                            <div style={{ ...autocompleteWrapper, maxHeight: 'auto' }}>
                                <TextField
                                    label={'Mecanismo de ação'}
                                    {...getFieldProps('mechanismAction')}
                                    value={productViewModel.mechanismAction}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ width: '100%', maxWidth: '400px' }}
                                    multiline
                                    rows={4}
                                    onChange={(event) => {
                                        productViewModel.mechanismAction = event.target.value
                                        setProductViewModel({ ...productViewModel })
                                    }}
                                    error={Boolean(touched.mechanismAction && errors.mechanismAction)}
                                    helperText={touched.mechanismAction && errors.mechanismAction}
                                />
                            </div>

                            <div style={{ ...autocompleteWrapper, maxHeight: 'auto' }}>
                                <TextField
                                    label={'Descrição'}
                                    {...getFieldProps('description')}
                                    value={productViewModel.description}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ width: '100%', maxWidth: '400px' }}
                                    multiline
                                    rows={4}
                                    onChange={(event) => {
                                        productViewModel.description = event.target.value
                                        setProductViewModel({ ...productViewModel })
                                    }}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                            </div>

                            <TextField
                                select
                                fullWidth
                                {...getFieldProps('period')}
                                name={'period'}
                                label="Período"
                                value={productViewModel.period}
                                sx={{ width: '100%', maxWidth: '1100px', marginTop: '24px' }}
                                onChange={(event) => {
                                    productViewModel.period = event.target.value
                                    setProductViewModel({ ...productViewModel })
                                }}
                                error={Boolean(touched.period && errors.period)}
                                helperText={touched.period && errors.period}
                            >
                                {Periods.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div style={{ width: '100%', maxWidth: '400px', marginTop: '24px' }}>
                            <MainBox>
                                <ItemBox>
                                    <p>{"PERMITIDO EM GESTANTES"}</p>
                                    <Switch checked={productViewModel.pregnant}
                                            onChange={(event) => {
                                                productViewModel.pregnant = event.target.checked
                                                setProductViewModel({ ...productViewModel })
                                            }}
                                            name={"pregnant"}/>
                                </ItemBox>
                            </MainBox>
                        </div>

                        <div style={{ width: '100%', maxWidth: '400px' }}>
                            <MainBox>
                                <ItemBox>
                                    <p>{"VEGANO"}</p>
                                    <Switch checked={productViewModel.vegan}
                                            onChange={(event) => {
                                                productViewModel.vegan = event.target.checked
                                                setProductViewModel({ ...productViewModel })
                                            }}
                                            name={"vegan"}/>
                                </ItemBox>
                            </MainBox>
                        </div>

                        <div style={{ width: '100%', maxWidth: '400px' }}>
                            <MainBox>
                                <ItemBox>
                                    <p>{"ORGÂNICO"}</p>
                                    <Switch checked={productViewModel.organic}
                                            onChange={(event) => {
                                                productViewModel.organic = event.target.checked
                                                setProductViewModel({ ...productViewModel })
                                            }}
                                            name={"organic"}/>
                                </ItemBox>
                            </MainBox>
                        </div>

                        <Container style={{ minHeight: "15vh", display: "inline" }}>
                            <Stack {...stackFlexColumn('center', 'flex-end')} sx={{ width: '30%', float: "left" }}>
                                <Space pixels={50} direction="v"/>
                                <Button
                                    type="submit"
                                    size={"large"}
                                    fullWidth={true}
                                    color={"primary"}
                                    variant="contained"
                                    disabled={btnSaveDisabled}
                                >SALVAR</Button>
                            </Stack>

                            <Stack {...stackFlexColumn('center', 'flex-end')} sx={{ width: '30%', float: "right" }}>
                                <Space pixels={50} direction="v"/>
                                <Button
                                    size={"large"}
                                    fullWidth={true}
                                    color={"secondary"}
                                    variant="contained"
                                    onClick={() => resetForm()}
                                >Limpar Formulário</Button>
                            </Stack>
                        </Container>
                    </form>
                </Formik>

                <Space pixels={200} direction="v"/>

                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <Title>Listagem de Ativos</Title>
                    <Space pixels={50} direction="v"/>

                    <FormControl>
                        <div style={{ width: '400px', float: 'left', margin: '10px' }}>
                            <TextField
                                label={'Nome'}
                                variant="outlined"
                                color="primary"
                                sx={{ width: '100%', maxWidth: '400px' }}
                                value={searchProductModel.productName}
                                onChange={(event) => {
                                    searchProductModel.productName = event.target.value
                                    setSearchProductModel({ ...searchProductModel })
                                }}
                            />
                        </div>

                        <div style={{ width: '400px', float: 'left', margin: '10px' }}>
                            <TextField
                                select
                                label="Período"
                                variant="outlined"
                                color="primary"
                                sx={{ width: '100%', maxWidth: '400px' }}
                                value={searchProductModel.period}
                                onChange={(event) => {
                                    searchProductModel.period = event.target.value
                                    setSearchProductModel({ ...searchProductModel })
                                }}
                            >
                                {Periods.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div style={{ width: '400px', float: 'left', margin: '10px' }}>
                            <TextField
                                select
                                label={'Status'}
                                variant="outlined"
                                color="primary"
                                sx={{ width: '100%', maxWidth: '400px' }}
                                value={searchProductModel.disabledProduct}
                                onChange={(event) => {
                                    searchProductModel.disabledProduct = event.target.value
                                    setSearchProductModel({ ...searchProductModel })
                                }}
                            >
                                {["AMBOS", "ATIVO", "INATIVO"].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div>
                            <Stack {...stackFlexColumn('center', 'flex-end')}
                                   sx={{ width: '400px', float: 'left', margin: '10px' }}>
                                <Space pixels={50} direction="v"/>
                                <Button
                                    size={"large"}
                                    fullWidth={true}
                                    color={"primary"}
                                    variant="contained"
                                    onClick={() => searchProducts()}
                                >Pesquisar</Button>
                            </Stack>
                        </div>
                    </FormControl>

                    <Space pixels={100} direction="v"/>

                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsProducts
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id_Product}>
                                                {columns.map((column) => {
                                                    if (column.id === 'actions') {
                                                        return <TableCell
                                                            key={row.id_Product + new Date().getTime()}
                                                            align={columns[0].align}>
                                                            <Button
                                                                onClick={() => handleClickEditProduct(row)}><Edit/></Button>

                                                            <Button
                                                                onClick={() => handleClickViewProduct(row)}><Visibility/></Button>

                                                            <Switch checked={row.disabled_Product !== 1}
                                                                    onClick={() => handleClickEnableProduct(row)}/>
                                                        </TableCell>
                                                    }

                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {value}
                                                        </TableCell>
                                                    );
                                                })}


                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsProducts.length > 0 ? rowsProducts[0].totalProducts : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <ProductDetail
                    detail={productModelToDetails(selectedProduct)}
                    onClose={() => setOpen(false)}
                />
            </Dialog>

            <MessageDialog
                emoji={dialogModel.emoji}
                open={dialogModel.open}
                title={dialogModel.title}
                message={dialogModel.message}
                onConfirm={dialogModel.onConfirm}
                confirmButtonText={dialogModel.confirmButtonText}/>
        </AdmLayout>
    )
}