import React, { FC, useCallback, useEffect, useState } from "react";
import { Button } from '@material-ui/core'
import { useNavigate } from "react-router-dom";
import Space from '../../../components/Space';
import PageTitle from '../../../components/PageTitle'
import { useAuth } from '../../../contexts/auth2';
import { handlePromise } from '../../../utils/handlePromisse';
import { getUserProfile } from '../../../services/profileService';
import { getPlanType } from '../../../services/userService';
import { PlayCircleFilled, Restore } from "@material-ui/icons";
import { BaseLayout } from "../../../layouts/base/base.layout";
import styled from "styled-components";
import { SimpleSnackbar } from "../../../components/snackbar";
import { MessageLockedResourceDialog, MessagePayedResourceDialog } from "../../../components/message-dialog";
import { Ranking } from "./ranking";

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`

const ButtonsContainer = styled.div`
  width: 100%;
`

const SmallDiv = styled.div`
    @media screen and (min-width: 768px) {
      margin-bottom: 3px;
  }
`

type ModalState = {
    payedResource: boolean
    lockedResource: boolean
}

export const Dashboard: FC = () => {
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [modalState, setModalState] = useState<ModalState>({ payedResource: false, lockedResource: false })

    const { user } = useAuth()
    const navigate = useNavigate()
    const [planType, setPlanType] = useState<string>('free')
    const [usedQueries, setUsedQueries] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)

    const fetchUser = useCallback(async () => {
        setLoading(true)
        const [result, error] = await handlePromise(getUserProfile(user.idUser))
        setLoading(false)
        if (error) {
            setSnackbarMessage('Erro ao recuperar informações do plano.')
            setShowSnackbar(true)
            return
        }
        setPlanType(getPlanType(user.plan))
        setUsedQueries(result.data.amountQueriesUsed_User ?? 2)
    }, [])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])

    const handleButtons = useCallback((action: 'query'|'history') => {
        if (loading) return

        if (planType === 'free') {
            setModalState({ ...modalState, payedResource: true })
            return
        }

        if (!user.unlocked) {
            setModalState({ ...modalState, lockedResource: true })
            return
        }

        navigate(`/dashboard/${action}`)
    }, [loading, navigate, planType])

    return (
    <SmallDiv>
        <BaseLayout hasLogo={true}>
            <Content>
                <SimpleSnackbar
                    show={showSnackbar}
                    message={snackbarMessage}
                    onDismiss={() => setShowSnackbar(false)}
                />

                <MessagePayedResourceDialog
                    open={modalState.payedResource}
                    onClose={() => setModalState({ ...modalState, payedResource: false })}
                />

                <MessageLockedResourceDialog
                    open={modalState.lockedResource}
                    onClose={() => setModalState({ ...modalState, lockedResource: false })}
                />

                <PageTitle title="Dashboard"/>

                <Ranking/>

                <ButtonsContainer>
                    <Space pixels={50} direction="v" />
                    <Button
                        size={"large"}
                        fullWidth={true}
                        onClick={() => handleButtons('query') }
                        color={"primary"}
                        variant="contained"
                        startIcon={<PlayCircleFilled />}>INICIAR CONSULTA</Button>
                    <Space pixels={20} direction="v" />
                    <Button
                        size={"large"}
                        fullWidth={true}
                        onClick={() => handleButtons('history')}
                        color={"secondary"}
                        variant="contained"
                        startIcon={<Restore />}>HISTÓRICO DE CONSULTAS</Button>
                </ButtonsContainer>
            </Content>
        </BaseLayout>
    </SmallDiv>
    )
}
