import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";

export type LoadingProps = {
    open: boolean
}

export const Loading: React.FC<LoadingProps> = ({ open }) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    )
}
