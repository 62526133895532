import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Favorite } from "@material-ui/icons";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import TableBody from "@mui/material/TableBody";
import { TablePagination } from "@material-ui/core";
import { ActiveModel } from "./active-model";
import { treatString } from "../../../utils";
import NoteIcon from '@mui/icons-material/Note';

interface Column {
    id: 'name'|'mechanismAction'|'actions';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (key: any) => string;
}

const columns: readonly Column[] = [
    { id: 'name', label: 'Nome', minWidth: 150 },
    { id: 'mechanismAction', label: 'Mecanismo de ação', minWidth: 100 },
    { id: 'actions', label: 'Ações', minWidth: 100 }
];

type Props = {
    page: number
    size: number
    total: number
    data: ActiveModel[]
    onFavorite: (patient: ActiveModel) => void
    onCheckActive: (patient: ActiveModel) => void
    annotationOpen: (patient: ActiveModel) => void
    onPageChange: (newPage: number, size: number) => void
    onSizeChange: (newSize: number, page: number) => void
    onOrderChange: (orderBy: keyof ActiveModel, order: Order, page: number, size: number) => void
}

export type Order = 'asc'|'desc';

export const ActiveTable: React.FC<Props> = ({
    data, page, size, total,
    onPageChange, onSizeChange, onOrderChange,
    onFavorite, onCheckActive, annotationOpen
}) => {

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof ActiveModel>('name');

    const handleChangePage = (event: unknown, newPage: number) => {
        onPageChange(newPage + 1, size)
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSizeChange(+event.target.value, 0);
    };

    const handleSort = (property: keyof ActiveModel) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        onOrderChange(property, isAsc ? 'desc' : 'asc', 0, size)
    };

    const actions = (active: ActiveModel) => {
        return (
            <div style={{display: 'flex'}}>
                <VisibilityIcon
                    color={'primary'}
                    onClick={() => onCheckActive(active)}
                    sx={{ cursor: 'pointer', marginRight: '8px' }}
                />

                <Favorite
                    onClick={() => onFavorite(active)}
                    color={active.favorite ? 'primary' : 'disabled'}
                    sx={{ cursor: 'pointer', marginRight: '8px' }}
                />

                <NoteIcon 
                    onClick={() => annotationOpen(active)}
                    color={'primary'}
                    sx={{ cursor: 'pointer', marginRight: '8px' }}
                />

            </div>
        )
    }

    const mechanism = (value: string) => {
        return (<>{treatString(value, 100)}</>)
    }

    const name = (name: string) => {
        return (<>{treatString(name, 25)}</>)
    }

    const createRows = (column: Column, model: ActiveModel) => {
        return (
            <TableCell key={column.id} align={column.align} sx={{width:'1px'}}>
                {
                    column.id === "actions" ? actions(model) :
                        column.id === "mechanismAction" ? mechanism(model.mechanismAction) :
                            name(model.name)
                }
            </TableCell>
        );
    }

    const tableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                            {
                                column.id === "name" ?
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleSort(column.id)}>
                                        {column.label}
                                        {orderBy === column.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    : column.label
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>)
    }

    const tableBody = () => {
        return (
            <TableBody>
                {
                    data.map((active) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={active.id}>
                                {columns.map((column) => createRows(column, active))}
                            </TableRow>
                        );
                    })}
            </TableBody>
        )
    }

    return (
        <Paper>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="active table">
                    {tableHeader()}
                    {tableBody()}
                </Table>
            </TableContainer>

            <TablePagination
                page={page - 1}
                component="div"
                count={total}
                rowsPerPage={size}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onPageChange={handleChangePage}
                labelRowsPerPage={"Linhas por página"}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
        </Paper>
    );
}