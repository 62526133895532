import http from './http-common'

export interface Consultation {
    id: number,
    date: string,
    name: string,
}

export interface Patient {
    id: number,
    name: string,
    consults: number
    actions?: string
    observation?: string
    consultations?: Consultation[]
}

export type PatientFilter = {
    page: number
    size: number
    order?: Order
    search?: string
    orderBy?: string
}

export type Order = 'asc'|'desc';

export const getPatients = () => http.get('/patient/all')

export const getPatientsPaginated2 = (filter: PatientFilter) => {
    let request = `/patient?size=${filter.size}&page=${filter.page}`
    if (filter.search !== undefined) request = request + `&search=${filter.search}`
    if (filter.order !== undefined && filter.orderBy !== undefined) request = request + `&order=${filter.order}&orderBy=${filter.orderBy}`
    return http.get(request)
}

export const getPatientsPaginated = (page: number, size: number, order?: string, orderBy?: string, filter?: string) => {
    let url = `/patient?size=${size}&page=${page}`
    if (filter !== undefined) url = url + `&search=${filter}`
    if (order !== undefined && orderBy !== undefined) url = url + `&order=${order}&orderBy=${orderBy}`
    return http.get(url)
}

export const savePatient = (id: number, patient: Patient) => http.put(`/patient/${id}`, patient)

export const deletePatient = (id: number) => http.delete(`/patient/${id}`)
