import http from "../http-common";

export type DcoEmailsRequest = {
    tag: string
    plan: number
    email: string
}

export type FilterDcoEmails = {
    page: number
    size: number
}

const baseUrl = '/dco-emails'

export const allowEmail = (gift: DcoEmailsRequest[]) => http.post(baseUrl, gift)

export const deleteEmail = (id: number) => http.delete(`${baseUrl}/${id}`)

export const deleteTagByEmail = (tag: string) => http.delete(`${baseUrl}/tag/${tag}`)

export const getDcoEmails = (filter: FilterDcoEmails) => http.get(`${baseUrl}?size=${filter.size}&page=${filter.page}`)

export const getDcoTags = () => http.get(`${baseUrl}/tags`)
