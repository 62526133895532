import { Helmet } from "react-helmet-async"

import React, { FC } from 'react'

type PageTitleProps = {
  title: string
}

const PageTitle: FC<PageTitleProps> = ({title}) => {
  return (
    <Helmet>
      <title>{title} | ProAtivos</title>
    </Helmet>
  )
}

export default PageTitle