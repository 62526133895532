import http from "./http-common"
import {QueryModel} from "../pages/main/query/query.model";
import { ProductModel } from "../models/Product.model";

export type BodyRegionResponse = {
  id_Body: number,
  name_Body: string,
}
export const getBodyRegions = () => http.get('/bodies')

export type UseTypeResponse = {
  id_TypeUse: number,
  name_TypeUse: string,
}
export const getUseTypes = () => http.get('/typeUse')

export type ActiveResponse = {
  id: number,
  name: string,
  enabled?: boolean,
  actions?: any
}
export const getActives = () => http.get('/actives')

export const getActivesPaginated = (page: number, size: number) => http.get(`/actives?size=${size}&page=${page}`)
export const enableActive = (id: number) => http.put(`/actives/${id}/enable`)
export const disableActive = (id: number) => http.put(`/actives/${id}/disable`)
export const newActive = (name: string) => http.post(`/actives`, { name })
export const editActive = (id: number, name: string) => http.put(`/actives/${id}`, { name })

// region Subclass
export const getSubclassPaginated = (page: number, size: number) => http.get(`/subclass?size=${size}&page=${page}`)
export const enableSubclass = (id: number) => http.put(`/subclass/${id}/enable`)
export const disableSubclass = (id: number) => http.put(`/subclass/${id}/disable`)
export const newSubclass = (name: string) => http.post(`/subclass`, { name })
export const editSubclass = (id: number, name: string) => http.put(`/subclass/${id}`, { name })

export type SubClassModel = {
  id: number,
  name: string,
  enabled?: boolean,
  actions?: any
}
// endregion

export interface AddPatientEntries {
  name: string,
  observation?: string
}
export const addPatient = (entries: AddPatientEntries) => http.post('/patient', entries)

export interface PatientResponse2 {
  "id_Patient": number,
  "name_Patient": string,
  "aestheticConsultations": number[],
  "observation_Patient": string,
}
export const getPatientsFromUser = (idUser: number) => http.get(`/patients/${idUser}`)

export type SubclassesEntries = {actives: number[]}
export type SubClassesObject = {
  id: number,
  name: string,
}
export type SubclassesResponse = {
  [key: string]: SubClassesObject[]
}
export const getSubclasses = (entries: SubclassesEntries) => {
  return http.post('/subclass/actives', entries)
}

export const canUserConsult = (idUser: number) => http.get(`/user/plan/available/${idUser}`)

export interface SaveConsultationEntries {
  "id_Body": number,
  "id_TypeUse": number,
  "daytime": boolean,
  "nighttime": boolean,
  "active": number[],
  "subclass": number[],
  "pregnant": boolean,
  "vegan": boolean,
  "organic": boolean,
  "id_User": number,
  "id_Patient": number,
  "product": {
    "id": number,
    "percentage": string
  }[],
  vehicles_id?: number,
  observation?: string,
  observations?: string[]
}

export const queryToConsultation = (
    model: QueryModel, userId: number, observation: string, observations: string[], products: ProductModel[], consultName?: string
) => {
  const both = model.period === 'AMBOS'
  let subclass: any[] = []
  if (model.subclass !== undefined) {
    subclass = model.subclass
  }

  return {
    id_Body: 2,
    id_TypeUse: 1,
    daytime: model.period === 'DIURNO' || both,
    nighttime: model.period === 'NOTURNO' || both,
    active: model.actives.map(act => act.id),
    subclass: subclass.length > 0 ? subclass.map(sub => sub.id) : [],
    pregnant: model.pregnant || false,
    vegan: model.vegan || false,
    organic: model.organic || false,
    id_User: userId,
    id_Patient: model.patientId === 0 ? undefined : model.patientId,
    product: products.map(prod => {
      return {
        id: prod.idProduct,
        percentage: prod.percentage || 0
      }
    }),
    vehicles_id: model.vehicles?.id || null,
    observation: observation,
    observations: observations,
    consultName: consultName
  } as SaveConsultationEntries
}

export const saveConsultation = (entries: SaveConsultationEntries) => http.post('/aestheticConsultation', entries)

export interface PDFData {
  daytime: boolean;
  nighttime: boolean;
  pregnant: boolean;
  vegan: boolean;
  organic: boolean;
  user: number;
  patient?: number;
  products: Product[];
  vehicleName?: string;
  observation?: string;
  observations?: string[];
}

export interface Product {
  name: string;
  percentage: string;
}

export const getPdfByConsultationId = (consults: number[], skipe: boolean) => {
  let url = `/pdf/${consults.join(',')}`
  if (skipe) url += '?skipe=true'

  return http.get(url)
}

export const getPdf = (model: QueryModel, userId: number, observation: string, observations: string[], products: ProductModel[], consultName?: string) => {
    const query = queryToPdf(model, userId, observation, observations, products, consultName)
    return http.post(`/pdf`, query)
}

export const queryToPdf = (model: QueryModel, userId: number, observation: string, observations: string[], products: ProductModel[], consultName?: string) => {
  const both = model.period === 'AMBOS'

  return {
    daytime: model.period === 'DIURNO' || both,
    nighttime: model.period === 'NOTURNO' || both,
    pregnant: model.pregnant || false,
    vegan: model.vegan || false,
    organic: model.organic || false,
    user: userId,
    patient: model.patientId === 0 ? undefined : model.patientId,
    products: products?.map((prod) => {
      return {
          name: prod.productName,
          percentage: prod.percentage || 0
      } as Product
    }),
    vehicleName: model.vehicles?.name,
    observation: observation,
    observations: observations,
    consultName: consultName,
  } as PDFData
}
