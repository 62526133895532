import { Navigate, Outlet, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";

// Dashboard and Error Page
import NotFound from "./pages/Page404";

// Auth
import LoginPage from './pages/authPages/login/login';
import SignupPage from "./pages/authPages/signup/signup";

//===============================================================================
import http from "./services/http-common"
import RecoverPassword from "./pages/authPages/recoverPassword/recoverPassword"
import { Dashboard } from "./pages/main/dashboard/dashboard"
import { ProfilePage } from "./pages/main/profile"
import { ReviewPage } from "./pages/main/review/ReviewPage";
import {useAuth} from "./contexts/auth2";
import {useEffect, useState} from "react";
import {getPlanType, getUserToken} from "./services/userService";
import CreateNewPassword from "./pages/authPages/CreateNewPassword/CreateNewPassword";
import SupportPage from "./pages/main/suport/SupportPage";
import UseTerms from "./pages/terms/UseTerms";
import PrivacyPolicy from "./pages/terms/PrivacyPolicy";
import Query from "./pages/main/query";
import {ManageActives} from "./pages/adm/actives/manage-actives";
import {ManageSubclass} from "./pages/adm/subclass/manage-subclass";
import { ProductPage } from "./pages/adm/Product/ProductPage";
import MercadoPagoPage from "./pages/notification/mercadoPago";
import {ManagePlans} from "./pages/adm/plans/manage-plans";
import { PlansPage } from "./pages/main/plans/plans-page";
import {HistoryPage} from "./pages/main/history/history-page";
import {ActivesPage} from "./pages/main/actives/actives-page";
import { GiftPage } from "./pages/adm/gift";
import { DosePage } from "./pages/main/query/dose/dose-page";
import { UsersPage } from "./pages/adm/users/users-page";
import { DcoPage } from "./pages/adm/dco/dco-page";
import { Tutorial } from "./pages/adm/tutorial/tutorial";
import { TutorialPage } from "./pages/main/tutorial/TutorialPage";

export default function Router() {

  const { hasUser, isAdm, user } = useAuth()
  const [ planType, setPlanType ] = useState<string>('free')
  const token = getUserToken()

  useEffect(() => {
    http.defaults.headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  }, [ token ])

  useEffect(() => {
    setPlanType(getPlanType(user.plan))
  }, [ user ])
 
  return useRoutes([
    {
      path: "/dashboard",
      element: 
        hasUser() ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: "/", element: <Navigate to="/dashboard/app" /> },
        { path: "app", element: <Dashboard /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'pricing', element: <PlansPage /> },
        { path: 'query', element: planType === 'free' ? <Navigate to="/dashboard" /> : <Query /> },
        { path: 'history', element: planType === 'free' ? <Navigate to="/dashboard" /> : <HistoryPage /> },
        { path: 'actives', element: planType === 'free' ? <Navigate to="/dashboard" /> : <ActivesPage /> },
        { path: 'dose', element: planType === 'free' ? <Navigate to="/dashboard" /> : <DosePage /> },
        { path: 'reviewConsult/:id', element: planType === 'free' ? <Navigate to="/dashboard" /> : <ReviewPage /> },
        { path: 'tutorial', element: <TutorialPage/>},
        { path: 'suport', element: <SupportPage /> }
      ],
    },
    {
      path: "/notification",
      children: [
        { path: "mercadopago", element: <MercadoPagoPage /> }
      ]
    },
    {
      path: "/",
      element: !hasUser() ? <Outlet /> : isAdm() ? <Navigate to="/adm" /> : <Navigate to="/dashboard" />,
      children: [
        { path: "/", element: <Navigate to="/login" /> },
        { path: "login", element: <LoginPage /> },
        { path: 'signup', element: <SignupPage/> },
        { path: "recoverPassword", element: <RecoverPassword/> },
        { path: 'newPassword/:token', element: <CreateNewPassword /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/adm',
      element:
        hasUser() ? 
        isAdm() ? <DashboardLayout /> : <Navigate to="/" /> :
        <Navigate to="/" />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'users', element: <UsersPage /> },
        { path: 'actives', element: <ManageActives /> },
        { path: 'subclass', element: <ManageSubclass /> },
        { path: 'products', element: <ProductPage />},
        { path: 'plans', element: <ManagePlans />},
        { path: 'gifts', element: <GiftPage />},
        { path: 'DCO', element: <DcoPage />},
        { path: 'tutorial', element: <Tutorial/>},
      ]
    },
    { path: 'use-terms', element: <UseTerms /> },
    { path: 'privacy-policy', element: <PrivacyPolicy /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

