import React, { Key, useCallback } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export type CheckableValue<T> = {
    isSelected: boolean
    value: T
}

interface Props<T> {
    values: CheckableValue<T>[]
    onValueChange?: (value: T, isSelected: boolean) => void
    getElementKey?: (value: T, index: number) => Key;
    getElementLabel: (value: T, index?: number) => string;
}

export const CheckableList = <T extends unknown>({ values, onValueChange, getElementKey, getElementLabel }: Props<T>) => {
    const handleOnChange = useCallback(checkable => {
        if (onValueChange) {
            onValueChange(checkable.value, !checkable.isSelected)
        }
    }, [onValueChange])

    return (
        <>
            {
                values.map((checkableValue, index) =>
                    <FormControlLabel
                        key={getElementKey ? getElementKey(checkableValue.value, index) : index}
                        control={<Checkbox onChange={() => handleOnChange(checkableValue)} checked={checkableValue.isSelected}/>}
                        label={getElementLabel(checkableValue.value)}
                        style={{ width: "90%", marginTop: "16px" }}
                    />
                )
            }
        </>
    )
}