import styled from 'styled-components'
import { globalStyleFunctions as gsf } from '../../../../styles/global'
import palette from '../../../../theme/palette'

const ProfileFormStyles = styled.div`
  width: 100%;
  height: auto;
  margin-top: 30px;
  .btnAction{
    ${gsf.flexColumn('center', 'center')}
    width: 100%;
    margin: 30px 0;
    min-height: 46px;
    background-color: ${palette.primary.dark};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, .2);
    color: white;
    padding: 6px 12px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 2px;
  }
  .btnActionWrapper{
    ${gsf.flexRow('flex-end', 'center')}
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    
  }
  .changeAddressBtn{
    ${gsf.flexColumn('center', 'center')}
    width: 100%;
    color: ${palette.primary.dark};
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 1.1rem;
    cursor: pointer;
  }
  
  .cursor-pointer {
    input {
      cursor: pointer;
    }
  }



  .editableText{
    ${gsf.flexColumn('flex-start', 'center')}
    width: 100%;
    height: auto;
    label{
      font-size: .8rem;
      color: ${palette.primary.dark};
      letter-spacing: 2px;
    }
    input{
      width: 100%;
      outline: none;
      border: none;
      font-size: 1.1rem;
      color: ${palette.primary.dark};
      padding: 6px 12px;
      border-radius: 6px;
      background-color:  ${palette.primary.lighter};
      border: 1px solid ${palette.primary.light};
      &:disabled{
        background-color: white;
        color: ${palette.primary.dark};
        border-color: white;
      }
    }
  }
`

export default ProfileFormStyles