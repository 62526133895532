import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams, Button, Chip,  } from "@material-ui/core";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import styled from "styled-components";
import Space from "../../../../components/Space";
import PlanModel from "../../../../models/plan.model";
import { handlePromise } from "../../../../utils/handlePromisse";
import { getPlans } from "../../../../services/plan-service";
import { GiftRequest } from "../../../../services/gift.services";

type Props = {
    onGiftAdded: (gifts: GiftRequest[]) => void
}

const MainContent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`

export const GiftForm: React.FC<Props> = ({  onGiftAdded }) => {
    const [email, setEmail] = useState<string>('');
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

    const [plan, setPlan] = useState('');
    const [plans, setPlans] = useState<PlanModel[]>([]);

    const fetchPlans = useCallback(async () => {
        const [response, error] = await handlePromise(getPlans())
        if (!!error) {
            console.log('error', error, error.stack)
            return
        }
        setPlans(response.data as PlanModel[])
    }, [selectedEmails]);

    useEffect(() => {
        fetchPlans()
    }, [fetchPlans]);

    const handleAddEmail = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && email && !selectedEmails.includes(email)) {
            event.preventDefault()

            const newEmail = email.split(/,|\s+|\n/)
            if (newEmail.length > 0) {
                const filtered = newEmail.filter((e) => !selectedEmails.includes(e.trim()));
                setSelectedEmails((emails) => [...emails, ...filtered]);
            } else {
                setSelectedEmails((emails) => [...emails, email.trim()]);
            }

            setEmail('');
        }
    }, [selectedEmails, email, setEmail]);

    const handleRemoveEmail = useCallback((email: string) => {
        setSelectedEmails((emails) => emails.filter((e) => e !== email));
    }, []);

    const handleSend = useCallback(() => {
        const gifts = selectedEmails.map((email) => {
            return {
                email: email,
                plan: parseInt(plan),
            } as GiftRequest
        })

        onGiftAdded(gifts);

        setSelectedEmails([]);
        setPlan('');
    }, [selectedEmails, plan, onGiftAdded]);

    const renderTags = useCallback(() => {
        return selectedEmails.map((option, index) => (
            <Chip
                key={index}
                label={option}
                color={'primary'}
                onDelete={() => handleRemoveEmail(option)}
            />
        ));
    }, [handleRemoveEmail, selectedEmails]);

    return (
        <MainContent>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel id="plan-select-label">Plan</InputLabel>
                <Select
                    labelId="plan-select-label"
                    id="plan-select"
                    value={plan}
                    onChange={(event) => setPlan(event.target.value)}
                    label="Plan">
                    {
                        plans.map((plan) => {
                            return <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>

            <Space pixels={16} direction={'v'}/>

            <Autocomplete
                multiple
                freeSolo
                value={selectedEmails}
                renderTags={renderTags}
                options={[]}
                renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                        {...params}
                        label={'Emails'}
                        variant="outlined"
                        color="primary"
                        onKeyDown={handleAddEmail}
                        onChange={(e) => setEmail(e.target.value)}
                        InputProps={{ ...params.InputProps, }}
                    />
                )}
            />

            <Space pixels={16} direction={'v'}/>

            <Button
                size={"large"}
                fullWidth={true}
                color={"primary"}
                variant="contained"
                onClick={handleSend}
                disabled={selectedEmails.length === 0 || !plan}>
                Enviar
            </Button>
        </MainContent>
    )
}
