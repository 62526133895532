import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { GiftForm } from "./form/gift-form";
import { handlePromise } from "../../../utils/handlePromisse";
import { createGifts, getGiftsPaginated, GiftRequest } from "../../../services/gift.services";
import { SimpleSnackbar } from "../../../components/snackbar";
import { GiftTable } from "./table/gift-table";
import { initialPagination, PaginatedResponse } from "../../../domain/models/paginated-response.model";
import { GiftModel } from "./table/gift.model";
import Space from "../../../components/Space";
import { AdmLayout } from "../../../layouts/base/adm.layout";
import TextField from "@mui/material/TextField";
import { GiftFilter } from '../../../services/gift.services';

export const GiftPage: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState<GiftFilter>({ page: 1, size: 5 })
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [gifts, setGifts] = useState<PaginatedResponse<GiftModel>>(initialPagination)

    const fetchGifts = useCallback(async () => {
        setLoading(true)
        setGifts(initialPagination)
        const [result, error] = await handlePromise(getGiftsPaginated(filter))
        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }
        setGifts(result.data as PaginatedResponse<GiftModel>)
        setLoading(false)
    }, [setGifts, setLoading, filter])

    const onHandleGiftAdd = useCallback(async (gifts: GiftRequest[]) => {
        setLoading(true)
        const [_, error] = await handlePromise(createGifts(gifts))
        setLoading(false)

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setShowSnackbar(true)
        const plural = `${gifts.length > 1 ? 's' : ''}`
        setSnackbarMessage(`Presente${plural} enviado${plural} com sucesso!`)
        fetchGifts()
    }, [loading, setSnackbarMessage, setShowSnackbar])

    const handlePageChange = useCallback(async (newPage: number, size: number) => {
        setFilter({...filter, page: newPage, size})
    }, [fetchGifts])

    const handleSizeChange = useCallback(async (newSize: number, page: number) => {
        setFilter({...filter, size: newSize, page})
    }, [fetchGifts])

    const handleFilter = useCallback(async (query?: string) => {
        setFilter({ ...filter, search: query })
    }, [filter])

    useEffect(() => {
        fetchGifts()
    }, [fetchGifts, filter])

    return (
        <AdmLayout loading={loading} hasLogo={true}>
            <SimpleSnackbar
                show={showSnackbar}
                message={snackbarMessage}
                onDismiss={() => setShowSnackbar(false)}
            />
            
            <GiftForm onGiftAdded={onHandleGiftAdd}/>

            <Space pixels={16} direction={'v'}/>

            <TextField
                label={'Buscar emails...'}
                variant="outlined"
                color="primary"
                onChange={(e) => handleFilter(e.target.value)}
                value={filter.search}
                sx={{ width: '90%' }}
            />

            <Space pixels={16} direction={'v'}/>

            <GiftTable
                data={gifts.data}
                onPageChange={handlePageChange}
                onSizeChange={handleSizeChange}
                total={gifts.pagination.total}
                size={gifts.pagination.perPage}
                page={gifts.pagination.currentPage}
            />
        </AdmLayout>
    )
}
