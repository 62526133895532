import React, { useCallback, useRef, useState } from 'react'
import palette from "../../theme/palette"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {CardContainer, Row, Name, Description, DosageContainer, Dosage, DosageInput, Warning } from "../../pages/main/review/index.styled"
import { MessageDialog } from "../message-dialog";
import Space from "../Space";

type ReviewItemProps = {
    id: number
    name: string
    minDosage: string
    maxDosage: string
    percentage?: string
    description: string
    onItemClick: (id: number) => void
    onRemoveItem?: (id: number) => void
    onItemDoseChange: (id: number, percentage: string) => void
}

export const ProductItem: React.FC<ReviewItemProps> = (
    {
        id, name, description,
        percentage, minDosage, maxDosage,
        onItemClick, onItemDoseChange, onRemoveItem
    }
) => {
    const warningMessage = 'Valor fora do intervalo permitido'
    const inputRef = useRef('')
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [warning, setWarning] = useState<string>(
        percentage ? (Number(percentage) < Number(minDosage) || Number(percentage) > Number(maxDosage) ? warningMessage : '') : ''
    )
    const [inputValue, setInputValue] = useState<String>(percentage ? percentage + '%' : '')

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement|HTMLInputElement>, setter: Function) => {
        const newString = event.target.value
            .replace(/[^\d,.]/g, '')
            .replace(',', '.')
            .replace(/\.(?=.*\.)+/g, '')
            .slice(0, 5)

        validateLimits(newString)

        setter(newString)
    }

    const handleOnBlur = (onBlurEvent: React.FocusEvent<HTMLInputElement>) => {
        if (onBlurEvent.target.value === '') {
            setInputValue('')
            return
        }

        const ceiling = validateLimits(onBlurEvent.target.value)
        setInputValue(String(ceiling) + '%')
    }

    const validateLimits = (value: string) => {
        const num = +value.replace(/%/g, '')
        const ceiling = num > 100 ? 100 : num

        if (ceiling < +minDosage || ceiling > +maxDosage) {
            setWarning(warningMessage)
        } else {
            setWarning('')
        }

        return ceiling
    }

    const handleChange = (value: string) => {
        const num = +value.replace(/%/g, '')
        const ceiling = num > 100 ? 100 : num

        onItemDoseChange(id, ceiling.toString())
    }

    const handleDeleteItem = () => {
        onRemoveItem?.(id)
        setShowDeleteDialog(false)
    }

    return (
        <CardContainer>
            <MessageDialog
                open={showDeleteDialog}
                message={"Tem certeza que deseja remover o Ativo?"}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={handleDeleteItem}
                confirmButtonText={"Deletar"}
                cancelButtonText={"Cancelar"}/>

            <div>
                <Row>
                    <Name>{name}</Name>
                    <Row>
                        {
                            onRemoveItem && <DeleteOutlineOutlinedIcon
                                style={{ color: `${palette.primary.dark}`, fontSize: 26, cursor: "pointer" }}
                                onClick={() => setShowDeleteDialog(true)}
                            />
                        }
                        <InfoOutlinedIcon
                            style={{ color: `${palette.primary.dark}`, fontSize: 26, cursor: "pointer" }}
                            onClick={() => onItemClick(id)}
                        />
                    </Row>
                </Row>
                <Description>{description}</Description>
            </div>
            <DosageContainer>
                <Dosage>Min <br/>{minDosage}%</Dosage>
                <Space pixels={16} direction={'h'}/>
                <DosageInput
                    color={"primary"}
                    value={inputValue}
                    onChange={(e) => {
                        handleInputChange(e, setInputValue)
                        handleChange(e.target.value)
                    }}
                    onBlur={handleOnBlur}
                    InputProps={{
                        onFocus: e => setInputValue(e.target.value.replace(/%/g, '')),
                        ref: inputRef
                    }}
                />
                <Space pixels={16} direction={'h'}/>
                <Dosage>Max <br/>{maxDosage}%</Dosage>
            </DosageContainer>
            <Warning style={{ visibility: warning.length > 0 ? 'visible' : 'hidden' }}>{warning}</Warning>
        </CardContainer>
    )
}
