import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import LayersIcon from '@mui/icons-material/Layers';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { getQuery } from '../../../../services/local-session-storage';
import { Divider } from "@material-ui/core";
import { useCallback } from 'react';
import { Title } from './product-detail.styled';
import { useState } from 'react';

type Anchor =  'right';

export default function TemporaryDrawer() {
  const [state, setState] = useState({
    right: false,
  });
  const {products} = getQuery()

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const list = useCallback((anchor: Anchor) => (
    <Box
      sx={{ width: '260px',  }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List >
        <LayersIcon style={{color: 'white',margin: '5px'}}/>
        <Title style={{textAlign: 'center', color: 'white',}}>Ativos selecionados:</Title>
        
        {products &&
        products.map((product) => (
          <ListItem key={product.productName} disablePadding>
              <ListItemText 
                style={{textTransform: 'capitalize', margin: '6px', paddingLeft: '3px', display: 'flex', alignItems: 'flex-start', color: 'white',  }}>
                  - {product.productName.toLocaleLowerCase()} </ListItemText>
              </ListItem>
        ))}
        <Divider style={{backgroundColor:'white'}}/>
        
      </List>
    </Box>
  ),[products]);

  return (
    <div>
      {(['right'] as const).map((anchor) => (
        <React.Fragment  key={anchor}>

          { products && products.length > 0 && 
            <LayersIcon sx={{position: 'absolute', right: '1px', bottom: '6px', cursor: 'pointer'}}
             onClick={toggleDrawer(anchor, true)}>
              {anchor}
            </LayersIcon>}
          
            <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            PaperProps={{sx: {backgroundColor: "#C0985D"}}}
          >
            {list(anchor)}
          </Drawer>
          
        </React.Fragment>
      ))}
    </div>
  );
}