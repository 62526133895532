import { Box, Checkbox, FormControl } from '@material-ui/core'
import { SxProps } from '@material-ui/system'
import { FC } from 'react'
import palette from '../../../../theme/palette'
import { flexColumn, flexRow } from '../../../../utils/MUIShortcuts'
import { treatString } from '../../../../utils'
import { withTheme } from '../../../../theme'
import { ProductModel } from "../../../../models/Product.model";
import NoteIcon from '@mui/icons-material/Note';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Favorite } from "@material-ui/icons";

interface ActiveItemComponentProps {
    checked?: boolean,
    props: ProductModel,
    onClick: (item: ProductModel) => void
    onSelect: (item: ProductModel, checked: boolean) => void,
    onFavorite: (patient: ProductModel) => void,
    annotationOpen?: (patient: ProductModel) => void,
    onPopUp: () => void
}

const containerStyles = withTheme((theme) => ({
    ...flexRow('space-between', 'center'),
    width: '100%',
    minHeight: '56px',
    background: palette.primary.lighter,
    borderRadius: '2px',
    marginTop: '10px',
    color: palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
        minHeight: '76px'
    }
}))

export const btnInfo: SxProps = {
    ...flexColumn('center', 'center'),
    width: '32px',
    height: '32px',
    borderRadius: '2px',
    marginRight: '12px',
}

const ActiveItem: FC<ActiveItemComponentProps> = ({props, onSelect, checked, onClick, onPopUp, onFavorite, annotationOpen }) => {
    const { productName, classes } = props

    return (
        <Box sx={containerStyles}>
            <FormControl>
                <Checkbox onChange={(_, check) => onSelect(props, check)} checked={checked} size="medium" color="secondary" />
            </FormControl>
            <Box style={{ width: '70%' }} onClick={() => onClick(props)}>
                <h2 style={{fontSize: '1.2rem', cursor: 'pointer'}}>
                    {treatString(productName.toLowerCase(), 20, {capitalize: true})}
                </h2>
                <p style={{fontSize: '.9rem', cursor: 'pointer'}}>
                    { classes && treatString(classes, 30)}
                </p>
            </Box>
            <Box sx={btnInfo} style={{ cursor: 'pointer' }} onClick={() => onClick(props)}>
                <VisibilityIcon style={{color: palette.primary.main, width: '24px', height: '24px'}} />
            </Box>

            <Favorite
                    onClick={() => onFavorite(props)}
                    color={props.favorite ? 'primary' : 'disabled'}
                    sx={{ cursor: 'pointer', marginRight: '8px' }}
                />

            { annotationOpen?
             <Box sx={btnInfo} style={{ cursor: 'pointer' }} onClick={() => annotationOpen(props)}>
                <NoteIcon style={{color: palette.primary.main, width: '24px', height: '24px'}}/>
             </Box>
             :
             <Box sx={btnInfo} style={{ cursor: 'pointer' }} onClick={() => onPopUp()}>
                <NoteIcon style={{color: 'grey', width: '24px', height: '24px'}}/>
             </Box>
            } 

        </Box>
    )
}

export default ActiveItem