import { Form,  useFormik, Field, FormikProvider } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import ProfileFormStyles from './profileFormStyles'
import * as Yup from 'yup'
import {TextField, MenuItem, Modal, Container, Button, Dialog} from '@material-ui/core'
import palette from '../../../../theme/palette'
import { handlePromise } from '../../../../utils/handlePromisse'
import { getUserProfile, IResponseProfile } from '../../../../services/profileService'
import ModalAddressContent, { ModalAddressContentvalues } from '../ModalAddress'
import { applyMask } from '../../../../utils'
import { useAuth } from '../../../../contexts/auth2'

const occupationAreaOptions = [
  'Esteticista',
  'Cosmetologista',
  'Farmaceutica(o)',
  'Biomédica(o)/Bioquímica(o)',
  'Médica(o)',
  'Fisioterapeuta(o)',
  'Outro',
]

export interface IProfileValues {
  fullName: string
  email: string
  phone: string
  occupationArea: string
  cep: string 
  city: string
  state: string
  address: string
  district: string
  number: string
}

let initialValues: IProfileValues = {
  fullName: '',
  email: '',
  occupationArea: '',
  phone: '',
  cep: '',
  city: '',
  state: '',
  address: '',
  district: '',
  number: ''
}

export interface ProfileFormProps {
  handleSubmitProp: (idUser: number, entries: Partial<IProfileValues>, setReload: Function) => void,
}

const updateFormikValues = (formik: any, values: any) => {
  Object.entries(values).map(([key, val]) => formik.values[key] = val)
}

const updateObjectWithAPIResponse = (state: any, values: any) => {
  Object.entries(values).map(([key, val]) => state[key] = val)
}

const ProfileForm: FC<ProfileFormProps> = ({handleSubmitProp}) => {

  const { user: { idUser } } = useAuth()
  const [showError, setError] = useState(false)
  const [showSuccess, setSuccess] = useState(false)
  const [disableBtnSave, setDisableBtnSave] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [formState, setFormState] = useState<IProfileValues>({...initialValues})
  const [reload, setReload] = useState(false)

  const ProfileSchema = Yup.object().shape({
    fullName: Yup.string().required('Este campo é obrigatório'),
    email: Yup.string().required('Este campo é obrigatório'),
    occupationArea: Yup.string().required('Este campo é obrigatório'),
    phone: Yup.string()
      .transform((val: string | null) => !!val ? val.replace(/\D*/gi, '') : '')
      .required('Este campo é obrigatório')
      .min(10, 'Número de telefone inválido'),
    cep: Yup.string().required('Este campo é obrigatório'),
    city: Yup.string().required('Este campo é obrigatório'),
    state: Yup.string().required('Este campo é obrigatório'),
    address: Yup.string().required('Este campo é obrigatório'),
    district: Yup.string().required('Este campo é obrigatório'),
    number: Yup.string().required('Este campo é obrigatório')
  })

  const formik = useFormik({
    initialValues: formState,
    validationSchema: ProfileSchema,
    onSubmit: (values, actions) => {}
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  useEffect(() => {
    (async () => {
      const [result, error] = await handlePromise(getUserProfile(idUser))
      if(!!error){
        console.log('err:', error)
        return
      }
      const { data } = result as {data: IResponseProfile}

      const fullName = !!data.name_User ? data.name_User : ''
      const phone = !!data.phone_User ? applyMask('phone').onValue(data.phone_User) : ''
      const occupationArea = !!data.occupation_User ? data.occupation_User : ''
      const email = !!data.email_User ? data.email_User : ''

      const address = !!data.address.street ? data.address.street : ''
      const number = !!data.address.number ? data.address.number : ''
      const city = !!data.address.city ? data.address.city : ''
      const state = !!data.address.state ? data.address.state : ''
      const cep = !!data.address.zipcode ? data.address.zipcode : ''
      const district = !!data.address.neighbourhood ? data.address.neighbourhood : ''

      updateObjectWithAPIResponse(formState, {fullName, email, phone, occupationArea, number, city, district, state, address, cep})
      updateFormikValues(formik, {fullName, email, phone, occupationArea, number, city, district, state, address, cep})
      setFormState(formState)
      setDisableBtnSave(!isValidForm(formState))
      document.getElementById('occupationArea')?.focus()
      document.getElementById('phone')?.focus()
    })();
  }, [isSubmitting, reload])

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000)
    if (showSuccess) setTimeout(() => setSuccess(false), 2000)
  }, [showError, showSuccess])

  useEffect(() => {
    setDisableBtnSave(!isValidForm(formState))
  }, [formState, initialValues, formik.values, isSubmitting, reload])

  const isValidForm = (formState: IProfileValues) => {
    return ProfileSchema.isValidSync(formState);
  }

  const handleInputChange = (e: any) => {
    if(['phone', 'occupationArea', 'fullName'].includes(e.target.id)){
      e.target.value = applyMask(e.target.id).onValue(e.target.value)
      const updateObj: any = {...formState}
      updateObj[e.target.id] = e.target.value
      setFormState(updateObj)
      updateFormikValues(formik, updateObj)
    }
  }

  const handleOccupationAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updateObj: any = {...formState, occupationArea: e.target.value || ''}
    setFormState(updateObj)
    updateFormikValues(formik, updateObj)
  }

  const renderFullAddress = () => {
    const { address, number, district, city, state } = formState
    if(!!address && !!number && !!district && !!city && !!state) return `${address}, ${number} - ${district} | ${city} / ${state}`
    return 'ENDEREÇO INCOMPLETO'
  }

  const handleModalClose = (modalState: ModalAddressContentvalues | null, isValidCEP: boolean) => {
    setOpenModal(false)
    if(!!modalState && isValidCEP){
      const { address, cep, city, district, state, number } = modalState
      updateObjectWithAPIResponse(formState, {address, cep, city, district, state, number})
      setFormState(formState)
      updateFormikValues(formik, formState)
      setDisableBtnSave(!isValidForm(formState))
    }
  }

  return (
    <ProfileFormStyles >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
          <TextField 
            id="fullName"
            fullWidth
            autoComplete="fullName"
            type="fullName"
            label="NOME"
            {...getFieldProps('fullName')}
            onChange={handleInputChange}
            value={formState.fullName}
            variant="outlined"
            color='primary'
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'fullName'}}
            error={Boolean(touched.fullName && errors.fullName)}
            helperText={touched.fullName && errors.fullName}
          />
          <TextField 
            fullWidth
            autoComplete="email"
            type="email"
            label="EMAIL"
            {...getFieldProps('email')}
            onChange={() => {}}
            value={formState.email}
            variant="outlined"
            color='primary'
            disabled
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'email'}}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField 
            fullWidth
            id="phone"
            autoComplete="phone"
            type="phone"
            label="TELEFONE"
            {...getFieldProps('phone')}
            onChange={handleInputChange}
            value={formState.phone}
            variant="outlined"
            color='primary'
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'phone'}}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />
          <TextField 
            fullWidth
            id="occupationArea"
            autoComplete="occupationArea"
            type="occupationArea"
            label="ÁREA DE OCUPAÇÃO"
            {...getFieldProps('occupationArea')}
            onChange={handleOccupationAreaChange}
            variant="outlined"
            color='primary'
            select={true}
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'occupationArea', defaultValue: ''}}
            error={Boolean(touched.occupationArea && errors.occupationArea)}
            helperText={touched.occupationArea && errors.occupationArea}
          >
            {occupationAreaOptions.map((e, i) => (<MenuItem key={i} value={e}>{e}</MenuItem>))}
          </TextField>
          <TextField 
            fullWidth
            autoComplete="fullAddress"
            type="fullAddress"
            label="ENDEREÇO"
            {...getFieldProps('fullAddress')}
            onChange={() => {}}
            onClick={() => setOpenModal(true)}
            value={renderFullAddress()}
            disabled
            variant="outlined"
            color='primary'
            className="cursor-pointer"
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'fullAddress'}}
          />
          <div className="changeAddressBtn" onClick={() => setOpenModal(true)}>
            alterar endereço
          </div>
        </Form>
      </FormikProvider>
      <Dialog
        open={openModal}
        onClose={(e) => {}}
      >
          <ModalAddressContent 
            address={{
              address: formState.address,
              cep: formState.cep,
              city: formState.city,
              district: formState.district,
              number: formState.number,
              state: formState.state,
              complement: ''
            }} 
            onClose={ handleModalClose }
          />
      </Dialog>
      <Button
          size={"large"}
          fullWidth={true}
          onClick={() => {
            formState.phone = formState.phone.replace(/\D*/gi, '')
            formState.cep = formState.cep.replace(/\D*/gi, '')
            handleSubmitProp(idUser, formState, setReload)
          }}
          style={{marginTop: "10px", marginBottom: "8px"}}
          disabled={disableBtnSave}
          variant="contained">salvar dados</Button>

    </ProfileFormStyles>
  )
}

export default ProfileForm