import { IconButton, Menu, MenuItem } from "@material-ui/core"
import { MoreVert } from "@material-ui/icons"
import React, { useState } from "react"

type MoreOptionsProps = {
    onEdit: () => void
    onDelete: () => void
    canGeneratePdf: boolean
    onPdfGenerate: () => void
}

export const MoreOptions: React.FC<MoreOptionsProps> = ({ onEdit, onDelete, onPdfGenerate, canGeneratePdf, }) => {
    const [anchorEl, setAnchorEl] = useState<null|HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVert/>
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => {
                    onEdit()
                    handleClose()
                }}>
                    Editar
                </MenuItem>
                <MenuItem onClick={() => {
                    onDelete()
                    handleClose()
                }}>
                    Excluir
                </MenuItem>
                <MenuItem disabled={!canGeneratePdf} onClick={() => {
                    onPdfGenerate()
                    handleClose()
                }}>
                    Gerar PDF
                </MenuItem>
            </Menu>
        </>
    )
}
