import { Theme, ThemeOptions } from "@material-ui/core";

export default function Modal(theme: Theme): ThemeOptions["components"] {
  return {
    MuiModal: {
      styleOverrides: {
        root: {
          border: 'none !important',
          outline: 'none !important'
        }
      }
    }
  };
}