import styled from "styled-components";

export const RibbonBox = styled.div`
  position: relative;
  background: transparent;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  margin-top: 20px;
  width: 98%;
  margin-bottom: 12px;
  min-width:300px;
  
  @media screen and (min-width: 768px) {
    margin-left: 20px;
  }

  @media screen and (max-height: 768px) {
    width: 47%;
    margin-left: 20px;
  }
`

export const Ribbon = styled.div`
  width: 130px;
  height: 130px;
  overflow: hidden;
  position: absolute;

  ::before,
  ::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
  }

  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 12px 0;
    background-color: #3d507a;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 14px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
  }
`

export const RibbonRight = styled(Ribbon)`
  top: -10px;
  right: -10px;

  ::before,
  ::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }

  ::before {
    top: 0;
    left: 0;
  }

  ::after {
    bottom: 0;
    right: 0;
  }

  span {
    left: -37px;
    top: 30px;
    transform: rotate(45deg);
  }
`
