import React, { FC, useCallback, useState } from 'react'
import { Stack, TextField, Box, InputAdornment, IconButton, CircularProgress  } from '@material-ui/core'
import { stackFlexColumn } from '../../../utils/MUIShortcuts'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { btnFill, btnModal } from '../../main/profile/ModalAddress/styles'
import { arrayWithoutOneElement, exists, objectMap } from '../../../utils'
import Icon from '@iconify/react'
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";

export type NewPasswordState = {
  password: string,
  confirmPassword: string
}

type NewPasswordProps = {
  onSubmit: (values: NewPasswordState) => void,
  loading: boolean
}

const NewPasswordForm: FC<NewPasswordProps> = ({ onSubmit, loading }) => {

  const [ showPassword, setShowPassword ] = useState<number[]>([])

  const schema = yup.object().shape({
    password: yup.string()
      .required('Senha obrigatória')
      .min(6, 'A senha deve ter no mínimo 6 dígitos')
      .matches(/([a-z])+/g, 'Digite ao menos uma letra minúscula')
      .matches(/([A-Z])+/g, 'Digite ao menos uma letra maiúscula')
      .matches(/([0-9])+/g, 'Digite ao menos um dígito numérico')
      .matches(/([!@#$%¨^&*()\-[\]{}+=/;:'"._])+/g, 'Digite ao menos um símbolo ex. @#!'),
    confirmPassword: yup.string()
      .required('Senha obrigatória')
      .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais')
  })

  const { getFieldProps, errors, touched, values, validateForm, setTouched } = useFormik<NewPasswordState>({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: schema,
    onSubmit: () => {},
    validateOnChange: true,
    validateOnBlur: true,
  })

  const onSubmitCallback = useCallback(async () => {
    const errs = await validateForm()
    setTouched(objectMap(values, () => true))
    if(!exists(errs)){
      onSubmit(values)
    }
  }, [ values, setTouched, validateForm, onSubmit ])

  return (
    <Stack { ...stackFlexColumn('center', 'space-evenly') } spacing={ 2 } sx={{ width: '100%', minHeight: '240px', maxWidth: '350px' }}>
      <TextField
        fullWidth 
        type={ showPassword.includes(0) ? 'text' : 'password' }
        label="NOVA SENHA"
        { ...getFieldProps('password') }
        error={ !!errors.password && touched.password }
        helperText={  touched.password ? (errors.password ?? ' ') : ' ' }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => showPassword.includes(0) ? setShowPassword(curr => ([...arrayWithoutOneElement(curr, curr.indexOf(0))])) : setShowPassword(curr => ([...curr, 0]))} edge="end">
                <Icon icon={showPassword.includes(0) ? eyeFill : eyeOffFill} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth 
        type={ showPassword.includes(1) ? 'text' : 'password' }
        label="CONFIRMAR SENHA"
        { ...getFieldProps('confirmPassword') }
        error={ !!errors.confirmPassword && touched.confirmPassword }
        helperText={  touched.confirmPassword ? (errors.confirmPassword ?? ' ') : ' ' }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => showPassword.includes(1) ? setShowPassword(curr => ([...arrayWithoutOneElement(curr, curr.indexOf(1))])) : setShowPassword(curr => ([...curr, 1]))} edge="end">
                <Icon icon={showPassword.includes(1) ? eyeFill : eyeOffFill} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ ...btnModal, ...btnFill }} onClick={() => onSubmitCallback()}>
        {
          loading ? <CircularProgress size={16} sx={{ color: 'white !important' }}/> : 'ALTERAR'
        }
      </Box>
    </Stack>
  )
}

export default NewPasswordForm