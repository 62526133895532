// scroll bar
import "simplebar/src/simplebar.css";

// react
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// basics
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Modalprovider } from "./contexts/modal";
import { AuthProvider } from "./contexts/auth2";

ReactDOM.render(
  <HelmetProvider>
    <AuthProvider>
      <BrowserRouter>
        <Modalprovider>
          <App />
        </Modalprovider>
      </BrowserRouter>
    </AuthProvider>
  </HelmetProvider>,
  document.getElementById("root")
);

reportWebVitals();
