import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import {
    ConsultationsFromPatientByIdUserResponse,
    deleteConsult,
    getAllConsultationsFromPatientByIdUser
} from "../../../../services/history";
import palette from "../../../../theme/palette";
import styled from "styled-components";
import { Delete } from "@material-ui/icons";
import { handlePromise } from "../../../../utils/handlePromisse";
import { useNavigate } from "react-router";
import { useAuth } from "../../../../contexts/auth2";
import { ConfirmDeleteDialog } from "./confirm-delete.dialog";

type Props = {
    open: boolean
    onClose: (reload: boolean) => void
    patientId: number
}

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 8px;
  max-width: 450px;
  min-height: 46px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
  margin-bottom: 16px;
`

const Text = styled.p`
  width: 100%;
  padding: 8px 0;
  color: ${palette.primary.dark};
  font-weight: 500;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    min-width: 300px;
  }
`

const ConsultButton = styled(Button)`
  width: 100%;
  padding: 8px;
  margin: 8px;
  align-content: center;
`

export const ViewConsultsDialog: React.FC<Props> = ({ open, patientId, onClose }) => {
    const navigate = useNavigate()

    const { user: { idUser } } = useAuth()
    const [viewDeleteConfirmation, setViewDeleteConfirmation] = useState(false)
    const [consultId, setConsultId] = useState<number>(0)
    const [consults, setConsults] = useState<ConsultationsFromPatientByIdUserResponse>([])

    const fetchConsultsByPatient = useCallback(async () => {
        const [result, error] = await handlePromise(getAllConsultationsFromPatientByIdUser(idUser, patientId))

        if (!!error) {
            console.log('error', error, error.stack)
            return
        }

        setConsults(result.data as ConsultationsFromPatientByIdUserResponse)
    }, [setConsults])

    useEffect(() => {
        fetchConsultsByPatient()
    }, [fetchConsultsByPatient])

    const onDeleteHandle = useCallback(async (consult: number) => {
        setViewDeleteConfirmation(false)

        const [result, error] = await handlePromise(deleteConsult(consult))
        if (!!error) {
            console.log(error)
            return
        }

        const newList = consults.filter(e => e.id_AestheticConsultation !== consult);
        setConsults(newList)

        if (newList.length === 0) onClose(true)
    }, [consults, setConsults, onClose])

    const onNavigateReview = (id: number) => {
        navigate(`/dashboard/reviewConsult/${id}`)
    }

    return (
        <Dialog open={open} onClose={() => onClose} sx={{ maxHeight: '80vh' }}>
            <ConfirmDeleteDialog
                open={viewDeleteConfirmation}
                resourceId={consultId}
                onConfirm={onDeleteHandle}
                onClose={() => setViewDeleteConfirmation(false)}
                title={'TEM CERTEZA QUE DESEJA REMOVER ESSA CONSULTA?'}
                message={'Essa ação é IRREVERSSÍVEL! E estes dados não poderão ser recuperados.'}
            />

            <DialogTitle>Consultas Realizadas</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {
                        !!consults && consults.length > 0 && consults.map(
                            (consult, i) =>
                                <Item key={i}>
                                    <Text onClick={() => onNavigateReview(consult.id_AestheticConsultation)}>
                                        {consult.date}
                                    </Text>
                                    <Button onClick={() => {
                                        setConsultId(consult.id_AestheticConsultation)
                                        setViewDeleteConfirmation(true)
                                    }}>
                                        <Delete color={"primary"}/>
                                    </Button>
                                </Item>)
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ConsultButton
                    fullWidth={true}
                    variant={"outlined"}
                    onClick={() => onClose(false)}>FECHAR
                </ConsultButton>
            </DialogActions>
        </Dialog>
    )
}