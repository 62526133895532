import React from "react";
import { Content, Header, MainContent } from "./base.layout.styled"
import { LogoLayout } from "./logo.layout";

type Props = {
    hasLogo?: boolean
}

export const BaseLayout: React.FC<Props> = ({ hasLogo, children }) => {
    return (
        <MainContent>
            <Header>
                {hasLogo && <LogoLayout />}
            </Header>

            <Content>
                {children}
            </Content>
        </MainContent>
    );
}

