import {Button, Container, Dialog, Stack, TextField} from '@material-ui/core'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {btnPrimary, btnSecondary, containerStyles} from '../../pages/main/profile/ModalAddress/styles'
import {useModal} from '../../contexts/modal'
import MCConfirm, {h2Style} from './MCConfirm'
import palette from '../../theme/palette'
import {stackFlexColumn, stackFlexRow} from '../../utils/MUIShortcuts'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {applyMask, exists, objectMap, removeEmptyAttributes} from '../../utils'
import MCAlert from './MCAlert'
import {useAuth} from '../../contexts/auth2'
import {handlePromise} from '../../utils/handlePromisse'
import {getUserProfile} from '../../services/profileService'
import {useNavigate} from 'react-router'
import {isValidCPF} from "../../utils/Validator";

export type OrderInfoState = {
    titularName: string,
    birthdate: string,
    cpf: string,
    expirationDate: string,
    cvv: string,
    cardNumber: string,
    brand: string
}

const helperTextStyle: any = {
    style: {
        background: 'white',
        margin: '0px',
        padding: '4px 16px 0px 16px'
    }
}

type MCOrderInfoProps = {
    onClose?: () => void
    onAssign?: (values: OrderInfoState) => void,
    preValues?: OrderInfoState
}

const MCOrderInfo: FC<MCOrderInfoProps> = ({onClose, onAssign, preValues}) => {

    const {setModalOpen, setModalContent} = useModal()
    const navigate = useNavigate()
    const {user} = useAuth()
    const [isThereAddress, setIsThereAddress] = useState(false)

    useEffect(() => {
        (async () => {
            const [result, error] = await handlePromise(getUserProfile(user.idUser))
            if (error) {
                setModalContent(<MCAlert title="Erro ao buscar informações do usuário"/>)
            }
            if (!!result && !!result.data) {
                const addressLength = Object.entries(removeEmptyAttributes(result.data.address)).length
                const isThereAddress = exists(result.data.address) || addressLength >= 6

                setIsThereAddress(isThereAddress);
                if (!isThereAddress) {
                    setModalContent(<MCConfirm
                        title="Perfil incompleto!"
                        text="Para aderir um plano é necessário completar suas informações."
                        confirmButtonText="completar"
                        cancelButtonText="agora não"
                        onConfirm={() => {
                            navigate('/dashboard/profile')
                            setModalOpen(false)
                        }}
                    />)
                    setModalOpen(true)
                }
            }
        })()
    }, [user.idUser, setModalContent, setModalOpen, navigate])

    const schema = Yup.object().shape({
        cpf: Yup.string()
            .required('Este campo é obrigatório')
            .transform((val) => val.replace(/\D/g, ''))
            .min(11, 'Digite ao menos 11 dígitos'),
    })

    const {errors, touched, getFieldProps, values, setValues, validateForm, setTouched} = useFormik({
        initialValues:
            preValues ?
                preValues :
                {
                    titularName: '',
                    birthdate: '',
                    cpf: '',
                    expirationDate: '',
                    cvv: '',
                    brand: '',
                    cardNumber: ''
                },
        validationSchema: schema,
        onSubmit: (values) => {
        },
        validateOnChange: true,
        validateOnBlur: true
    })

    const onCloseCallback = useCallback(() => {
        if (!!onClose) {
            onClose()
            setModalOpen(false)
        } else {
            setModalOpen(false)
        }
    }, [onClose, setModalOpen])

    const alertAndRerender = useCallback((title: string, text: string) => {
        setModalContent(
            <MCAlert
                title={title}
                text={text}
                onClose={
                    () => setModalContent(
                        <MCOrderInfo
                            onClose={onClose}
                            onAssign={onAssign}
                            preValues={values}
                        />
                    )
                }
            />
        )
    }, [onClose, onAssign, setModalContent, values])


    const onAssignCallback = useCallback(() => {
        setTouched(objectMap(values, () => true) as any)
        validateForm()
            .then(r => {
                const hasErrors = Object.entries(removeEmptyAttributes(r)).length > 0
                if (hasErrors) {
                    console.log('has errors')
                    return
                } else {
                    if (!isValidCPF(values.cpf.replace(/\D/g, ''))) {
                        alertAndRerender('Campo inválido', 'CPF inválido')
                        return
                    }
                    if (!!onAssign) {
                        setModalContent(<MCAlert title="Validando informações"
                                                 text="Essa operação pode demorar alguns minutos"
                                                 buttonText="validando..." onClose={() => {
                        }}/>)
                        onAssign(values as OrderInfoState)
                    } else {
                        setModalOpen(false)
                    }
                }
            })

    }, [onAssign, setModalOpen, setModalContent, values, validateForm, setTouched, alertAndRerender])

    if (isThereAddress) {
        return <Dialog open={true}>
            <Container sx={{
                ...containerStyles,
                maxWidth: '350px !important',
                width: '100%',
                minWidth: '300px',
                height: '21hv'
            }}>
                <h2 style={h2Style}> DOCUMENTO </h2>
                <Stack {...stackFlexColumn('center', 'flex-start')} spacing={.1} sx={{maxWidth: '80%', margin: 'auto'}}>
                    <TextField
                        fullWidth
                        type="text"
                        label="CPF"
                        {...getFieldProps('cpf')}
                        onChange={e => setValues(current => ({
                            ...current,
                            cpf: applyMask('cpf').onValue(e.target.value)
                        }))}
                        value={applyMask('cpf').onValue(values.cpf)}
                        error={!!errors.cpf && touched.cpf}
                        helperText={touched.cpf ? (errors.cpf ?? ' ') : ' '}
                        FormHelperTextProps={helperTextStyle}
                        sx={{
                            background: palette.primary.lighter
                        }}
                    />
                </Stack>
                <Stack
                    {...(window.innerWidth < 350 ? stackFlexColumn('center', 'space-evenly') : stackFlexRow('center', 'center'))}
                    style={{width: '100%', marginBottom: '16px', marginTop: '8px'}} spacing={2}
                >
                    <Button
                        size={"large"}
                        onClick={onAssignCallback}
                        sx={btnPrimary}
                        variant="contained">CONTINUAR</Button>
                    <Button
                        size={"large"}
                        onClick={onCloseCallback}
                        sx={btnSecondary}
                        variant="contained">VOLTAR</Button>
                </Stack>
            </Container>
        </Dialog>
    }

    return <Container></Container>;
}

export default MCOrderInfo