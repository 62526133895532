import React, { useCallback } from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Page from '../../../components/Page';
import { MHidden } from '../../../components/@material-extend';
import CSS from '../../../utils/CSSShortCuts';
import { Typography } from '@material-ui/core';
import palette from '../../../theme/palette';
import RecoverPasswordForm, { IRecoverPasswordValues } from './recoverPasswordForm';
import { FormikHelpers } from 'formik';
import NewLogo from '../../../components/NewLogo';
import { handlePromise } from '../../../utils/handlePromisse';
import { recoverPassword } from '../../../services/authService';
import { useModal } from '../../../contexts/modal';
import MCAlert from '../../../components/ModalContents/MCAlert';
import { useNavigate } from 'react-router';

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentlessSectionStyle = styled('div')(({theme}) => {
  return {
    ...CSS.flexColumn('center', 'center'),
    width: '100%',
    height: 'auto',
    minHeight: '100vh',
  }
})

const AsideWrapper = styled('div')(({theme}) => {
  return {
    ...CSS.flexColumn('center', 'center'),
    width: '100%',
    maxWidth: '40vw',
    height: '100vh',
    paddingTop: '38px',
    background: palette.primary.main,
    [theme.breakpoints.down('md')]: {
      ...CSS.flexColumn('center', 'flex-start'),
      maxWidth: '100vw',
      background: '#fff'
    }
  }
})

const TitleStyles = styled('div')(({theme}) => {
  return {
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.dark,
      width: '100%',
      maxWidth: '400px'
    }
  }
})

const SubtitleStyles = styled('div')(({theme}) => {
  return {
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.dark,
      width: '100%',
      maxWidth: '400px'
    }
  }
})

const AsideContentStyle = styled('div')(({theme}) => {
  return {
    ...CSS.flexColumn('center', 'space-evenly'),
    width: '90%',
    height: 'auto',
    maxHeight: '80vh',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.dark
    }
  }
})

const MDUpHidden = styled('div')(({theme}) => {
  return {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
})

const RecoverPassword: React.FC = () => {

  const { setModalContent, setModalOpen } = useModal()
  const navigate = useNavigate()
  const onSubmit = useCallback(async (values: IRecoverPasswordValues, actions: FormikHelpers<IRecoverPasswordValues>) => {
    if (!values.email) {
      setModalContent(
        <MCAlert
          title="Impossível continuar!" text="Por favor informe um email." 
          onClose={() => setModalOpen(false)}
        />
      )
      setModalOpen(true)
      return
    }
    const [ result, error ] = await handlePromise(recoverPassword(values.email))
    if(error){
      console.log(error)
      setModalContent(
        <MCAlert
          title="Erro ao recuperar senha!" text="Um erro ocorreu, tente novamente mais tarde." 
          onClose={() => setModalOpen(false)}
        />
      )
      setModalOpen(true)
      return
    }
    if (result.status === 500) {
      setModalContent(
        <MCAlert
          title="Erro ao recuperar senha!" text="Um erro interno ocorreu, tente novamente mais tarde." 
          onClose={() => setModalOpen(false)}
        />
      )
      setModalOpen(true)
      return 
    }
    setModalContent(
      <MCAlert 
        title="Enviamos um email pra você!" 
        text={`Verifique o email que foi enviado para "${values.email}" e clique no link de recuperação de senha.`} 
        onClose={() => {
          setModalOpen(false)
          navigate('/login')
        }}
      />
    )
    setModalOpen(true)
  }, [ setModalContent, setModalOpen, navigate ])

  return (
    <RootStyle title="Recover Password | ProAtivos">
      <MHidden width="mdDown">
        <ContentlessSectionStyle>
          <NewLogo imgStyle={{width: '30%', minWidth: '390px'}} />
        </ContentlessSectionStyle>
      </MHidden>
      <AsideWrapper>
        <AsideContentStyle>
          <MDUpHidden>
            <NewLogo imgStyle={{width: '30%', minWidth: '300px'}} />
          </MDUpHidden>
          <TitleStyles><Typography variant="h3" >RECUPERAR SENHA</Typography></TitleStyles>
          <SubtitleStyles><Typography variant="subtitle2" >ENVIAREMOS UM EMAIL PARA VOCÊ</Typography></SubtitleStyles>
          <RecoverPasswordForm handleSubmitProp={onSubmit}/>
        </AsideContentStyle>
      </AsideWrapper>
    </RootStyle>
  )
}

export default RecoverPassword