import React, { useState } from "react";
import VehiclesModel, { vehiclesToProductDetail } from "../../../../models/vehicles.model";
import palette from "../../../../theme/palette";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { treatString } from "../../../../utils";
import { Dialog } from "@material-ui/core";
import { ProductDetail } from "../products/product-detail";
import { Container, Row, Column, Field } from "./vehicles-item.styled";

type Props = {
    observation?: string
    vehicles: VehiclesModel
    onObservationChange: (observation: string) => void
}

export const VehiclesItem: React.FC<Props> = ({ vehicles, observation, onObservationChange }) => {
    const [modalOpen, setModalOpen] = useState(false)

    return (
        <Container key={vehicles.id}>
            <Row>
                <Column>
                    <h3 style={{ color: palette.background.default, fontSize: '1.1rem' }}>{treatString(vehicles.name.toLowerCase(), 40, { capitalize: true })}</h3>
                    <p style={{ color: palette.background.default, fontSize: '0.9rem' }}>{treatString(vehicles.description.toLowerCase(), 40, { capitalize: true })}</p>
                </Column>
                <InfoOutlinedIcon
                    style={{ color: 'white', fontSize: 26, cursor: "pointer" }}
                    onClick={() => setModalOpen(true)}
                />
            </Row>

            <Field
                placeholder={'Observação'}
                variant="outlined"
                value={observation}
                type="text"
                onChange={(event) => {
                    onObservationChange(event.target.value)
                }}
            />

            <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                <ProductDetail
                    detail={vehiclesToProductDetail(vehicles)}
                    onClose={() => setModalOpen(false)}/>
            </Dialog>
        </Container>
    )
}