import styled from "styled-components";
import palette from '../../../../theme/palette'

export const Title = styled.h2`
  font-size: 1.4rem;
  color: ${palette.primary.dark};
`

export const ContentLabel = styled.p`
  width: 100%;
  color: #73665c;
`

export const Label = styled.span`
  color: ${palette.primary.dark};
  font-weight: 500;
`
