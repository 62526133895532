import React, { useCallback, useEffect, useState } from "react";
import { BaseLayout } from "../../../layouts/base/base.layout";
import { ActiveTable } from "./actives-table";
import { Loading } from "../../../components/loading";
import { ActiveModel } from "./active-model";
import Space from "../../../components/Space";
import { Dialog, TextField } from "@material-ui/core";
import styled from "styled-components";
import palette from "../../../theme/palette";
import { handlePromise } from "../../../utils/handlePromisse";
import {
    favoriteProduct,
    getPaginatedProducts,
    getProductDetail,
    postAnnotation,
    unFavoriteProduct,
} from "../../../services/productService";
import { initialPagination, PaginatedResponse } from "../../../domain/models/paginated-response.model";
import { useAuth } from "../../../contexts/auth2";
import { Details, ProductDetail } from "../query/products/product-detail";
import { ProductModel, productModelToDetails } from "../../../models/Product.model";
import { AnnotationDialog } from "./annotation.dialog";

const Title = styled.h2`
  font-size: 1.5rem;
  color: ${palette.primary.dark};
  width: 100%;
  text-align: center;
`

export const ActivesPage: React.FC = () => {
    const { user: { idUser } } = useAuth()

    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState("")
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [details, setDetails] = useState<Details|null>(null)
    const [products, setProducts] = useState<PaginatedResponse<ActiveModel>>(initialPagination)
    const [annotationOpen, setAnnotationOpen] = useState(false)
    const [activeSelected, setActiveSelected] = useState<ActiveModel|null>(null)

    const fetchProducts = useCallback(async (newPage: number = 1, newSize: number = 10, order?: string, orderBy?: string) => {
        setOpenBackdrop(true)
        const [result, error] = await handlePromise(getPaginatedProducts(newPage, newSize, order, orderBy, filter))
        setOpenBackdrop(false)

        if (!!error) {
            console.log('error', error, error.stack)
            return
        }

        const response = result.data as PaginatedResponse<ActiveModel>;
        setProducts(response)
    }, [setProducts, setOpenBackdrop, filter])

    useEffect(() => {
        fetchProducts()
    }, [fetchProducts])

    const handlePageChange = useCallback(async (newPage: number, size: number) => {
        await fetchProducts(newPage, size)
    }, [fetchProducts])

    const handleSizeChange = useCallback(async (newSize: number, page: number) => {
        await fetchProducts(page, newSize)
    }, [fetchProducts])

    const handleOrderChange = useCallback(async (orderBy: keyof ActiveModel, order: string, page: number, size: number) => {
        await fetchProducts(page, size, order, orderBy)
    }, [fetchProducts])

    const handleCheckActive = useCallback(async (active: ActiveModel) => {
        setOpenBackdrop(true)
        const [result, error] = await handlePromise(getProductDetail(active.id))
        setOpenBackdrop(false)

        if (!!error) {
            console.log('error', error, error.stack)
            return
        }

        const model = result.data as ProductModel
        const details = productModelToDetails(model)

        setDetails(details)
        setOpen(true)
    }, [])

    const handleClickOpen = useCallback(async (active: ActiveModel) => {
        setActiveSelected(active)
        setAnnotationOpen(true)
    }, [setActiveSelected])

    const saveAnnotation = useCallback(async (newAnnotation: string) => {
        if (!activeSelected) return

        const [_, error] = await handlePromise(postAnnotation(activeSelected?.id, { annotation: newAnnotation }))
        if (!!error) {
            console.log('error', error, error.stack)
            return
        }

        fetchProducts()
        setAnnotationOpen(false)
    }, [activeSelected])

    const handleFavorite = useCallback(async (active: ActiveModel) => {
        if (active.favorite) {
            await unFavoriteProduct(idUser, active.id)
        } else {
            await favoriteProduct({
                id_Product: active.id, id_User: idUser
            })
        }

        products.data.filter(p => p.id === active.id).forEach(p => p.favorite = !p.favorite)
        setProducts({ ...products })
    }, [unFavoriteProduct, favoriteProduct, fetchProducts, setProducts, products])

    return (
        <BaseLayout hasLogo={true}>
            {details && open && <Dialog open={open} onClose={() => setOpen(false)}>
                <ProductDetail
                    detail={details}
                    onClose={() => setOpen(false)}
                />
            </Dialog>}

            {
                annotationOpen && <AnnotationDialog
                    annotation={activeSelected?.annotation}
                    open={annotationOpen}
                    onSaveAnnotation={saveAnnotation}
                    onClose={() => setAnnotationOpen(false)}
                />
            }

            <Loading open={openBackdrop}/>

            <Space pixels={8} direction={'v'}/>

            <Title>BUSCAR ATIVOS</Title>

            <Space pixels={8} direction={'v'}/>

            <TextField
                label={'Buscar...'}
                variant="outlined"
                color="primary"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
                sx={{ width: '100%' }}
            />

            <Space pixels={16} direction={'v'}/>

            <ActiveTable
                data={products.data}
                total={products.pagination.total}
                size={products.pagination.perPage}
                page={products.pagination.currentPage}
                onPageChange={handlePageChange}
                onSizeChange={handleSizeChange}
                onOrderChange={handleOrderChange}
                onFavorite={handleFavorite}
                onCheckActive={handleCheckActive}
                annotationOpen={(model) => handleClickOpen(model)}
            />
        </BaseLayout>
    )
}
