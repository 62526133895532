import React, { FC, SyntheticEvent, useState } from 'react'
import palette from '../../../theme/palette'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import styled from "styled-components";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FrequentlyQuestion } from '../../../models/frequently-question.model';
import { BaseLayout } from "../../../layouts/base/base.layout";

const Floating = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
`

const AccordionFAQ = styled(AccordionSummary)`
  color: ${palette.secondary.main};
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin: 4px;
  background-color: ${palette.primary.lighter};
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`

const TypographyFAQ = styled(Typography)`
  color: ${palette.secondary.main};
`

const ListFAQ = styled('ul')`
  list-style-type: none;
`

const FAQ = [
  {
    question: 'Paguei a ProAtivos por pix e ainda não liberou, o que fazer?',
    answers: ['R: Saia e entre novamente com seu login e senha. Se o erro permanecer, entre em contato com a gente.']
  }, {
    question: 'A plataforma ProAtivos tem um período de teste grátis?',
    answers: ['R: Sim, no plano Mensal (recorrente) após cadastrar o seu cartão você terá 7 dias grátis para testar a plataforma. Passando do período de teste será debitado o valor do plano no seu cartão e ocorrerá uma nova cobrança de renovação dentro de 30 dias.']
  }, {
    question: 'Estou com dúvidas sobre os planos disponíveis.',
    answers: ['R: Na ProAtivos, temos duas modalidades de plano: recorrentes e não recorrentes. Os planos recorrentes, quando disponíveis, terão indicado no nome "recorrente" e contém informações na descrição. Planos recorrentes são assinaturas que possuem renovação automática mensal e oferecem um período de 7 dias grátis de acesso a plataforma. Já os planos não recorrentes não possuem renovação automática, portanto, após o término do plano você deverá comprá-lo novamente para liberar acesso a plataforma ProAtivos.']
  }, {
    question: 'Comprei um plano não recorrente (mensal, trimestral, semestral ou anual), tenho 7 dias grátis?',
    answers: ['R: Não. Nos planos que não são recorrentes, você terá garantia de 7 dias pela plataforma. Ou seja, caso a plataforma ProAtivos não atenda suas necessidades durante esse período, entre em contato com o suporte para acionar a garantia.']
  }, {
    question: 'Estou com problemas no pagamento, o que fazer?',
    answers: ['R: Tente mudar o cartão utilizado ou a forma de pagamento. Se o erro persistir, entre em contato com o nosso suporte e detalhe o ocorrido.']
  }, {
    question: 'Tenho sugestões para melhorar a plataforma, o que fazer?',
    answers: ['R: Entre em contato conosco pelo e-mail ou whatsapp. Vamos adorar receber seu feedback!']
  }, {
    question: 'Estava sem limite no cartão no momento da recorrência, o que fazer?',
    answers: ['R: Libere limite do seu cartão pois dentro de 2 dias haverá uma nova tentativa de cobrança. No total, são 4 tentativas. Após as 4 tentativas, seu plano será automaticamente cancelado. Em caso de dúvidas ou caso queira cancelar seu plano durante esse período de tentativa de cobrança, entre em contato com o nosso suporte pelo WhatsApp.']
  }, {
    question: 'Como faço o cancelamento do meu plano?',
    answers: ['R: Caso tenha o plano recorrente, você pode cancelar sua assinatura a qualquer momento na aba "planos" clicando em "calcelar plano". Após o cancelamento, você ainda terá acesso a plataforma até zerar a contagem do tempo do seu plano. Se você tiver um plano não recorrente (mensal, trimestral, semestral ou anual), o cancelamento do seu plano é automático após a finalização do período do plano.']
  }, {
    question: 'Canais para contato',
    answers: ['R: E-mail do suporte: suporteproativos@gmail.com ', 'Whatsapp: (51) 99391-4753']
  }
] as FrequentlyQuestion[]

const SupportPage: FC = () => {

  const [expanded, setExpanded] = useState<string | false>(false);

  const openWhats = () => {
    window.open("https://api.whatsapp.com/send?phone=555193914753", '_blank')
  }

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <BaseLayout hasLogo={true}>

      <h2 style={{ color: palette.secondary.main, textAlign: 'center', marginBottom: '10px' }}>Dúvidas Frequentes</h2>
      {
        FAQ.map((question, index) => {
          return <Accordion expanded={expanded === `${index}`}
            onChange={handleChange(`${index}`)} sx={{ wordWrap: 'break-word', width: '95%', }}>
            <AccordionFAQ
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TypographyFAQ sx={{ width: '100%', }}>
                {question.question}
              </TypographyFAQ>
            </AccordionFAQ>
            <AccordionDetails sx={{ boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.25)', }}>
              <TypographyFAQ>
                <ListFAQ>
                  <li>
                    <ListFAQ>
                      {question.answers.map((answer) => { return <li>{answer}</li> })}
                    </ListFAQ>
                  </li>
                </ListFAQ>
              </TypographyFAQ>
            </AccordionDetails>
          </Accordion>
        })
      }

      <Floating onClick={openWhats} style={{ cursor: "pointer" }}>
        <WhatsAppIcon fontSize={"large"} />
      </Floating>
    </BaseLayout>
  )
}
export default SupportPage
