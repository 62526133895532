import { Details } from "../pages/main/query/products/product-detail";

export default interface VehiclesModel {
    id: number
    PH: string
    name: string
    source: string
    minimum: number
    maximum: number
    favorite: boolean
    description: string
    incompatibility: string
    mechanism_action: string
}

export const vehiclesToProductDetail = (vehicle: VehiclesModel | null | undefined) => {
    return {
        name: vehicle?.name,
        mechanism: vehicle?.mechanism_action,
        description: vehicle?.description,
        ph: vehicle?.PH,
        incompatibility: vehicle?.incompatibility,
        minimum: vehicle?.minimum,
        maximum: vehicle?.maximum,
        source: vehicle?.source,
    } as Details
}
