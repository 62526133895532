import {Stack, Button} from '@material-ui/core'
import {btnPrimary, btnSecondary} from '../../pages/main/profile/ModalAddress/styles'
import React, { FC } from 'react'
import { useModal } from '../../contexts/modal'
import palette from '../../theme/palette'
import { stackFlexColumn, stackFlexRow } from '../../utils/MUIShortcuts'
import MCBase from './MCBase'

type MCConfirmProps = {
  title: string,
  text?: string,
  onConfirm?: () => void,
  onCancel?: () => void,
  onEither?: () => void,
  confirmButtonText?: string,
  cancelButtonText?: string,
}

export const h2Style: React.CSSProperties = {
  color: palette.primary.dark, 
  textAlign: 'center',
  margin: '10px',
}

const pStyle: React.CSSProperties = {
  color: palette.primary.dark, 
  textAlign: 'center',
  margin: '24px 0'
}

const MCConfirm: FC<MCConfirmProps> = ({title, text, confirmButtonText, cancelButtonText, onConfirm, onCancel, onEither}) => {
  const { setModalOpen } = useModal()
  return (
    <MCBase>
      <h2 style={h2Style}>{title}</h2>
        {text && <p style={pStyle} dangerouslySetInnerHTML={{__html: text}}></p>}
      <Stack { ...( window.innerWidth < 350 ? stackFlexColumn('center', 'space-evenly') : stackFlexRow('center', 'center')) } style={{width: '100%'}} spacing={2}>
          <Button
              size={"large"}
              onClick={() => {
                  !!onConfirm ? onConfirm() : setModalOpen(false)
                  if (!!onEither) onEither()
              }}
              sx={btnPrimary}
              variant="contained">{confirmButtonText}</Button>

          <Button
              size={"large"}
              onClick={() => {
                  !!onCancel ? onCancel() : setModalOpen(false)
                  if (!!onEither) onEither()
              }}
              sx={btnSecondary}
              variant="contained">{cancelButtonText ?? 'cancelar'}</Button>
      </Stack>
    </MCBase>
  )
}

export default MCConfirm