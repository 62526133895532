import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import * as React from "react";

type Props = {
    open: boolean
    onClose: () => void
    onConfirm: () => void
}

export const DoseWarningDialog: React.FC<Props> = ({ open, onClose, onConfirm }) => {
    return (
        <Dialog open={open} onClose={() => onClose} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <DialogTitle>Deseja continuar?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Você possui itens na lista com valores fora do intervalo recomendado
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Não
                </Button>
                <Button
                    onClick={onConfirm}>
                    Sim
                </Button>
            </DialogActions>
        </Dialog>
    )
}