import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { clearQuery, getNotifyFormula, getQuery, setQuery } from "../../../../services/local-session-storage";
import { useNavigate } from "react-router-dom";
import { getPdf, queryToConsultation, saveConsultation } from "../../../../services/decisionFlow";
import { DoseWarningDialog } from "./dose-warning.dialog";
import { useAuth } from "../../../../contexts/auth2";
import { Add, ArrowBack, Done, PictureAsPdf } from "@material-ui/icons";
import Space from "../../../../components/Space";
import { QueryObservations } from "../observation/query-observations";
import { MessageDialog } from "../../../../components/message-dialog";
import { ProductList } from "../../../../components/product/product-list";
import { ProductModel } from "../../../../models/Product.model";
import { redirectToPDF } from "../../../../utils/pdf.util";
import styled from "styled-components";
import { CircleButton, IconsContainer } from "../../../../styles/styles";
import { QueryState } from "../index";
import { TextField } from "@material-ui/core";
import { DoseNotifyDialog } from "./dose-notify.dialog";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 16px;

  @media (max-width: 640px) {
    padding-bottom: 60px;
    position: relative
  }
`

export const DosePage: React.FC = () => {
    const model = getQuery()
    const navigate = useNavigate()

    const { user: { idUser } } = useAuth()
    const [open, setOpen] = useState(false)
    const [observation, setObservation] = useState(model.observation || '')
    const [showAnotherConsult, setShowAnotherConsult] = useState(false)
    const [showNotifyDialog, setShowNotifyDialog] =
        useState<{show: boolean, action?: 'done'|'pdf'}>({ show: false, action: undefined })
    const [notifyWasShown, setNotifyWasShown] = useState(false)
    const [observations, setObservations] = useState<string[]>(model.observations || [])
    const [warning, setWarning] = useState<boolean>(false)
    const [products, setProducts] = useState<ProductModel[]>([])
    const [openObservations, setOpenObservations] = useState(false)
    const [nameConsultation, setNameConsultation] = useState<string>(model.consultationName || '')

    const handleChange = (id: number, value: string) => {
        const product = products.find(p => p.idProduct == id)
        if (product) {
            product.percentage = value
        }

        setWarning(!validateProducts())
        setQuery({ products })
    }

    const save = useCallback(async () => {
        const request = queryToConsultation(getQuery(), idUser, observation, observations, products, nameConsultation)
        const result = await saveConsultation(request)
        return result.data.id as number
    }, [queryToConsultation, saveConsultation, handleChange, model, observation, observations])

    const handleGeneratePdf = useCallback(async (warning: boolean = false) => {
        if (warning) {
            setOpen(true)
        } else if (!!model.patientId) {
            await save();
            const result = await getPdf(getQuery(), idUser, observation, observations, products, nameConsultation)

            redirectPdf(result.data.pdf)
        } else {
            const result = await getPdf(model, idUser, observation, observations, products, nameConsultation)
            redirectPdf(result.data.pdf)
        }
    }, [save, setOpen, model, observation, observations])

    const handleDone = async () => {
        if(warning) {
            setOpen(true)
        } else if (!!model.patientId) {
            await save()
            setShowAnotherConsult(true)
        } else {
            navigate('/')
        }
    }

    const handleActionsToDone = async (action: 'done'|'pdf'|undefined) => {
        if (!getNotifyFormula() && !notifyWasShown) {
            setShowNotifyDialog({ show: true, action })
            setNotifyWasShown(true)
            return
        }

        if (action === 'done') {
            await handleDone()
        } else {
            await handleGeneratePdf(warning)
        }
    }

    const validateProducts = () => {
        for (const product of products) {
            const percentage = Number(product.percentage)
            if (percentage < Number(product.minimumDosage) || percentage > Number(product.maxDosage)) {
                return false
            }
        }
        return true
    }

    const redirectPdf = (base64: string) => {
        redirectToPDF(base64)

        if (model.patientId) {
            setShowAnotherConsult(true)
        } else {
            setTimeout(() => {
                navigate('/')
            }, 500)
        }
    }

    const handleConfirmDialog = useCallback(() => {
        setOpen(false)
        handleGeneratePdf(false)
    }, [setOpen, setWarning, handleGeneratePdf])

    const onDeleteItem = useCallback((id: number) => {
        setProducts(prev => prev.filter((product) => product.idProduct !== id))
    }, [setQuery, products])

    const handleFinishConsult = useCallback(async () => {
        clearQuery()
        setShowAnotherConsult(false)
        navigate('/dashboard/query')
    }, [setShowAnotherConsult, navigate])

    const handleAnotherConsult = useCallback(async () => {
        const id = model.patientId
        clearQuery()
        navigate('/dashboard/query', {
            state: { patientId: id } as QueryState,
        })
    }, [navigate])

    const handleBackButton = useCallback(() => {
        navigate('/dashboard/query', {
            state: { fromDoseNavigation: true } as QueryState,
        })
    }, [])

    useEffect(() => {
        setProducts(model.products)
    }, [])

    useEffect(() => {
        setQuery({ products })
    }, [products])

    return (
        <Container>
            <DoseNotifyDialog
                open={showNotifyDialog.show}
                onClose={() => setShowNotifyDialog({ show: false })}
                onConfirm={() => handleActionsToDone(showNotifyDialog.action)}/>

            <MessageDialog
                open={showAnotherConsult}
                message={"Deseja continuar formulando para o mesmo paciente?"}
                onClose={handleFinishConsult}
                onConfirm={handleAnotherConsult}
                confirmButtonText={"Sim"}
                cancelButtonText={"Não"}/>

            <DoseWarningDialog
                open={open}
                onClose={() => setOpen(false)}
                onConfirm={handleConfirmDialog}/>

            <QueryObservations
                open={openObservations}
                externalObservations={observations}
                onClose={() => setOpenObservations(false)}
                onConfirm={(observations) => {
                    setOpenObservations(false)
                    setObservations(observations)
                    setQuery({ observations })
                }}
            />

            <TextField
                label={"Nome da consulta"}
                style={{ width: '100%', marginBottom: '16px' }}
                color={"primary"}
                value={nameConsultation}
                onChange={(e) => {
                    setNameConsultation(e.target.value)
                    setQuery({ consultationName: e.target.value })
                }}
                inputProps={{
                    maxLength: 100,
                }}
                helperText={`${nameConsultation.length}/${100}`}
            />

            <ProductList
                products={products}
                vehicles={model.vehicles}
                observation={observation}
                onRemoveItem={onDeleteItem}
                onDoseChange={handleChange}
                onObservationChange={obs => {
                    setObservation(obs)
                    setQuery({ observation: obs })
                }}
            />

            <Space pixels={16} direction={'v'}/>

            <IconsContainer>
                <CircleButton onClick={handleBackButton}><ArrowBack/></CircleButton>
                <Space pixels={16} direction={'h'}/>
                <CircleButton onClick={() => setOpenObservations(true)}><Add/></CircleButton>
                <Space pixels={16} direction={'h'}/>
                <CircleButton onClick={() => handleActionsToDone('pdf')}><PictureAsPdf/></CircleButton>
                <Space pixels={16} direction={'h'}/>
                <CircleButton onClick={() => handleActionsToDone('done')}><Done/></CircleButton>
            </IconsContainer>
            <Space pixels={16} direction={'v'}/>
        </Container>
    )
}