import {Button, CircularProgress, Container, Dialog, Stack} from '@material-ui/core'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {btnPrimary, btnSecondary, containerStyles} from '../../pages/main/profile/ModalAddress/styles'
import {useModal} from '../../contexts/modal'
import MCConfirm from './MCConfirm'
import {stackFlexColumn, stackFlexRow} from '../../utils/MUIShortcuts'
import {exists, removeEmptyAttributes} from '../../utils'
import MCAlert from './MCAlert'
import {useAuth} from '../../contexts/auth2'
import {handlePromise} from '../../utils/handlePromisse'
import {getUserProfile} from '../../services/profileService'
import {useNavigate} from 'react-router'
import './paymentInfo.css'
import {tokenToUser} from "../../services/userService";

export type PaymentInfoState = {
    titularName: string,
    cpf: string,
    expirationDate: string,
    cvv: string,
    cardNumber: string,
    brand: string,
    issuer: string
}

type MCPaymentInfoProps = {
    onClose?: () => void
    onAssign?: (values: PaymentInfoState) => void,
    preValues?: PaymentInfoState,
    callback: () => void
}

const MCPaymentInfo: FC<MCPaymentInfoProps> = ({onClose, onAssign, preValues, callback}) => {

    const {setModalOpen, setModalContent} = useModal()
    const navigate = useNavigate()
    const {user} = useAuth()
    const [isThereAddress, setIsThereAddress] = useState(false)

    useEffect(() => {
        (async () => {
            const [result, error] = await handlePromise(getUserProfile(user.idUser))
            if (error) {
                setModalContent(<MCAlert title="Erro ao buscar informações do usuário"/>)
            }
            if (!!result && !!result.data) {
                const addressLength = Object.entries(removeEmptyAttributes(result.data.address)).length
                const isThereAddress = exists(result.data.address) || addressLength >= 6

                setIsThereAddress(isThereAddress);
                if (!isThereAddress) {
                    setModalContent(<MCConfirm
                        title="Perfil incompleto!"
                        text="Para aderir um plano é necessário completar suas informações."
                        confirmButtonText="completar"
                        cancelButtonText="agora não"
                        onConfirm={() => {
                            navigate('/dashboard/profile')
                            setModalOpen(false)
                        }}
                    />)
                    setModalOpen(true)
                }
            }
        })()
    }, [user.idUser, setModalContent, setModalOpen, navigate])

    const onCloseCallback = useCallback(() => {
        if (!!onClose) {
            onClose()
            setModalOpen(false)
        } else {
            setModalOpen(false)
        }
    }, [onClose, setModalOpen])

    if (isThereAddress) {
        const user = tokenToUser();
        const onChangeDocument = (e: any) => {
            e.target.value = e.target.value.replace(/\D/g, "")
        }

        const onBlurName = (e: any) => {
            e.target.value = e.target.value.trim().split(" ").filter((x: string) => x.trim() != "").map((v: string) => v.trim()).join(" ")
        }

        callback()
        // @ts-ignore
        return <Dialog open={true}>
            <Container sx={{
                ...containerStyles,
                maxHeight: '700px',
                maxWidth: '350px !important',
                width: '100%',
                minWidth: '300px',
                height: '22hv',
                minHeight: '50px'
            }}>
                <div id={"circularProgress"} style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center"}}>
                    <CircularProgress color="primary" />
                </div>
                <div className="warning-fields" style={{display: "none"}}>
                    <p>* Todos os campos devem ser preenchidos</p>
                </div>
                <form id="form-checkout" style={{display: "none"}} autoComplete="off">
                    <div id="form-checkout__cardNumber" className="container"></div>
                    <div id="form-checkout__expirationDate" className="container"></div>
                    <div id="form-checkout__securityCode" className="container"></div>
                    <input type="text" id="form-checkout__cardholderName" className="container" onBlur={(e) => onBlurName(e)}/>
                    <select id="form-checkout__issuer" className="container"></select>
                    <select id="form-checkout__installments" className="container"></select>
                    <select id="form-checkout__identificationType" className="container"></select>
                    <input type="text" id="form-checkout__identificationNumber" className="container" onChange={(e) => onChangeDocument(e)}/>
                    <input type="email" id="form-checkout__cardholderEmail" className="container disabled" value={user.email}/>
                    <Stack
                        {...(window.innerWidth < 350 ? stackFlexColumn('center', 'space-evenly') : stackFlexRow('center', 'center'))}
                        style={{width: '100%', marginBottom: '16px', marginTop: '8px'}} spacing={2}
                    >
                        <Button
                            size={"large"}
                            onClick={onCloseCallback}
                            sx={btnSecondary}
                            variant="contained">VOLTAR</Button>
                        <Button
                            type="submit"
                            id="form-checkout__submit"
                            size={"large"}
                            sx={btnPrimary}
                            variant="contained">PAGAR</Button>
                    </Stack>
                </form>
            </Container>
        </Dialog>
    }

    return <Container></Container>;
}

export default MCPaymentInfo