import styled from "styled-components";
import palette from "../../../theme/palette";
import { Card, TextField } from "@material-ui/core";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 16px;

  @media (max-width: 640px) {
    padding-bottom: 60px;
    position: relative
  }
`

// region: Item styles
export const CardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: ${palette.primary.lighter};
`

export const Name = styled.h3`
  font-size: 1.1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${palette.primary.dark};
`

export const Description = styled.div`
  font-size: .9rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${palette.primary.dark};
`

export const Warning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-size: .85rem;
  color: ${palette.error.dark};
`

export const DosageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`

export const Dosage = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #968272;
`

export const DosageInput = styled(TextField)`
  background: white;

  .MuiOutlinedInput-input {
    text-align: center;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`
// endregion
