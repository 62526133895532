import { Theme, ThemeOptions } from "@material-ui/core";

export default function Container(theme: Theme): ThemeOptions["components"] {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          border: 'none !important',
          outline: 'none !important'
        }
      }
    }
  };
}