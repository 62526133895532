import React, {useCallback, useEffect, useState} from 'react'
import {StepModel} from "./step.model";
import {Patient} from "./patient/patient";
import {Definitions} from "./definitions";
import {Actives} from "./actives/actives";
import {SubClass} from "./subclass/sub-class";
import {Products} from "./products/products";
import {useLocation, useNavigate} from "react-router-dom";
import {ActiveResponse, getSubclasses, SubClassesObject, SubclassesResponse} from "../../../services/decisionFlow";
import {useAuth} from "../../../contexts/auth2";
import {getUserProfile, UserProfileResponse} from "../../../services/profileService";
import {MessageDialog} from "../../../components/message-dialog";
import {getAllActives} from "../../../services/activeService";
import VehiclesModel from "../../../models/vehicles.model";
import {getVehicles} from "../../../services/vehicles.service";
import {Vehicles} from "./vehicles/vehicles";
import {getConsult} from "../../../services/history";
import {clearQuery, getQuery, setQuery} from "../../../services/local-session-storage";
import {ConsultationModel} from "../../../domain/models/consultation.model";
import {toConsultation} from "./query.model";
import styled from "styled-components";

export type SubClassType = {
    active: string
    subClass: SubClassesObject[]
}

export type QueryState = {
    patientId?: string
    consultationId?: string
    fromDoseNavigation?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

const Index: React.FC = () => {

    const model = getQuery()
    const { state } = useLocation();
    const navigate = useNavigate()
    const { user: { idUser } } = useAuth()
    const [open, setOpen] = useState(false)
    const [currentStep, setCurrentStep] = useState<StepModel>(StepModel.PATIENT)
    const [actives, setActives] = useState<ActiveResponse[]>([])
    const [subclass, setSubclass] = useState<SubClassType[]>([])
    const [vehicles, setVehicles] = useState<VehiclesModel[]>([])

    const fetchUser = useCallback(async () => {
        const result = await getUserProfile(idUser)
        const user = result.data as UserProfileResponse;
        setOpen(user.amountQueriesUsed_User >= user.amountQueries_PlansPG)
    }, [setOpen])

    const fetchActives = useCallback(async () => {
        const result = await getAllActives()
        const data = result.data.data as ActiveResponse[];

        setActives(data)
    }, [setActives])

    const fetchVehicles = useCallback(async () => {
        const result = await getVehicles()
        const data = result.data.data as VehiclesModel[];
        setVehicles(data)
    }, [setActives])

    const fetchSubclasses = useCallback(async (actives: ActiveResponse[]) => {
        const activeMap = actives.map(value => value.id)
        const result = await getSubclasses({ actives: activeMap })

        const data = result.data as SubclassesResponse;

        let normalizedList: SubClassType[] = []
        for (let attr in data) {
            normalizedList = normalizedList.concat({
                active: attr,
                subClass: data[attr]
            } as SubClassType)
        }
        if (normalizedList.length > 0) {
            toggleStep(StepModel.SUBCLASS)
        } else {
            toggleStep(StepModel.PRODUCTS)
        }
        setSubclass(normalizedList)
    }, [setSubclass])

    const fetchConsultation = useCallback(async (id: string) => {
        const result = await getConsult(Number(id))
        const consultation = result.data as ConsultationModel

        clearQuery()
        setQuery(toConsultation(consultation))
        setQuery({ canEdit: false })

        toggleStep(StepModel.PRODUCTS)
    }, [getConsult])

    const handleActiveList = useCallback(async () => {
        const result = await getAllActives()
        const ativos = result.data.data as ActiveResponse[]
        const data = getQuery()
        if (data.activeList) {
            setQuery({ actives: ativos })
            toggleStep(StepModel.PRODUCTS)
        } else {
            toggleStep(StepModel.ACTIVES)
        }
    }, [model.activeList])

    useEffect(() => {
        fetchUser()
        fetchActives()
        fetchVehicles()
        if (state !== null) {
            const query = (state as QueryState);
            if (query.consultationId !== undefined) {
                fetchConsultation(query.consultationId)
            }
            if (query.fromDoseNavigation !== undefined ) {
                toggleStep(StepModel.VEHICLES)
            } else if (query.patientId !== undefined) {
                setQuery({ patientId: query.patientId, canEdit: true })
                toggleStep(StepModel.DEFINITIONS)
            }
        }
    }, [fetchActives, fetchUser, fetchVehicles,])

    const handleActivesFinish = (actives: ActiveResponse[]) => {
        fetchSubclasses(actives)
    }

    const toggleStep = useCallback((nextStep: StepModel) => {
        setCurrentStep(nextStep)
    }, [setCurrentStep])

    const goToDose = useCallback(() => {
        navigate('/dashboard/dose')
    }, [])

    return (
        <Container>
            <MessageDialog
                emoji="😢"
                open={open}
                title={"aaah, que pena! "}
                message={"Você excedeu o limite de consultas para seu plano, deseja fazer o upgrade?"}
                onClose={() => navigate('/dashboard/app')}
                onConfirm={() => navigate('/dashboard/pricing')}
                confirmButtonText={"Fazer Agora"}
                cancelButtonText={"Cancelar"} />
            {
                currentStep === StepModel.PATIENT &&
                <Patient onFinish={() => {
                    toggleStep(StepModel.DEFINITIONS)
                }} />
            }
            {
                currentStep === StepModel.DEFINITIONS &&
                <Definitions
                    onFinish={() => handleActiveList()}
                    onBack={() => toggleStep(StepModel.PATIENT)}
                />
            }
            {
                actives && currentStep === StepModel.ACTIVES &&
                <Actives
                    actives={actives}
                    onFinish={(actives) => handleActivesFinish(actives)}
                    onBack={() => toggleStep(StepModel.DEFINITIONS)}
                />
            }
            {
                currentStep === StepModel.SUBCLASS &&
                <SubClass
                    subclass={subclass}
                    onFinish={() => toggleStep(StepModel.PRODUCTS)}
                    onBack={() => toggleStep(StepModel.ACTIVES)}
                />
            }
            {
                currentStep === StepModel.PRODUCTS &&
                <Products
                    onFinish={() => toggleStep(StepModel.VEHICLES)}
                    onAddMoreItem={async () => {
                        return model.activeList
                            ? toggleStep(StepModel.DEFINITIONS)
                            : toggleStep(StepModel.ACTIVES)
                    }}
                />
            }
            {
                currentStep === StepModel.VEHICLES &&
                <Vehicles
                    vehicles={vehicles}
                    onFinish={() => goToDose()}
                    onBack={() => toggleStep(StepModel.PRODUCTS)}
                />
            }
        </Container>
    )
}

export default Index;
