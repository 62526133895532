import { Order, Patient } from "../../../../services/patient.service"
import * as React from "react"
import { useState } from "react"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import { PatientRow } from "./patient-row"
import { Card, TablePagination } from "@material-ui/core"
import TableSortLabel from "@mui/material/TableSortLabel"
import Box from "@mui/material/Box"
import { visuallyHidden } from "@mui/utils"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material"

type Props = {
    page: number
    size: number
    total: number
    isMobile: boolean
    patients: Patient[]
    onEdit: (patient: Patient) => void
    onDelete: (patient: Patient) => void
    onPdfGenerate: (consults: number[]) => void
    onEditConsult: (consult: number) => void
    onDeleteConsult: (consult: number) => void
    onPageChange: (newPage: number, size: number) => void
    onSizeChange: (newSize: number, page: number) => void
    onOrderChange: (orderBy: keyof Patient, order: Order, page: number, size: number) => void
}

interface Header {
    id: 'name'|'consults'|'actions'
    label: string
    isOrdenable: boolean
    hidden?: boolean
    style?: SxProps<Theme>
}

export const PatientTable: React.FC<Props> = ({
  patients, page, size, total, isMobile,
  onPageChange, onSizeChange, onOrderChange,
  onEdit, onDelete, onPdfGenerate, onEditConsult, onDeleteConsult
}) => {

    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState<keyof Patient>('name')
    const [perPage] = useState(size > 100 ? [10, 25, 50, 100] : [5, 10, 25])

    const headers: readonly Header[] = [
        {
            id: 'name',
            label: 'Nome',
            isOrdenable: false,
            style: { width: isMobile ? '80%' : '50%' }
        },
        {
            id: 'consults',
            label: 'Consultas',
            isOrdenable: false,
            hidden: isMobile,
            style: { width: isMobile ? '0%' : '30%', }
        },
        {
            id: 'actions',
            label: 'Ações',
            isOrdenable: false,
            style: { width: isMobile ? '20%' : '20%', textAlign: 'center' }
        },
    ]

    const handleChangePage = (event: unknown, newPage: number) => {
        onPageChange(newPage + 1, size)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSizeChange(+event.target.value, 0)
    }

    const handleSort = (property: keyof Patient) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        onOrderChange(property, isAsc ? 'desc' : 'asc', 0, size)
    }

    const orderLabel = (header: Header) => {
        return (
            <TableSortLabel
                active={orderBy === header.id}
                direction={orderBy === header.id ? order : 'asc'}
                onClick={() => handleSort(header.id)}>
                {header.label}
                {orderBy === header.id ? (
                    <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                ) : null}
            </TableSortLabel>
        )
    }

    return (
        <Card variant="outlined">
            <TableContainer>
                <Table aria-label="collapsible patient table">
                    <TableHead>
                        {headers.map((header) =>
                                !header.hidden && <TableCell key={header.id} sx={header.style}>
                                    {
                                        header.isOrdenable ? orderLabel(header) : header.label
                                    }
                                </TableCell>
                        )}
                    </TableHead>
                    <TableBody>
                        {patients.map((patient) => (
                            <PatientRow
                                onEdit={onEdit}
                                key={patient.id}
                                patient={patient}
                                isMobile={isMobile}
                                onDelete={onDelete}
                                onPdfGenerate={onPdfGenerate}
                                onEditConsult={onEditConsult}
                                onDeleteConsult={onDeleteConsult}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                page={page - 1}
                component="div"
                count={total}
                rowsPerPage={size}
                rowsPerPageOptions={perPage}
                onPageChange={handleChangePage}
                labelRowsPerPage={"Linhas por página"}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
        </Card>
    )
}
