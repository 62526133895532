import React, { useCallback, useState } from "react";

import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@material-ui/core";
import styled from "styled-components";

type Props = {
    open: boolean
    onClose: () => void
    externalObservations?: string[]
    onConfirm: (observations: string[]) => void
}

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 8px;
  flex-wrap: wrap;
`

export const QueryObservations: React.FC<Props> = ({ open, onClose, onConfirm, externalObservations }) => {

    const [newObservation, setNewObservation] = useState("")
    const [observations, setObservations] = useState<string[]>(externalObservations || [])

    const handleKeyPress = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && newObservation.trim().length > 0) {
            event.preventDefault()
            setObservations([...observations, newObservation])
            setNewObservation("")
        }
    }, [setObservations, observations, newObservation, setNewObservation])

    const handleChipDelete = useCallback((feat: string) => {
        const newFeatures = observations.filter(value1 => value1 !== feat);
        setObservations(newFeatures)
    }, [observations, setObservations])

    return (
        <Dialog open={ open } onClose={ onClose } fullWidth={ true }>
            <DialogTitle>Observações</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    multiline
                    label="Observação"
                    variant="outlined"
                    helperText="Aperte Enter para incluir nova observação"
                    value={ newObservation }
                    onKeyPress={ event => handleKeyPress(event) }
                    onChange={ (e) => setNewObservation(e.target.value) }
                    sx={ { marginTop: "16px", width: '100%', minWidth: '320px' } }/>

                <ChipContainer>
                    { observations.map((obs) => {
                        return <Chip
                            size="small"
                            variant="filled"
                            onDelete={ _ => handleChipDelete(obs) }
                            style={ { height: "100%", marginTop: '8px' } }
                            label={ <Typography style={ { whiteSpace: "normal" } }>{ obs }</Typography> }
                        />
                    }) }
                </ChipContainer>
            </DialogContent>

            <DialogActions>
                <Button onClick={ onClose }>Cancelar</Button>
                <Button onClick={ () => { onConfirm(observations)} } disabled={ newObservation.length > 0 }>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}