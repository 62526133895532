import React, { useCallback, useEffect, useState } from "react";
import { Title } from "../../../../theme/globalStyles"
import { ActiveResponse } from "../../../../services/decisionFlow";
import { Alert, Button } from "@material-ui/core";
import { CheckableList, CheckableValue } from "../../../../components/checkable-list";
import { setQuery } from "../../../../services/local-session-storage";
import { ListContainer, QueryContainer } from "../index.styled";
import { CircleButton, SpacedIconsContainer } from "../../../../styles/styles";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import Space from "../../../../components/Space";
import { BaseLayout } from "../../../../layouts/base/base.layout";
import TemporaryDrawer from "../products/sidebar";
import { getQuery } from '../../../../services/local-session-storage';

type Props = {
    onFinish: (actives: ActiveResponse[]) => void
    onBack: () => void
    actives: ActiveResponse[]
}

export const Actives: React.FC<Props> = ({ onFinish, onBack, actives }) => {
    const [checkableValue, setCheckableValue] = useState<CheckableValue<ActiveResponse>[]>([])

    const {products} = getQuery()

    const transformActives = useCallback(() => {
        const list = actives.map(active => {

            return {
                value: active,
                isSelected: false
            } as CheckableValue<ActiveResponse>
        })

        setCheckableValue(list)
    }, [setCheckableValue, actives])

    useEffect(() => {
        transformActives()
    }, [transformActives])

    function getSelectedItems() {
        return checkableValue.filter(item => item.isSelected).map(item => item.value)
    }

    const handleContinue = () => {
        setQuery({ actives: getSelectedItems() })
        onFinish(getSelectedItems())
    }

    const handleOnChange = useCallback((value, isSelected) => {
        const newList = [...checkableValue]
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].value.id === value.id) {
                newList[i].isSelected = isSelected
                break;
            }
        }

        setCheckableValue(newList)
    }, [checkableValue, setCheckableValue])

    return (
        <BaseLayout hasLogo={true}>
            <QueryContainer>
                <div>
                    <Title style={{position: 'relative', }}>
                        CLASSES DE ATIVOS 
                        <TemporaryDrawer />
                    </Title>

                    <ListContainer>
                        <CheckableList
                            values={checkableValue}
                            onValueChange={handleOnChange}
                            getElementKey={(value) => value.id}
                            getElementLabel={(value) => value.name}
                        />
                    </ListContainer>
                </div>

                <Space pixels={16} direction={'v'}/>

                {getSelectedItems().length > 10 &&
                    <Alert variant={"outlined"} severity="error">Limite máximo de classes selecionadas</Alert>}

                <Space pixels={16} direction={'v'}/>

                <SpacedIconsContainer>
                    <CircleButton onClick={onBack}><ArrowBack/></CircleButton>
                    <CircleButton
                        disabled={!(getSelectedItems().length > 0 && getSelectedItems().length <= 10)}
                        onClick={handleContinue}>
                        <ArrowForward/>
                    </CircleButton>
                </SpacedIconsContainer>
            </QueryContainer>
        </BaseLayout>
    )
}