import React from 'react';

type Props = {
    label?: string
    symbol: any
}

const Emoji: React.FC<Props> = ({ label, symbol }) => {
    return (
        <span role="img" aria-label={label}>
            {symbol}
        </span>
    )
}

export default Emoji
