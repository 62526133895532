import React, { useState } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@material-ui/core"
import styled from "styled-components"

export type FilterType = 'name'|'email'
export type PlanFilterType = 'all'|'yes'|'no'

export type Props = {
    onFilter: (typeFilter: FilterType, planFilter: PlanFilterType, filter: string) => void;
}

const MainContent = styled.div`
  width: 100%;
`

export const UserForm: React.FC<Props> = ({ onFilter }) => {
    const [filter, setFilter] = useState<string>('')
    const [typeFilter, setTypeFilter] = useState<FilterType>('name')
    const [planFilter, setPlanFilter] = useState<PlanFilterType>('all')

    const onSelectedFilterType = (event: SelectChangeEvent) => {
        const filterType = event.target.value as FilterType
        setTypeFilter(filterType)
        onFilter(filterType, planFilter, filter)
    }

    const onSelectedPlanType = (event: SelectChangeEvent) => {
        const planFilter = event.target.value as PlanFilterType
        setPlanFilter(planFilter)
        onFilter(typeFilter, planFilter, filter)
    }

    return (
        <MainContent>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <TextField
                        fullWidth={true}
                        id="outlined-basic"
                        label="Buscar..."
                        variant="outlined"
                        value={filter}
                        onChange={(e) => {
                            onFilter(typeFilter, planFilter, e.target.value)
                            setFilter(e.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth>
                        <InputLabel id="simple-select-label-type">Tipo</InputLabel>
                        <Select
                            label="Tipo"
                            id="demo-simple-select"
                            labelId="simple-select-label-type"
                            value={typeFilter}
                            defaultValue={'name'}
                            onChange={onSelectedFilterType}>
                            <MenuItem value='name'>Nome</MenuItem>
                            <MenuItem value='email'>Email</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth>
                        <InputLabel id="select-with-plan-label">Apenas com plano?</InputLabel>
                        <Select
                            label="Apenas com plano?"
                            id="select-with-plan"
                            labelId="select-with-plan-label"
                            value={planFilter}
                            defaultValue={'all'}
                            onChange={onSelectedPlanType}>
                            <MenuItem value='all'>Todos</MenuItem>
                            <MenuItem value='yes'>Sim</MenuItem>
                            <MenuItem value='no'>Não</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </MainContent>
    )
}
