import { createContext, FC, useContext, useEffect, useState } from "react";
import { IDecodedToken, tokenToUser } from "../services/userService";
import { isEqual } from 'lodash'

type AuthContextProps = {
  readonly user: IDecodedToken,
  setUser: (user: IDecodedToken) => void,
  hasUser: () => boolean,
  isAdm: () => boolean
}

const AuthContext = createContext<AuthContextProps>({
  user: {} as IDecodedToken,
  setUser: () => {},
  hasUser: () => false,
  isAdm: () => false
})

export const AuthProvider: FC = ({children}) => {

  const [user, setUser] = useState<IDecodedToken>({} as IDecodedToken)
  useEffect(() => setUser(tokenToUser()), [])

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
      hasUser: () => !!user && !isEqual(user, {}),
      isAdm: () => (!!user && !isEqual(user, {}) && user.role === '1')
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)