import styled from "styled-components";
import palette from "../../../../theme/palette";
import { Box } from "@material-ui/core";

export const Background = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  background: ${palette.primary.lighter};
  border-radius: 2px;
  margin-top: 10px;
  color: ${palette.secondary.main};
`

export const InfoIcon = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  background: ${palette.primary.main};
  margin-right: 12px;
  cursor: pointer;
`
