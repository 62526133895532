import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from '@iconify-icons/mdi/menu'
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton } from "@material-ui/core";
// components
import { MHidden } from "../../components/@material-extend";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  backdropFilter: "blur(6px)",
  backgroundColor: "#fff",
  boxShadow: '0px 3px 9px rgba(0, 0, 0, .05)',
  [theme.breakpoints.up("lg")]: {
    width: `100%`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }: any) {
  return (
    <RootStyle>
      <MHidden width="lgUp">
        <ToolbarStyle>
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: "text.primary" }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </ToolbarStyle>
      </MHidden>
    </RootStyle>
  );
}
