import React from "react";
import { Content } from "./base.layout.styled"
import styled from "styled-components";
import { Loading } from "../../components/loading";
import { LogoLayout } from "./logo.layout";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 98%;
`

type LoadingLayoutProps = {
    loading: boolean
    hasLogo?: boolean
}

export const LoadingLayout: React.FC<LoadingLayoutProps> = ({ children, loading, hasLogo }) => {
    return (
        <Main>
            {hasLogo && <LogoLayout/>}

            <Loading open={loading}/>

            <Content>{children}</Content>
        </Main>
    );
}

