import styled from "styled-components";
import palette from "../../../../theme/palette";
import { Card, TextField } from "@material-ui/core";

export const Container = styled(Card)`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  padding: 16px;
  background: ${palette.primary.main};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`

export const Column = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;

  width: 100%;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const Field = styled(TextField)`
  width: 100%;
  border-radius: 2px;
  margin-top: 16px;
  background-color: white;
  border: none !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .07);

  & .MuiOutlinedInput-notchedOutline {
    border: none;
    outline: none;
  }
`