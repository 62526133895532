import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {matchPath, NavLink as RouterLink, useLocation, useNavigate} from "react-router-dom";
// material
import {experimentalStyled as styled} from "@material-ui/core/styles";
import {Box, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import palette from "../theme/palette";
import {useAuth} from "../contexts/auth2";
import { MessageLockedResourceDialog, MessagePayedResourceDialog } from "./message-dialog";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props: any) => <ListItem button disableGutters {...props} />)(({theme}) => ({
    ...theme.typography.body2,
    height: 48,
    position: "relative",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: "#fff",
    "&:before": {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: "''",
        display: "none",
        position: "absolute",
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: palette.primary.main,
    },
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object,
    active: PropTypes.func,
};

type ModalState = {
    payedResource: boolean
    lockedResource: boolean
}

function NavItem({item, active}: any) {

    const {user, isAdm} = useAuth()
    const navigate = useNavigate()
    const isActiveRoot = active(item.path);
    const {title, path, icon, info, children} = item;
    const [modalState, setModalState] = useState<ModalState>({ payedResource: false, lockedResource: false })

    let plan = 'Free'
    if (user.plan != null) {
        plan = 'Paid'
    }

    const commonRoutes: string[] = [
        '/dashboard/app',
        '/dashboard/profile',
        '/dashboard/pricing',
        '/dashboard/tutorial',
        '/dashboard/suport'
    ]

    const isUserAllowedToEnterURL = useCallback((url: string) => {
        const URLsDictionary: any = {
            Free: [
                '/dashboard/app',
                '/dashboard/profile',
                '/dashboard/pricing',
                '/dashboard/tutorial',
                '/dashboard/suport'
            ],
            Paid: [
                '/dashboard/app',
                '/dashboard/profile',
                '/dashboard/pricing',
                '/dashboard/suport',
                '/dashboard/consult',
                '/dashboard/query',
                '/dashboard/query/definitions',
                '/dashboard/query/actives',
                '/dashboard/query/subclass',
                '/dashboard/query/products',
                '/dashboard/query/dose',
                '/dashboard/history',
                '/dashboard/dose',
                '/dashboard/actives',
                '/dashboard/tutorial',
            ]
        }

        const allowedURLs = URLsDictionary[plan] ? URLsDictionary[plan] : []

        return allowedURLs.includes(url)
    }, [plan])

    const activeRootStyle = {
        color: palette.primary.main,
        fontWeight: "fontWeightMedium",
        bgcolor: "#F3F4FF",
        "&:hover": {
            bgcolor: "#F3F4FF",
        },
    };

    if (children) {
        return (<></>);
    }

    const handleClick = () => {
        if (isUserAllowedToEnterURL(path)) {
            if (user.unlocked || commonRoutes.includes(path)) {
                navigate(path)
            } else {
                setModalState({ ...modalState, lockedResource: true })
            }
        } else {
            setModalState({ ...modalState, payedResource: true })
        }
    }

    return (
        <>
            <MessagePayedResourceDialog
                open={modalState.payedResource}
                onClose={() => setModalState({ ...modalState, payedResource: false })}
            />

            <MessageLockedResourceDialog
                open={modalState.lockedResource}
                onClose={() => setModalState({ ...modalState, lockedResource: false })}
            />

            <ListItemStyle {...(isAdm() ? { component: RouterLink, to: path } : { onClick: () => handleClick() })}
                           sx={{ ...(isActiveRoot && activeRootStyle), }}>
                <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                <ListItemText disableTypography primary={title}/>
                {info && info}
            </ListItemStyle>
        </>
    );
}

NavSection.propTypes = {
    navConfig: PropTypes.array,
};

export default function NavSection({navConfig}: any) {
    const {pathname} = useLocation();
    const match = (path: any) => (path ? !!matchPath({path, end: false}, pathname) : false);

    return (
        <Box sx={{backgroundColor: palette.primary.main}}>
            <List disablePadding>
                {navConfig.map((item: any) => (
                    <NavItem key={item.title} item={item} active={match}/>
                ))}
            </List>
        </Box>
    );
}
