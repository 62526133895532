import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { ActiveResponse } from "../../../services/decisionFlow";

type Props = {
    open: boolean
    onClose: () => void
    active?: ActiveResponse
    onConfirm: (name: string, id?: number) => void
}

export const NewActive: React.FC<Props> = ({ open, onClose, onConfirm, active }) => {
    const [state, setState] = useState(active?.name || '')

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Ativo"
                    variant="outlined"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    sx={{ marginTop: "8px", width: '100%', minWidth: '320px' }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={() => onConfirm(state || '', active?.id)}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}
