import React, {useState} from 'react'
import { Alert, MenuItem, Switch, TextField } from "@material-ui/core";
import {ItemBox, MainBox} from "./definitions.styled";
import {Title} from "../../../../theme/globalStyles"
import {getQuery, setQuery} from "../../../../services/local-session-storage";
import {QueryContainer} from "../index.styled";
import Periods from "../../../../utils/periods";
import {CircleButton, SpacedIconsContainer} from "../../../../styles/styles";
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import Space from "../../../../components/Space";
import {BaseLayout} from "../../../../layouts/base/base.layout";
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import {Dialog, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import palette from '../../../../theme/palette';
import CloseIcon from '@mui/icons-material/Close';

type DefinitionsModel = {
    vegan: boolean
    period: string
    organic: boolean
    pregnant: boolean
    activeList: boolean
}

type Props = {
    onFinish: () => void
    onBack: () => void
}

export const Definitions: React.FC<Props> = ({ onFinish, onBack }) => {

    const model = getQuery()
    const [state, setState] = useState<DefinitionsModel>({
        vegan: model.vegan,
        organic: model.organic,
        pregnant: model.pregnant,
        period: model.period || Periods[0],
        activeList: model.activeList,
    })
    const [openActive, setOpenActive] = useState(false)
    const [openInfo, setOpenInfo] = useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            period: event.target.value
        });
    }

    const handleContinue = () => {
        setQuery(state)
        onFinish()
    }

    return (
        <BaseLayout hasLogo={true}>
            <QueryContainer>
                <div>

                    <Title>DEFINIÇÕES DA CONSULTA
                        <IconButton onClick={() => { setOpenInfo(true) }} style={{ color: palette.primary.main, marginBottom: '1px' }}>
                            <InfoIcon/>
                        </IconButton>
                    </Title>

                    <Space pixels={16} direction={'v'} />

                    <MainBox>
                        <ItemBox>
                            <p>{"A paciente é gestante?"}</p>
                            <Switch checked={state.pregnant} disabled={!model.canEdit} onChange={handleChange} name={"pregnant"}/>
                        </ItemBox>
                    </MainBox>

                    <MainBox>
                        <ItemBox>
                            <p>{"Buscar somente produtos veganos/cruelty free?"}</p>
                            <Switch checked={state.vegan} disabled={!model.canEdit} onChange={handleChange} name={"vegan"}/>
                        </ItemBox>
                    </MainBox>

                    <MainBox>
                        <ItemBox>
                            <p>{"Buscar somente produtos orgânicos?"}</p>
                            <Switch checked={state.organic} disabled={!model.canEdit} onChange={handleChange} name={"organic"}/>
                        </ItemBox>
                    </MainBox>

                    <MainBox>
                        <ItemBox>
                            <p>{"Já sei quais ativos quero usar!"}
                                <IconButton onClick={() => { setOpenActive(true) }} style={{ color: palette.primary.main, }}>
                                    <InfoIcon/>
                                </IconButton>
                            </p>
                            <div>
                                <Dialog open={openActive}
                                    onClose={() => { setOpenActive(false) }}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <DialogTitle
                                        sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', }}
                                        id="alert-dialog-title">
                                        {"Troque a busca por classes pela busca por ativos."}
                                        <IconButton
                                            onClick={() => { setOpenActive(false) }}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                        Selecione essa opção caso já tenha em mente quais ativos precisa usar para essa formulação.
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                            </div>
                            <Dialog open={openInfo}
                                    onClose={() => { setOpenInfo(false) }}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <DialogTitle
                                        sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', }}
                                        id="alert-dialog-title">
                                        {"Informação sobre os botões."}
                                        <IconButton
                                            onClick={() => { setOpenInfo(false) }}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                        Ative os botões para a direita para ativar os filtros de paciente gestante, produtos veganos/organicos/cruelty free. Somente ativos filtrados irão aparecer na sua consulta.
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                            <Switch checked={state.activeList} onChange={handleChange} name={"activeList"}/>
                        </ItemBox>
                    </MainBox>

                    <Space pixels={16} direction={'v'}/>

                    <TextField
                        select
                        fullWidth
                        name={'period'}
                        label="Período"
                        value={state.period}
                        disabled={!model.canEdit}
                        onChange={handleSelect}>
                        {Periods.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {
                    !model.canEdit &&
                    <Alert severity="warning" variant="outlined">As definições da consulta não podem ser alteradas na
                        edição do histórico!</Alert>
                }

                <Space pixels={16} direction={'v'}/>

                <SpacedIconsContainer>
                    <CircleButton onClick={onBack}><ArrowBack/></CircleButton>
                    <CircleButton onClick={handleContinue}><ArrowForward/></CircleButton>
                </SpacedIconsContainer>
            </QueryContainer>
        </BaseLayout>
    )
}
