import React from "react";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { Chip, IconButton, TablePagination } from "@material-ui/core";
import { DcoModel } from "./dco.model";
import { Delete } from "@material-ui/icons";

interface Column {
    id: 'email'|'plan'|'tag'|'actions';
    label: string;
    minWidth?: number;
    align?: 'right';
}

const columns: readonly Column[] = [
    { id: 'email', label: 'Email', minWidth: 150 },
    { id: 'plan', label: 'Plano', minWidth: 100 },
    { id: 'tag', label: 'Tag', minWidth: 100 },
    { id: 'actions', label: 'Ações', minWidth: 100 },
];

type Props = {
    page: number
    size: number
    total: number
    data: DcoModel[]
    onDelete: (id: number) => void
    onPageChange: (newPage: number, size: number) => void
    onSizeChange: (newSize: number, page: number) => void
}

export const DcoTable: React.FC<Props> = ({
   data, page, size, total,
   onDelete, onPageChange, onSizeChange,
}) => {

    const handleChangePage = (event: unknown, newPage: number) => {
        onPageChange(newPage + 1, size)
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSizeChange(+event.target.value, 0);
    };

    const tag = (model: DcoModel) => {
        return (
            <>
                {model.tag && <Chip label={model.tag} variant="outlined"/>}
            </>
        )
    }

    const createRows = (column: Column, model: DcoModel) => {
        const value = model[column.id]
        return (
            <TableCell key={column.id} align={column.align}>
                {
                    column.id === 'actions' ? actions(model) :
                        column.id === 'tag' ? tag(model) :
                            value
                }
            </TableCell>
        )
    }

    const actions = (model: DcoModel) => {
        return (
            <IconButton onClick={() => onDelete(model.id)}>
                <Delete color={"primary"}/>
            </IconButton>
        )
    }

    const tableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}>
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    const tableBody = () => {
        return (
            <TableBody>
                {
                    data.map((model) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={model.email}>
                                {columns.map((column) => createRows(column, model))}
                            </TableRow>
                        );
                    })}
            </TableBody>
        )
    }

    return (
        <Paper>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="active table">
                    {tableHeader()}
                    {tableBody()}
                </Table>
            </TableContainer>

            <TablePagination
                page={page - 1}
                component="div"
                count={total}
                rowsPerPage={size}
                rowsPerPageOptions={[5, 10, 25]}
                onPageChange={handleChangePage}
                labelRowsPerPage={"Linhas por página"}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
        </Paper>
    );
}