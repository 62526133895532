import React, { useCallback, useMemo, useState } from "react";
import { TextField } from "@material-ui/core";
import { SxProps } from "@material-ui/system";
import { Theme } from "@material-ui/core/styles";

export interface DecimalAdapterProps {
    label: string
    value?: number
    sx?: SxProps<Theme>
    onChange: (value: number) => void
}

class DecimalParser implements DecimalParser {

    applyMask(value: string): string {
        if (!value) return ''

        let newValue = value.replace(/\D/g, '');
        newValue = newValue.replace(/(\d{1,2})$/, ',$1');
        newValue = newValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

        return `R$ ${newValue}`
    }

    format(value: string | number | null): string {
        if (!value) return ''
        return value.toString().replace(".", ",")
    }

    parse(value: string): number | null {
        let newValue = value.replace(/\D/g, '');
        newValue = newValue.replace(",", ".")
        return Number(newValue);
    }
}

const CurrencyTextField: React.FC<DecimalAdapterProps> = ({ label, value, sx, onChange }) => {
    const decimalParser = useMemo(() => new DecimalParser(), [])
    const [toFormat, setToFormat] = useState<number | null>(value || null)
    const [fieldValue, setFieldValue] = useState<string>(decimalParser.format(toFormat))

    const handleValueChange = useCallback((newValue: string) => {
        const maskedValue = decimalParser.applyMask(newValue)
        setFieldValue(maskedValue)

        if (maskedValue.length) {
            const value = decimalParser.parse(maskedValue)
            setToFormat(value)
        } else {
            setToFormat(null)
        }

        const parsed = decimalParser.parse(maskedValue) || 0
        onChange((parsed / 100))
    }, [decimalParser, setFieldValue, setToFormat])

    return (
        <TextField
            sx={sx}
            id={`form_${label}`}
            label={label}
            value={fieldValue || ''}
            onChange={event => handleValueChange(event.target.value || "")}
        />
    )
};

export default CurrencyTextField;
