import jwt_decode from "jwt-decode"
import http from './http-common'
import {AxiosResponse} from 'axios'
import { FilterType } from "../pages/adm/users/form/user-form";

export interface IDecodedToken {
    exp: number,
    iat: number,
    idUser: number,
    role: string,
    plan: string | null,
    unlocked: number,
    cancelled: number,
    email: string,
    planId: number | null | undefined
}

export const updateUserToken = (token: string) => localStorage.setItem('token', token)

export const getUserToken = () => localStorage.getItem('token')

export const tokenToUser = (): IDecodedToken => {
    const token = getUserToken()
    if (!!token) {
        return jwt_decode(token) as IDecodedToken
    }
    return {} as IDecodedToken
}

export const removeUser = () => {
    localStorage.removeItem('token')
}

export const getPlanType = (userPlan: string | null | undefined): string => {
    return userPlan == null ? 'free' : userPlan;
}

export const isFreePlan = (userPlan: string | null | undefined) => {
    return getPlanType(userPlan) === 'free'
}

export const getRanking = (idUser: number) => http.get(`/user/${idUser}/ranking`)

export const refreshToken = (token: string) => http.post<any, AxiosResponse<{ token: string }>>('/user/refresh/token', {token})

export const allowNewUser = () => http.get('/user/allow')

export type UserFilter = {
    type?: FilterType
    query?: string
    withPlan?: boolean
    page: number
    size: number
}

export const getPaginatedUsers = (filter: UserFilter) => {
    let url = `/users?size=${filter.size}&page=${filter.page}`
    if (filter.query !== undefined) {
        url = url + `&search=${filter.query}`
        if (filter.type !== undefined) url = url + `&filterType=${filter.type}`
        if (filter.withPlan !== undefined) url = url + `&withPlan=${filter.withPlan}`
    }
    return http.get(url)
}