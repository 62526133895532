import React, { useRef } from 'react'
import { Box, Grid, TextField, useMediaQuery } from "@material-ui/core"
import palette from "../../../../theme/palette";
import styled from "styled-components";

interface OTPInputProps {
    value: string
    onChange: (value: string) => void
}

const Input = styled(TextField)`
  background: ${palette.primary.lighter};
  width: 3rem;
  border-radius: 8px;
`

export const OTPInput: React.FC<OTPInputProps> = ({ value, onChange }) => {
    const isDesktop = useMediaQuery('(min-width: 960px)')
    const inputRefs = [
        useRef<HTMLInputElement|null>(null),
        useRef<HTMLInputElement|null>(null),
        useRef<HTMLInputElement|null>(null),
        useRef<HTMLInputElement|null>(null),
        useRef<HTMLInputElement|null>(null),
        useRef<HTMLInputElement|null>(null),
    ]

    const handleInputChange = (index: number, inputValue: string) => {
        const updatedValue = value.slice(0, index) + inputValue + value.slice(index + 1);
        onChange(updatedValue);

        if (inputValue && index < 5) {
            setTimeout(() => {
                inputRefs[index + 1]?.current?.focus();
            }, 0);
        } else if (!inputValue && index > 0) {
            setTimeout(() => {
                inputRefs[index - 1]?.current?.focus();
            }, 0);
        }
    };

    const handleInputPaste = (index: number, e: React.ClipboardEvent<HTMLDivElement>) => {
        const clipboardData = e.clipboardData.getData('text')

        if (/^[a-zA-Z0-9]{6}$/.test(clipboardData)) {
            const updatedValue = value.slice(0, index) + clipboardData + value.slice(index + clipboardData.length)
            onChange(updatedValue)

            if (index < 5) {
                setTimeout(() => {
                    inputRefs[clipboardData.length - 1]?.current?.focus();
                }, 0)
            }
        }
    }

    return (
        <Box display="flex" gap={isDesktop ? '20px' : '5px'} alignItems="center">
            {Array(6)
                .fill(null)
                .map((_, index) => (
                    <Grid item key={index}>
                        <Input
                            fullWidth
                            color='primary'
                            variant="outlined"
                            value={value[index] || ''}
                            inputRef={inputRefs[index]}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            onPaste={(e) => handleInputPaste(index, e)}
                            inputProps={{
                                maxLength: 1,
                                style: {
                                    textAlign: 'center',
                                },
                            }}
                        />
                    </Grid>
                ))}
        </Box>
    )
}
