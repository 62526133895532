import http from './http-common'
import PlanModel from "../models/plan.model";

export const getPlans = () => http.get(`/plans`)
export const newPlan = (plan: PlanModel) => http.post(`/plans`, plan)

export const enablePlan = (id?: number) => http.put(`/plans/${id}/enable`)
export const disablePlan = (id?: number) => http.put(`/plans/${id}/disable`)

export const editPlan = (id: number, plan: PlanModel) => http.put(`/plans/${id}`, plan)

export const getActivesPlans = () => http.get(`/plans/actives`)
export const getPlanById = (id: number | null | undefined) => http.get(`/plans/${id}`)
