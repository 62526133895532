import React from "react";
import {Alert, Button, Divider, Link, Stack, Typography} from "@material-ui/core";
import palette from "../../../theme/palette";
import styled from "styled-components";
import PlanModel from "../../../models/plan.model";
import {RibbonComponent}  from "../../../components/Ribbon/ribbon";
import CheckIcon from '@mui/icons-material/Check';

const Item = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  color: ${palette.primary.dark};
  background: ${palette.primary.lighter};
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
  
  @media screen and (min-width: 768px){
    padding: 15px;
  }

  @media screen and (max-height: 768px) {
    min-height: 375px;
    align-item: center;
    padding: 15px;
  }
`

const Label = styled(Typography)`
  font-weight: 400;
  font-size: 1.125rem;
  margin-top: 0 !important;
`

const TitleLabel = styled(Label)`
  font-weight: 400;
  font-size: 2rem;
  margin-top: 0 !important;
  text-align: center;
`

const PriceLabel = styled(Label)`
  color: #627217;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 0 !important;
`

export const AlertItem = styled(Alert)`
  background: #FF00FF00;
`

const List = styled.li`
  marginLeft: 16px; 
  display: flex; 
  alignItems: flex-start;
`
const Line = styled.hr`
  width:258px;
  color: black;
  margin-bottom: 10px;
  overflow-x: hidden;
`

type PlanItemProps = {
    plan: PlanModel
    dueDatePlan: number
    userHasPlan: boolean
    isActualPlan: boolean
    planInCheck: boolean
    onPlanSelected?: () => void,
    onCancelPlan?: () => void,
    isPreapproval: boolean,
    isPlanCancelled: boolean,
    isPlanPreapproval: boolean
}

export const PlanItem: React.FC<PlanItemProps> = ({ plan, userHasPlan, isActualPlan, planInCheck, dueDatePlan, onPlanSelected, onCancelPlan, isPreapproval, isPlanCancelled, isPlanPreapproval }) => {
    const getMonth = (value?: number) => {
        if (value) return Math.floor(value / 30)
        else return 1
    }

    const formatCurrency = (value: number) => {
        return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }

    const getTotalOfPlan = () => {
        return plan.value / getMonth(plan.daysOfUse)
    }

    return (
        <RibbonComponent text={"Plano Atual"} show={isActualPlan}>
            <Item key={plan.name} spacing={2}>

                <TitleLabel variant="h3" >{plan.name}</TitleLabel>

                <Divider style={{width: '30px'}}/>

                <PriceLabel variant={"h4"}>{formatCurrency(getTotalOfPlan())} por mês</PriceLabel>
                <Label variant={"h6"}>Total de {formatCurrency(plan.value)}</Label>

                {!userHasPlan && <Button
                    fullWidth={true}
                    style={{ backgroundColor: "#627217" }}
                    variant="contained"
                    onClick={() => onPlanSelected?.()}>
                    Assinar plano!
                </Button>}

                {isActualPlan && (dueDatePlan >= 0) && !planInCheck
                    && (
                        <React.Fragment>
                            {isPreapproval && !isPlanCancelled && <Button size={"large"}
                                fullWidth={true}
                                color={"primary"}
                                variant="contained"
                                onClick={() => onPlanSelected?.()}>ALTERAR CARTÃO </Button>}
                        </React.Fragment>
                    )
                }

                    <Line style={{width:'80%'}}/>
                {
                    plan.features && plan.features.length > 0 && <AlertItem sx={{ width: '100%' }} severity="success" variant="standard" icon={false}>
                        <ul style={{ textDecoration: 'none',  }}>
                            {
                                plan.features.map(value => {
                                    return <List><CheckIcon sx={{ color: 'black', marginRight: '8px' }}></CheckIcon>{value}</List>
                                })
                            }
                        </ul>
                    </AlertItem>
                }

                {
                    isActualPlan && (dueDatePlan >= 0) && !planInCheck
                    && (
                        <React.Fragment>
                            <AlertItem severity="warning" variant="outlined">Seu plano expira
                                em {dueDatePlan} dias!</AlertItem>
                            {isPreapproval && !isPlanCancelled && <Link component="button"
                                variant="body2"
                                sx={{ color: '#be1704', }}
                                onClick={() => onCancelPlan?.()}>CANCELAR ASSINATURA</Link>}
                        </React.Fragment>
                    )
                }

                {
                    isActualPlan && planInCheck
                    && <AlertItem sx={{ width: '100%' }} severity="error" variant="outlined">Aguardando aprovação!</AlertItem>
                }
            </Item>

        </RibbonComponent>
    )
}
