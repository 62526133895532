import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Autocomplete, Box, TextField } from "@material-ui/core";
import palette from "../../../../theme/palette";
import QueryFormStyles from "./queryFormStyles";
import { handlePromise } from "../../../../utils/handlePromisse";
import { Patient as PatientModel , getPatients } from "../../../../services/patient.service";
import { Instability } from "../../../../components/instability";

type QueryFormProps = {
    reloadPatients: boolean
    patientSelected: (id: number) => void
}

const QueryForm: React.FC<QueryFormProps> = ({ reloadPatients, patientSelected }) => {
    const [selected, setSelected] = useState<PatientModel | null>(null)
    const [patients, setPatients] = useState<PatientModel[]>([])

    const fetchPatients = useCallback(async () => {
        const [result, error] = await handlePromise(getPatients())
        setPatients(result.data.data as PatientModel[])
    }, [])

    useEffect(() => {
        fetchPatients()
    }, [fetchPatients, reloadPatients])

    const autoCompleteStyle = {
        width: "100%",
        background: palette.primary.lighter,
    };
    return (
        <QueryFormStyles>
            <Box>
                <Autocomplete
                    options={patients}
                    style={autoCompleteStyle}
                    limitTags={1}
                    onChange={(_, value) => {
                        setSelected(value)
                        if (value) patientSelected(value.id)
                    }}
                    getOptionLabel={(option) => (option.name ? option.name : '')}
                    value={selected}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        )
                    }}
                    renderInput={
                        (params) => <TextField
                            {...params}
                            label="&#128269; BUSCA DE PACIENTES CADASTRADOS"
                            variant="outlined"
                            color="primary"
                        />
                    }
                />
                <Alert className="alert-info" severity="info">Quer adicionar uma nova consulta no histórico de um
                    paciente já cadastrado? Digite o nome do paciente e, após selecionado, inicie a consulta. Caso não
                    queira salvar sua consulta no histórico, inicie uma consulta sem selecionar um paciente.</Alert>
                <Instability/>
            </Box>
        </QueryFormStyles>
    )
}

export default QueryForm;