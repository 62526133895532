import { QueryModel } from "../pages/main/query/query.model";

const queryModel = 'query-model'
const notifyFormula = 'notify-formula'

export const getQuery = () => {
    const json = sessionStorage.getItem(queryModel);
    return JSON.parse(json || '{}') as QueryModel
}

export const setQuery = (newState: any) => {
    const updated = {
        ...getQuery(),
        ...newState
    } as QueryModel
    sessionStorage.setItem(queryModel, JSON.stringify(updated))
}

export const clearQuery = () => {
    sessionStorage.setItem(queryModel, "")
}

export const setNotifyFormula = (notify: boolean) => {
    sessionStorage.setItem(notifyFormula, notify ? 'true' : 'false')
}

export const getNotifyFormula = () => {
    const notify = sessionStorage.getItem(notifyFormula)
    return notify === 'true'
}
