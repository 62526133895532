import React, { useCallback, useEffect, useState } from "react";
import { BaseLayout } from "../../../layouts/base/base.layout";
import { SubclassTable } from "./subclass-table";
import { ActiveResponse, disableActive, disableSubclass, editActive, editSubclass, enableActive, enableSubclass, getSubclassPaginated, newActive, newSubclass, SubClassModel } from "../../../services/decisionFlow";
import { initialPagination, PaginatedResponse } from "../../../domain/models/paginated-response.model";
import { NewSubclass } from "./new-subclass";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { Title } from "../../../theme/globalStyles";
import { AdmLayout } from "../../../layouts/base/adm.layout";

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 16px;
`

type State = {
    open: boolean
    subclass?: SubClassModel
}

export const ManageSubclass: React.FC = () => {
    const [state, setState] = useState<State>({ open: false })
    const [list, setList] = useState<PaginatedResponse<SubClassModel>>(initialPagination)

    const fetchList = useCallback(async (newPage: number = 0, newSize: number = 10) => {
        const result = await getSubclassPaginated(newPage + 1, newSize)
        const response = result.data as PaginatedResponse<SubClassModel>;

        setList(response)
    }, [setList, getSubclassPaginated])

    useEffect(() => {
        fetchList(0, 10)
    }, [fetchList])

    const handlePageChange = useCallback(async (newPage: number, size: number) => {
        await fetchList(newPage, size)
    }, [fetchList])

    const handleSizeChange = useCallback(async (newSize: number, page: number) => {
        await fetchList(page, newSize)
    }, [fetchList])

    const handleEnableActive = useCallback(async (active: ActiveResponse) => {
        if (active.enabled) {
            await disableSubclass(active.id)
            await fetchList()
        } else {
            await enableSubclass(active.id)
            await fetchList()
        }
    }, [disableSubclass, enableSubclass, fetchList])

    const handleNewActive = useCallback(async (name: string, id?: number) => {
        if (id) {
            await editSubclass(id, name)
        } else {
            await newSubclass(name)
        }
        await fetchList()
        setState({ open: false })
    }, [setState, editActive, newActive, fetchList])

    return (
        <AdmLayout loading={false} hasLogo={true}>
            {state.open && <NewSubclass
                open={state.open}
                subclass={state.subclass}
                onClose={() => setState({ open: false })}
                onConfirm={(name, id) => handleNewActive(name, id)}/>}

            <Title>Gestão de Subclasses</Title>
            <SubclassTable
                data={list.data}
                onPageChange={handlePageChange}
                onSizeChange={handleSizeChange}
                total={list.pagination.total}
                size={list.pagination.perPage}
                page={list.pagination.currentPage}
                switchSubclass={(subclass) => handleEnableActive(subclass)}
                onEditItem={(subclass) => {
                    setState({
                        subclass: { ...subclass },
                        open: true,
                    })
                }}
            />
            <ActionContainer>
                <Button variant="contained" onClick={() => setState({ open: true })}>Nova sub classe</Button>
            </ActionContainer>
        </AdmLayout>
    )
}
