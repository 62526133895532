import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core"
import styled from "styled-components"
import Space from "../../../../components/Space"
import PlanModel from "../../../../models/plan.model"
import { handlePromise } from "../../../../utils/handlePromisse"
import { getPlans } from "../../../../services/plan-service"
import { DcoEmailsRequest } from "../../../../services/dco-emails/dco-emails.service"

type Props = {
    tags: string[]
    onDeleteTag: (tag: string) => void
    onAdded: (gifts: DcoEmailsRequest[]) => void
}

const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const DcoForm: React.FC<Props> = ({ tags, onAdded, onDeleteTag }) => {
    const [tag, setTag] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [selectedEmails, setSelectedEmails] = useState<string[]>([])

    const [plan, setPlan] = useState('')
    const [plans, setPlans] = useState<PlanModel[]>([])

    const disabled = !(selectedEmails.length > 0 && !!plan && tag.length > 0)

    const fetchPlans = useCallback(async () => {
        const [response, error] = await handlePromise(getPlans())
        if (!!error) {
            console.log('error', error, error.stack)
            return
        }
        setPlans(response.data as PlanModel[])
    }, [selectedEmails])

    useEffect(() => {
        fetchPlans()
    }, [])

    const handleAddEmail = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && email && !selectedEmails.includes(email)) {
            event.preventDefault()

            const newEmail = email.split(/,|\s+|\n/)
            if (newEmail.length > 0) {
                const filtered = newEmail.filter((e) => !selectedEmails.includes(e.trim()))
                setSelectedEmails((emails) => [...emails, ...filtered])
            } else {
                setSelectedEmails((emails) => [...emails, email.trim()])
            }

            setEmail('')
        }
    }, [selectedEmails, email, setEmail])

    const handleRemoveEmail = useCallback((email: string) => {
        setSelectedEmails((emails) => emails.filter((e) => e !== email))
    }, [])

    const handleSend = useCallback(() => {
        const emails = selectedEmails.map((email) => {
            return {
                email: email,
                plan: parseInt(plan),
                tag: tag
            } as DcoEmailsRequest
        })

        onAdded(emails)

        setSelectedEmails([])
        setPlan('')
        setTag('')
    }, [selectedEmails, plan, tag, onAdded])

    const renderTags = useCallback(() => {
        return selectedEmails.map((option, index) => (
            <Chip
                key={index}
                label={option}
                color={'primary'}
                onDelete={() => handleRemoveEmail(option)}
            />
        ))
    }, [handleRemoveEmail, selectedEmails])

    return (
        <MainContent>
            <p>
                Nessa tela você poderá deixar planos específicos visíveis apenas para e-mails cadastrados, mesmo que o mesmo esteja oculto do público.
                Use esse recurso para promoções de cursos, cadastrando o e-mail dos usuários e o plano que ficará visível. Adicione tags para filtrar com facilidade um grupo de usuários.
            </p>
            <Space pixels={16} direction={'v'}/>
            <Row>
                <Autocomplete
                    multiple
                    freeSolo
                    sx={{ width: '60%' }}
                    value={selectedEmails}
                    renderTags={renderTags}
                    options={[]}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                            {...params}
                            label={'Emails'}
                            variant="outlined"
                            color="primary"
                            onKeyDown={handleAddEmail}
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{ ...params.InputProps, }}
                        />
                    )}
                />

                <Space pixels={16} direction={'h'}/>

                <FormControl sx={{ width: '20%' }}>
                    <InputLabel id="plan-select-label">Plan</InputLabel>
                    <Select
                        labelId="plan-select-label"
                        id="plan-select"
                        value={plan}
                        onChange={(event) => setPlan(event.target.value)}
                        label="Plano">
                        {
                            plans.map((plan) => {
                                return <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <Space pixels={16} direction={'h'}/>

                <TextField
                    value={tag}
                    label={'Tag'}
                    color="primary"
                    variant="outlined"
                    sx={{ width: '20%' }}
                    onChange={(e) => setTag(e.target.value)}
                />

                <Space pixels={16} direction={'h'}/>

                <Button
                    size={"large"}
                    fullWidth={true}
                    color={"primary"}
                    variant="contained"
                    onClick={handleSend}
                    sx={{ width: '20%' }}
                    disabled={disabled}>
                    Mostrar
                </Button>
            </Row>

            <Space pixels={16} direction={'v'}/>
        </MainContent>
    )
}
