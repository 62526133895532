import { TutorialModel } from "../models/TutorialModel";
import http from "./http-common";

export const saveTutorial = (Tutorial: TutorialModel) => {
    if (Tutorial.id != null) {
        return http.put(`/tutorial/${Tutorial.id}`, Tutorial)
    }
    return http.post('/tutorial', Tutorial)
}

export const getAllTutorial = () => {
    return http.get(`/tutorial`)
}

export const getById = (id: number) => {
    return http.get(`/tutorial/${id}`)
}