import { AxiosResponse } from 'axios'
import { IProfileValues } from "../pages/main/profile/Form/profileForm"
import http from "./http-common"

export interface IResponseProfile {
  "name_User": string,
  "email_User": string,
  "phone_User": string,
  "occupation_User": string,
  "address": {
    "street": string,
    "number": string,
    "complement": string,
    "zipcode": string,
    "neighbourhood": string,
    "city": string,
    "state": string
  }
}

export interface IUpdateUserAPI {
  "occupation_User"?: string,
  "name_User"?: string,
  "phone_User"?: string,
  "address"?: {
    "street"?: string,
    "number"?: string,
    "complement"?: string,
    "zipcode"?: string,
    "neighbourhood"?: string,
    "city"?: string,
    "state"?: string,
  }
}

export type UserProfileResponse = {
  "name_User": string,
  "email_User": string,
  "phone_User": string,
  "occupation_User": string,
  "planName": string,
  "amountQueries_PlansPG": number,
  "amountQueriesUsed_User": number,
  "dat_end_plan": string,
  "cod_payment": string,
  "preapproval_id": string
}

export const getUserProfile = (idUser: number) => http.get<any, AxiosResponse<UserProfileResponse>>(`/user/profile/${idUser}`)

export const putUserProfile = (idUser: number, entries: IUpdateUserAPI) => {
  return http.put(`/user/update/${idUser}`, {
    ...entries
  })
}

export const searchCEP = (cep: string) => http.get(`/integrations/viacep/${cep}`)