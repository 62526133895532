import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";

type Props = {
    open: boolean
    title: string
    message: string
    resourceId: number
    onClose: () => void
    onConfirm: (id: number) => void
}

const ConsultButton = styled(Button)`
  width: 100%;
  padding: 8px;
  margin: 8px;
  align-content: center;
`

export const ConfirmDeleteDialog: React.FC<Props> = (
    { open, resourceId, title, message, onClose, onConfirm }
) => {
    return (
        <Dialog open={open} onClose={() => onClose} sx={{ maxHeight: '80vh' }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ConsultButton
                    variant={"outlined"}
                    fullWidth={true}
                    onClick={onClose}>CANCELAR
                </ConsultButton>
                <ConsultButton
                    variant={"contained"}
                    color={"error"}
                    fullWidth={true}
                    onClick={() => onConfirm(resourceId)}>REMOVER
                </ConsultButton>
            </DialogActions>
        </Dialog>
    )
}