import React, { useCallback, useEffect, useState } from "react";
import { initialPagination, PaginatedResponse } from "../../../domain/models/paginated-response.model";
import { handlePromise } from "../../../utils/handlePromisse";
import { SimpleSnackbar } from "../../../components/snackbar";
import { Loading } from "../../../components/loading";
import Space from "../../../components/Space";
import { AdmLayout } from "../../../layouts/base/adm.layout";
import {
    allowEmail,
    DcoEmailsRequest,
    deleteEmail,
    deleteTagByEmail,
    FilterDcoEmails,
    getDcoEmails,
    getDcoTags
} from "../../../services/dco-emails/dco-emails.service";
import { DcoModel } from "./table/dco.model";
import { DcoTable } from "./table/dco-table";
import { DcoForm } from "./form/dco-form";
import { MessageDialog } from "../../../components/message-dialog";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const DcoPage: React.FC = () => {
    const [loading, setLoading] = useState(false)

    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [deleteId, setDeleteId] = useState<number|undefined>(undefined)
    const [list, setList] = useState<PaginatedResponse<DcoModel>>(initialPagination)
    const [tags, setTags] = useState<string[]>([])
    const [filter, setFilter] = useState<FilterDcoEmails>({ page: 1, size: 5 })

    const [removeTag, setRemoveTag] = useState<string>('')

    const [tagDialog, setTagDialog] =
        useState<{show: boolean, tag: string}>({ show: false, tag: '' })

    const fetchDcoEmails = useCallback(async () => {
        setLoading(true)
        const [result, error] = await handlePromise(getDcoEmails(filter))
        setLoading(false)

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setList(result.data as PaginatedResponse<DcoModel>)
    }, [filter])

    const fetchDcoTags = useCallback(async () => {
        const [result, error] = await handlePromise(getDcoTags())
        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setTags(result.data as string[])
    }, [])

    const onHandleGiftAdd = useCallback(async (request: DcoEmailsRequest[]) => {
        setLoading(true)
        const [_, error] = await handlePromise(allowEmail(request))
        setLoading(false)

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setShowSnackbar(true)
        const plural = `${request.length > 1 ? 's' : ''}`
        setSnackbarMessage(`Email${plural} adicionado${plural} com sucesso!`)
        await fetchDcoEmails()
    }, [loading, setSnackbarMessage, setShowSnackbar])

    const onDeleteEmailsByTag = async () => {
        const [_, error] = await handlePromise(deleteTagByEmail(tagDialog.tag))

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setShowSnackbar(true)
        setSnackbarMessage(`Emails removidos com sucesso!`)
        setTagDialog({ show: false, tag: '' })
        await fetchDcoEmails()
    }

    const handlePageChange = useCallback(async (newPage: number, size: number) => {
        setFilter({ ...filter, page: newPage, size: size })
    }, [])

    const handleSizeChange = useCallback(async (newSize: number, page: number) => {
        setFilter({ ...filter, page: page, size: newSize })
    }, [])

    const onDeleteEmail = async () => {
        setLoading(true)
        const [_, error] = await handlePromise(deleteEmail(deleteId || 0))
        setLoading(false)

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setRemoveTag('')
        setShowSnackbar(true)
        setSnackbarMessage(`Email removido com sucesso!`)
        setShowDeleteDialog(false)
        await fetchDcoEmails()
    }

    const handleDeleteEmail = async (id: number) => {
        setShowDeleteDialog(true)
        setDeleteId(id)
    }

    useEffect(() => {
        fetchDcoEmails()
        fetchDcoTags()
    }, [filter])

    return (
        <AdmLayout hasLogo={true} loading={loading}>
            <SimpleSnackbar
                show={showSnackbar}
                message={snackbarMessage}
                onDismiss={() => setShowSnackbar(false)}
            />

            <MessageDialog
                open={showDeleteDialog}
                message={"Tem certeza que deseja remover o Email?"}
                onConfirm={onDeleteEmail}
                confirmButtonText={"Remover"}
                cancelButtonText={"Cancelar"}
                onClose={() => {
                    setRemoveTag('')
                    setShowDeleteDialog(false)
                }}
            />

            <MessageDialog
                open={tagDialog.show}
                message={'Tem certeza que deseja remover os emails dessa Tag?'}
                onConfirm={onDeleteEmailsByTag}
                confirmButtonText={'Deletar'}
                cancelButtonText={'Cancelar'}
                onClose={() => setTagDialog({ show: false, tag: '' })}
            />

            <Loading open={loading}/>

            <DcoForm
                tags={tags}
                onAdded={onHandleGiftAdd}
                onDeleteTag={(tag) => setTagDialog({ show: true, tag: tag })}
            />

            <Space pixels={16} direction={'v'}/>

            <DcoTable
                data={list.data}
                onDelete={handleDeleteEmail}
                onPageChange={handlePageChange}
                onSizeChange={handleSizeChange}
                total={list.pagination.total}
                size={list.pagination.perPage}
                page={list.pagination.currentPage}
            />

            <Space pixels={16} direction={'v'}/>

            <Row>
                <FormControl fullWidth>
                    <InputLabel id="tag-select-label">Tag</InputLabel>
                    <Select
                        labelId="tag-select-label"
                        id="tag-select"
                        value={removeTag}
                        onChange={(event) => setRemoveTag(event.target.value)}
                        label="Tag">
                        {
                            tags.map((tag) => {
                                return <MenuItem key={tag} value={tag}>{tag}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <Space pixels={16} direction={'h'}/>

                <Button
                    size={"large"}
                    color={"error"}
                    sx={{ width: '30%' }}
                    variant="contained"
                    onClick={() => setTagDialog({ show: true, tag: removeTag })}
                    disabled={removeTag.length == 0}>
                    Remover
                </Button>

            </Row>

            <Space pixels={30} direction={'v'}/>
        </AdmLayout>
    )
}
