import React from "react";
import { Button, DialogContent, Stack } from "@material-ui/core";
import { stackFlexColumn } from "../../../../utils/MUIShortcuts";
import { ContentLabel, Label, Title } from "./product-detail.styled"
import getDescriptionPeriod from "../../../../services/periodService";
import { Star } from "@material-ui/icons";

export type Details = {
    id: number
    name?: string
    mechanism?: string
    description?: string
    ph?: string
    incompatibility?: string
    minimum?: string
    maximum?: string
    source?: string
    daytime?: number
    nighttime?: number
    vegan?: boolean
    organic?: boolean
    pregnant?: boolean
    classes?: string
    actives?: string[]
    favorite: boolean
    annotation?: string
}

type Props = {
    onClose: () => void
    detail: Details
    onFavorite?: (productId: number, isFavorite: boolean) => void
}

export const ProductDetail: React.FC<Props> = ({ detail, onClose, onFavorite}) => {
    const createLabel = (label: string, value?: string) => {
        return <>{value && <ContentLabel><Label>{label}</Label> {value}</ContentLabel>}</>
    }

    const createBooleanLabel = (label: string, value?: boolean) => {
        if (value !== undefined) {
            return createLabel(label, value ? "SIM" : "NÃO")
        }
        return <></>
    }

    return (
        <>
            <DialogContent>
                <Stack {...stackFlexColumn('center', 'flex-start')} spacing={2} sx={{ width: '100%' }}>
                    <Title>{detail.name}</Title>

                    {   
                        
                        detail.actives && <ContentLabel>CLASSES:</ContentLabel> &&
                        detail.actives.map(active => (
                            <ContentLabel key={active}> {active}</ContentLabel>
                        ))
                    }
                    {createLabel("MECANISMO DE AÇÃO:", detail.mechanism)}
                    {createLabel("DESCRIÇÃO:", detail.description)}
                    {createLabel("PH de estabilidade:", detail.ph)}
                    {createLabel("INCOMPATIBILIDADE:", detail.incompatibility ?? 'none')}
                    {createLabel("DOSAGEM USUAL:", `${detail.minimum} a ${detail.maximum}`)}
                    {createLabel("FONTE:", detail.source)}
                    {createLabel("PERÍODO:", getDescriptionPeriod(detail.daytime, detail.nighttime))}
                    {createBooleanLabel("VEGANO:", detail.vegan)}
                    {createBooleanLabel("ORGÂNICO:", detail.organic)}
                    {createBooleanLabel("PERMITIDO EM GESTANTES:", detail.pregnant)}
                    {createLabel("CLASSE:", detail.classes)}
                    {createLabel("MINHA ANOTAÇÃO:", detail.annotation)}

                    {
                        !!onFavorite && <Button
                            size={"large"}
                            fullWidth={true}
                            onClick={() => onFavorite(detail.id, detail.favorite)}
                            startIcon={detail.favorite ? <Star/> : <></>}
                            variant={detail.favorite ? "contained" : "outlined"}>
                            {detail.favorite ? "FAVORITO" : "FAVORITAR"}
                        </Button>
                    }
                    <Button
                        fullWidth={true}
                        size={"large"}
                        onClick={onClose}
                        variant="outlined">FECHAR</Button>
                </Stack>
            </DialogContent>
        </>
    )
}