import React, { FC } from 'react'
import { SxProps } from '@material-ui/system'
import { Box } from '@material-ui/core'
import { flexColumn } from '../../utils/MUIShortcuts'

const container: SxProps = {
  ...flexColumn('center', 'space-evenly'),
  width: '90%',
  maxWidth: '440px',
  background: '#fff',
  height: 'auto',
  minHeight: '20vh',
  padding: '16px',
  boxShadow: '0px 3px 9px rgba(0, 0, 0, .15)',
  borderRadius: '2px'
}

const MCBase: FC = ({children}) => {
  return (
    <Box sx={container}>
      {children}
    </Box>
  )
}

export default MCBase