import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {experimentalStyled as styled} from "@material-ui/core/styles";
import {Box, Button, Drawer, Typography} from "@material-ui/core";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import {MHidden} from "../../components/@material-extend";

import {admUserOptions, commonUserOptions} from "./SidebarConfig";
import palette from "../../theme/palette";
import {useAuth} from "../../contexts/auth2";
import {IDecodedToken} from "../../services/userService";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const {REACT_APP_ACTUAL_VERSION} = process.env;

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: any) {

  const { setUser, isAdm, user } = useAuth()
  const navigate = useNavigate();
  const [ sidebarConfig, SetSidebarConfig ] = useState<any>([])

  const logout = () => {
    localStorage.removeItem("token")
    setUser({} as IDecodedToken)
    navigate("/login", { replace: true })
  }

  useEffect(() => {
    SetSidebarConfig(isAdm() ? admUserOptions.concat(commonUserOptions) : commonUserOptions)
  }, [ isAdm, user ])

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100vh",
        backgroundColor: palette.primary.main,
        "& .simplebar-content": {
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, pt: 3, pb: 2 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
        <img style={{width: '30%', minWidth: '230px'}} src="/imgs/newLogoWhite.svg" alt="" />
        </Box>
      </Box>
      
      <NavSection navConfig={ sidebarConfig } />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ p: 2, pt: 1.5 }}>
        <Button
          fullWidth={true}
          color={"secondary"}
          variant="contained"
          onClick={logout}>
          Sair do sistema
        </Button>
      </Box>

      <Box sx={{ textAlign: "center", fontSize: "14px" }}>
        <Typography sx={{ color: "#fff", fontSize: "12px", marginBottom: "10px" }}>
          ProAtivos - {REACT_APP_ACTUAL_VERSION}
        </Typography>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
