import React, {useEffect} from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

import Page from '../../../components/Page';
import { MHidden } from '../../../components/@material-extend';
import CSS from '../../../utils/CSSShortCuts';
import { Typography } from '@material-ui/core';
import palette from '../../../theme/palette';
import LoginForm, { ILoginSocial, ILoginValues, ISocialLoginActions } from './loginForm';
import { FormikHelpers } from 'formik';
import { handlePromise } from '../../../utils/handlePromisse';
import { checkUserEmail, loginWithEmail, socialLogin, updateToken } from '../../../services/authService'
import { exists } from '../../../utils'
import NewLogo from '../../../components/NewLogo';
import { useModal } from '../../../contexts/modal';
import MCAlert from '../../../components/ModalContents/MCAlert';
import { useAuth } from '../../../contexts/auth2';
import { allowNewUser, tokenToUser } from '../../../services/userService';

const RootStyle = styled(Page)(({theme}) => ({
  minHeight: '100vh',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentlessSectionStyle = styled('div')(({theme}) => {
  return {
    ...CSS.flexColumn('center', 'center'),
    width: '100%',
    height: 'auto',
    minHeight: '100vh',
  }
})

const AsideWrapper = styled('div')(({theme}) => {
  return {
    ...CSS.flexColumn('center', 'center'),
    width: '100%',
    maxWidth: '40vw',
    height: 'auto',
    minHeight: '100vh',
    background: palette.primary.main,
    paddingTop: '100px',
    [theme.breakpoints.down('md')]: {
      ...CSS.flexColumn('center', 'flex-start'),
      maxWidth: '100vw',
      background: '#fff'
    }
  }
})

const AsideContentStyle = styled('div')(({theme}) => {
  return {
    ...CSS.flexColumn('center', 'space-evenly'),
    width: '90%',
    height: 'auto',
    maxHeight: '80vh'
  }
})

const TitleStyles = styled('div')(({theme}) => {
  return {
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.dark,
      width: '100%',
      maxWidth: '400px'
    }
  }
})

const SubtitleStyles = styled('div')(({theme}) => {
  return {
    width: '100%',
    color: theme.palette.primary.dark,
    maxWidth: '400px'
  }
})

const MDUpHidden = styled('div')(({theme}) => {
  return {
    width: '100%',
    ...CSS.flexColumn('center', 'center'),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
})
const onSuccessGoogleWithNavigate: (
    onError: (message: string) => void
) => (response: any) => Promise<void> = (onError: (message: string) => void) => async (response: any) => {
  const { profileObj: { email, givenName, familyName } } = response
  const entries = { email, name: `${givenName} ${familyName}` };
  await checkUser(entries.email, entries.name, onError)
}

const onFacebookWithNavigate: (
    onError: (message: string) => void
) => ISocialLoginActions["onFacebook"] = (onError: (message: string) => void) => async (response: any) => {
  if (exists(response) && !response.status) {
    const { email, name } = response
    await checkUser(email, name, onError)
  }
}

const checkUser = async (email: string, name: string, onError: (message: string) => void) => {
  // todo Medida peleativa enquanto o login é limitado
  const [result] = await handlePromise(checkUserEmail(email))
  const entries = { email, name: name };
  if (result.data.userExists) {
    await makeAuthLogin(entries)
  } else {
    const response = await allowNewUser()
    if (!response.data.allowNewUser) {
      onError(response.data.message)
      return;
    } else {
      await makeAuthLogin(entries)
    }
  }
}

const makeAuthLogin = async (entries: ILoginSocial) => {
  const [ result, error ] = await handlePromise(socialLogin(entries))
  if(!!error){
    alert(error.response.data.message)
    return
  }
  updateToken(result.data.token)
  setTimeout(() => window.location.href = '/', 10)
}

const dictErrorMessages: any = {
  401: 'Erro ao tentar se autenticar',
  403: 'Senha incorreta',
  406: 'Email não encontrado',
  500: 'Estamos passando por ajustes técnicos, agradecemos sua compreensão'
}

const LoginPage: React.FC = () => {

  const { setUser } = useAuth()
  const { setModalContent, setModalOpen } = useModal()
  const navigate = useNavigate()
  const socialLoginActions: ISocialLoginActions = {
    onSuccessGoogle: onSuccessGoogleWithNavigate((message: string) => {
        setModalContent(<MCAlert title="Erro ao realizar cadastro" text={message}/>)
        setModalOpen(true)
    }),
    onFailureGoogle: (error) => {
      setModalContent(<MCAlert title="Erro ao fazer login com google" />)
      setModalOpen(true)
    },
    onFacebook: onFacebookWithNavigate((message: string) => {
      setModalContent(<MCAlert title="Erro ao realizar cadastro" text={message}/>)
      setModalOpen(true)
    })
  }
  const onSubmit = async (values: ILoginValues, actions: FormikHelpers<ILoginValues>) => {
    const [ result, error ] = await handlePromise(loginWithEmail(values))
    if(error){
      console.log(error.stack)
      if(!!dictErrorMessages[error.response.status]){
        setModalContent(
          <MCAlert
            title="Erro ao fazer login"
            text={dictErrorMessages[error.response.status]}
          />
        )
        setModalOpen(true)
        return
      }
      setModalContent(<MCAlert title="Um erro inesperado ocorreu" text="acesse o log para mais detalhes" /> )
      setModalOpen(true)
      return
    }
    updateToken(result.data.token)
    setUser(tokenToUser())
    navigate('/dashboard')
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('sessionExpired') === 'true') {
      setModalContent(<MCAlert title="Sua sessão expirou!"/>)
      setModalOpen(true)
    }
  }, [])

  return (
    <RootStyle title="Login | ProAtivos">
      <MHidden width="mdDown">
        <ContentlessSectionStyle>
          <NewLogo imgStyle={{width: '30%', minWidth: '390px'}} />
        </ContentlessSectionStyle>
      </MHidden>
      <AsideWrapper>
        <AsideContentStyle>
          <MDUpHidden>
            <NewLogo imgStyle={{width: '30%', minWidth: '300px'}} />
          </MDUpHidden>
          <TitleStyles><Typography variant="h3" style={{width: "100%"}}>LOGIN</Typography></TitleStyles>
          <MDUpHidden><SubtitleStyles>
            <Typography variant="subtitle2">BEM-VINDO DE VOLTA!</Typography>
          </SubtitleStyles></MDUpHidden>
          <LoginForm handleSubmitProp={onSubmit} socialLoginActions={socialLoginActions} />
        </AsideContentStyle>
      </AsideWrapper>
    </RootStyle>
  )
}

export default LoginPage