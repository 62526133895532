import React from "react";
import styled from "styled-components";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

type Props = {
    style?: React.CSSProperties
}

export const LogoLayout: React.FC<Props> = ({ style }) => {
    return (
        <Content>
            <img
                style={style ?? { width: '90%', minWidth: '300px', maxWidth: '450px' }} src="/imgs/newLogo.svg"
                alt="Logo da pagina, Escrito 'ProAtivos by Marina Guanor' em tons de marrom e dourado"
            />
        </Content>
    );
}
