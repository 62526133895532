import {Container,  TextField, Typography, Stack, Button} from '@material-ui/core'
import { FormikProvider, Form, useFormik } from 'formik'
import * as Yup from 'yup'
import React, { FC, useState, useEffect } from 'react'
import {
  containerStyles,
  formStyles,
  titleStyles,
  btnWrapper,
  btnSecondary,
  btnPrimary
} from './styles'
import palette from '../../../../theme/palette'
import { handlePromise } from '../../../../utils/handlePromisse'
import { searchCEP } from '../../../../services/profileService'
import { applyMask } from '../../../../utils'

interface UserAddress {
  cep: string 
  city: string
  state: string
  address: string
  district: string
  number: string,
  complement?: string
}

interface ModalAddressContentProps {
  onClose: Function,
  address: UserAddress
}

export interface ModalAddressContentvalues {
  cep: string 
  city: string
  state: string
  address: string
  district: string
  number: string,
  complement?: string,
}

const updateFormikValues = (formik: any, values: any) => {
  Object.entries(values).map(([key, val]) => formik.values[key] = val)
}

const updateObjectWithBaseObj = (state: any, values: any) => {
  Object.entries(values).map(([key, val]) => state[key] = val)
}

let initialValues: ModalAddressContentvalues = {
  cep: '',
  city: '',
  state: '',
  address: '',
  district: '',
  number: '',
  complement: ''
}

const ModalAddressContent: FC<ModalAddressContentProps> = ({onClose, address: {cep, address, city, state, district, number}}) => {

  cep = applyMask('cep').onValue(cep)
  const [showError, setError] = useState(false)
  const [showSuccess, setSuccess] = useState(false)
  const [formState, setFormState] = useState<ModalAddressContentvalues>({...initialValues})
  const [validCEP, setValidCEP] = useState(cep != null)

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000)
    if (showSuccess) setTimeout(() => setSuccess(false), 2000)
  }, [showError, showSuccess])

  const ProfileSchema = Yup.object().shape({
    cep: Yup.string()
      .required('Este campo é obrigatório')
      .min(7, 'CEP inválido')
      .test('isValidCEP', 'CEP inválido', (value) => !!value && !!validCEP),
    city: Yup.string().required('Este campo é obrigatório'),
    state: Yup.string().required('Este campo é obrigatório'),
    address: Yup.string().required('Este campo é obrigatório'),
    district: Yup.string().required('Este campo é obrigatório'),
    number: Yup.string().required('Este campo é obrigatório')
  })
  
  const formik = useFormik({
    initialValues: formState,
    validationSchema: ProfileSchema,
    onSubmit: (values, actions) => {}
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps} = formik

  useEffect(() => {
    setFormState({...formState, cep, address, city, state, district, number})
    updateFormikValues(formik, {cep, address, city, state, district, number})
  }, [cep, address, city, state, district, number])

  const handleInputChange = (e: any) => {
    if(['cep', 'address', 'number', 'district', 'city', 'state'].includes(e.target.id)){
      if(e.target.id === 'cep') e.target.value = applyMask('cep').onValue(e.target.value)
      const updateObj: any = {...formState}
      updateObj[e.target.id] = e.target.value
      setFormState(updateObj)
      updateObjectWithBaseObj(formState, updateObj)
      updateFormikValues(formik, formState)
    }
  }

  const handleCEP = async (e: any) => {
    handleInputChange(e)
    if(e.target.value.length > 8){
      const [result] = await handlePromise(searchCEP(e.target.value))
      if(!!result){
        if(!!result.data.erro){
          const updateObject: any = {...formState, ...initialValues}
          updateObject.cep = formState.cep;
          setValidCEP(false)
          setFormState(updateObject)
          updateFormikValues(formik, initialValues)
          return
        }

        setValidCEP(true)
        const { localidade, logradouro, bairro, uf } = result.data as any
        setFormState({
          ...formState,
          ...{
            city: localidade as string,
            address: logradouro as string,
            district: bairro as string,
            state: (uf as string).toUpperCase()
          }
        })
        updateFormikValues(formik, {
          city,
          address,
          district,
          state: (uf as string).toUpperCase()
        })
      }
    }
  }

  return (
      <Container sx={containerStyles}>
      <Typography variant="h3" sx={titleStyles}>editar endereço</Typography>
      <FormikProvider  value={formik}>
        <Form style={formStyles} onSubmit={(e) => e.preventDefault()}>
          <TextField
            fullWidth
            id="cep"
            autoComplete="cep"
            type="cep"
            label="CEP"
            {...getFieldProps('cep')}
            onChange={handleCEP}
            value={formState.cep}
            variant="outlined"
            color='primary'
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'cep'}}
            error={Boolean(touched.cep && errors.cep)}
            helperText={touched.cep && errors.cep}
          />

          <TextField
            fullWidth
            id="address"
            autoComplete="address"
            type="address"
            label="ENDEREÇO"
            {...getFieldProps('address')}
            onChange={handleInputChange}
            value={formState.address}
            variant="outlined"
            color='primary'
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'address'}}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
            disabled={!validCEP}
          />
          <TextField
            fullWidth
            id="number"
            autoComplete="number"
            type="number"
            label="NÚMERO"
            {...getFieldProps('number')}
            onChange={handleInputChange}
            value={formState.number}
            variant="outlined"
            color='primary'
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'number'}}
            error={Boolean(touched.number && errors.number)}
            helperText={touched.number && errors.number}
            disabled={!validCEP}
          />
          <TextField
            fullWidth
            id="district"
            autoComplete="district"
            type="district"
            label="BAIRRO"
            {...getFieldProps('district')}
            onChange={handleInputChange}
            value={formState.district}
            variant="outlined"
            color='primary'
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'district'}}
            error={Boolean(touched.district && errors.district)}
            helperText={touched.district && errors.district}
            disabled={!validCEP}
          />
          <TextField
            fullWidth
            id="city"
            autoComplete="city"
            type="city"
            label="CIDADE"
            {...getFieldProps('city')}
            onChange={handleInputChange}
            value={formState.city}
            variant="outlined"
            color='primary'
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'city'}}
            error={Boolean(touched.city && errors.city)}
            helperText={touched.city && errors.city}
            disabled={!validCEP}
          />
          <TextField
            fullWidth
            id="state"
            autoComplete="state"
            type="state"
            label="ESTADO"
            {...getFieldProps('state')}
            onChange={handleInputChange}
            value={formState.state}
            variant="outlined"
            color='primary'
            sx={{
              background: palette.primary.lighter,
              marginBottom: '20px'
            }}
            inputProps={{'data-testid': 'state'}}
            error={Boolean(touched.state && errors.state)}
            helperText={touched.state && errors.state}
            disabled={!validCEP}
          />
        </Form>
        <Stack sx={btnWrapper} direction="row" spacing={0}>
          <Button
              size={"large"}
              onClick={() => onClose(null)}
              sx={btnSecondary}
              variant="contained">Voltar</Button>
          <Button
              size={"large"}
              onClick={() => onClose(formState, validCEP)}
              sx={btnPrimary}
              variant="contained">alterar</Button>
        </Stack>
      </FormikProvider>
    </Container>
  )
}

export default ModalAddressContent