import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { AdmLayout } from "../../../layouts/base/adm.layout";
import { handlePromise } from "../../../utils/handlePromisse";
import { initialPagination, PaginatedResponse } from "../../../domain/models/paginated-response.model";
import { UserModel } from "./table/user.model";
import { SimpleSnackbar } from "../../../components/snackbar";
import { UserTable } from "./table/user-table";
import { getPaginatedUsers, UserFilter } from "../../../services/userService";
import { FilterType, PlanFilterType, UserForm } from "./form/user-form";
import { MessageDialog } from "../../../components/message-dialog";
import { deleteUser } from "../../../services/adm";

export const UsersPage: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState<UserFilter>({ page: 1, size: 10 })

    const [openDialog, setOpenDialog] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [userId, setUserId] = useState<number | undefined>(undefined)
    const [users, setUsers] = useState<PaginatedResponse<UserModel>>(initialPagination)

    const fetchUsers = useCallback(async () => {
        setLoading(true)
        const [result, error] = await handlePromise(getPaginatedUsers(filter))
        setLoading(false)

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setUsers(result.data as PaginatedResponse<UserModel>)
    }, [filter])

    const cancelPlan = useCallback(async () => {
        setLoading(true)

        const [_, error] = await handlePromise(deleteUser(userId || -1))
        setLoading(false)

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setOpenDialog(false)
        setShowSnackbar(true)
        setSnackbarMessage('Plano cancelado com sucesso!')

        fetchUsers()
    }, [userId])

    const handlePageChange = useCallback(async (newPage: number, size: number) => {
        setFilter({ ...filter, page: newPage, size })
    }, [filter])

    const handleSizeChange = useCallback(async (newSize: number, page: number) => {
        setFilter({ ...filter, size: newSize, page })
    }, [filter])

    const handleCancelPlan = useCallback(async (id: number) => {
        setUserId(id)
        setOpenDialog(true)
    }, [])

    const handleFilter = useCallback(async (type?: FilterType, planFilter?: PlanFilterType, query?: string) => {
        let withPlan: undefined|boolean
        if (planFilter == 'yes') {
            withPlan = true
        } else if (planFilter == 'no') {
            withPlan = false
        } else {
            withPlan = undefined
        }
        setFilter({ ...filter, type, query, withPlan })
    }, [filter])

    useEffect( () => {
        fetchUsers()
    }, [filter])

    return <AdmLayout loading={loading} hasLogo={true}>
        <MessageDialog
            open={openDialog}
            message={`Deseja cancelar o plano de ${users.data.find(value => value.id === userId)?.name}?`}
            onConfirm={() => cancelPlan()}
            confirmButtonText='Sim'
            onClose={() => setOpenDialog(false)}
            cancelButtonText='Cancelar'
        />

        <SimpleSnackbar
            show={showSnackbar}
            message={snackbarMessage}
            onDismiss={() => setShowSnackbar(false)}
        />

        <UserForm onFilter={handleFilter}/>

        <UserTable
            data={users.data}
            onCancelPlan={handleCancelPlan}
            onPageChange={handlePageChange}
            onSizeChange={handleSizeChange}
            total={users.pagination.total}
            size={users.pagination.perPage}
            page={users.pagination.currentPage}
        />
    </AdmLayout>
}