import axios from "axios";
import {removeUser, tokenToUser} from "./userService"
import {isEqual} from 'lodash'
import React from "react";

const { REACT_APP_URL_API } = process.env;

const token = localStorage.getItem('token')
let axiosInstance = axios.create({
  baseURL: REACT_APP_URL_API,
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
  },
})

axiosInstance.interceptors.request.use(request => {
  const user = tokenToUser()
  if(!isEqual(user, {}) && user.exp * 1_000 - Date.now() < 0){
    removeUser()
    window.location.href = "/"
  }
  return request
})

axiosInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  if (error.response != null && (error.response.status === 401)) {
    removeUser()
    window.location.href = "/login?sessionExpired=true"
  }

  return Promise.reject(error);
});


export default axiosInstance