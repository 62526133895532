import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
    show: boolean
    message: string
    onDismiss: () => void
}

export const SimpleSnackbar: React.FC<Props> = ({ message, show,onDismiss }) => {

    const handleClose = (event: React.SyntheticEvent|Event, reason?: string) => {
        if (reason === 'clickaway') return
        onDismiss()
    }

    const action = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}>
            <CloseIcon fontSize="small"/>
        </IconButton>
    )

    return (
        <Snackbar
            open={show}
            action={action}
            message={message}
            onClose={handleClose}
            autoHideDuration={3000}
            key={'snackbar-bottom-right'}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
    )
}
