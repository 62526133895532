type JustifyContent = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'

type AlignItems = 'flex-start' | 'center' | 'flex-end'

interface CSSShortCuts {
  flexRow: (horizontalAlignment: JustifyContent, verticalAlignment: AlignItems) => any,
  flexColumn: (horizontalAlignment: AlignItems, verticalAlignment: JustifyContent) => any,
  circle: (diameter: number) => any
}

const CSS: CSSShortCuts  = {
  flexRow: (horizontalAlignment: JustifyContent, verticalAlignment: AlignItems) => {
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: horizontalAlignment,
      alignItems: verticalAlignment
    }
  },
  flexColumn: (horizontalAlignment: AlignItems, verticalAlignment: JustifyContent) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: verticalAlignment,
      alignItems: horizontalAlignment
    }
  },
  /**
   * 
   * @param diameter medida em pixels
   * @returns 
   */
  circle: (diameter: number) => {
    return {
      width: `${diameter}`,
      height: `${diameter}`,
      borderRadius: '50%'
    }
  }
}

export default CSS