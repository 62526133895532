import React, { useCallback, useState } from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

import Page from '../../../components/Page';
import { MHidden } from '../../../components/@material-extend';
import CSS from '../../../utils/CSSShortCuts';
import { Typography } from '@material-ui/core';
import palette from '../../../theme/palette';
import SignupForm, { ISignupValues } from './signupForm'
import { FormikHelpers } from 'formik';
import NewLogo from '../../../components/NewLogo';
import { useModal } from '../../../contexts/modal';
import { LoginSteps } from "./model/login-steps";
import { VerifyEmail } from "./form/verify-email";
import { handlePromise } from "../../../utils/handlePromisse";
import { resendOtp, sendOtp, validateOtp } from "../../../services/user-validation/otp.service";
import { SimpleSnackbar } from "../../../components/snackbar";
import { signupWithEmail } from "../../../services/authService";
import MCAlert from "../../../components/ModalContents/MCAlert";

const RootStyle = styled(Page)(({theme}) => ({
  minHeight: '100vh',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentlessSectionStyle = styled('div')(({theme}) => {
  return {
    ...CSS.flexColumn('center', 'center'),
    width: '100%',
    height: 'auto',
    minHeight: '100vh',
  }
})

const AsideWrapper = styled('div')(({theme}) => {
  return {
    ...CSS.flexColumn('center', 'flex-start'),
    width: '100%',
    maxWidth: '40vw',
    height: '100vh',
    background: palette.primary.main,
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw',
      background: '#fff'
    }
  }
})
const AsideContentStyle = styled('div')(({theme}) => {
  return {
    ...CSS.flexColumn('center', 'space-evenly'),
    width: '90%',
    height: 'auto',
  }
})

const TitleStyles = styled('div')(({theme}) => {
  return {
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.dark,
      width: '100%',
      maxWidth: '400px'
    }
  }
})

const MDUpHidden = styled('div')(({theme}) => {
  return {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
})

const SignupPage: React.FC = () => {
    const { setModalOpen, setModalContent } = useModal()
    const navigate = useNavigate()
    const [values, setValues] = useState<ISignupValues>({} as ISignupValues)

    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [step, setStep] = useState<LoginSteps>(LoginSteps.REGISTER)

    const onSubmit = async (values: ISignupValues, actions: FormikHelpers<ISignupValues>) => {
        setStep(LoginSteps.CONFIRMATION)
        setValues(values)
        await sendOtpCode(values.email)
        actions.setSubmitting(false)
    }

    const registerUser = async (values: ISignupValues) => {
        const [, error] = await handlePromise(signupWithEmail(values))
        if (!!error) {
            setModalContent(<MCAlert title="Erro ao realizar cadastro" text={error.response.data.message}/>)
            setModalOpen(true)
        } else {
            setModalContent(
                <MCAlert
                    title="Cadastro bem-sucedido"
                    text="Parabéns 🎉🎉, agradecemos pela preferência"
                    buttonText="ir para login"
                    onClose={() => {
                        navigate('/login', { replace: true })
                        setModalOpen(false)
                    }}
                />
            )
            setModalOpen(true)
        }
    }

    const sendOtpCode = useCallback(async (email) => {
        const [_, error] = await handlePromise(sendOtp(email))

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }
    }, [values])

    const validateOtpCode = useCallback(async (otp) => {
        const [_, error] = await handlePromise(validateOtp(values.email, otp))

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setShowSnackbar(true)
        setSnackbarMessage('Código validado com sucesso!')
        await registerUser(values)
    }, [values])

    const resendOptCode = useCallback(async () => {
        const [_, error] = await handlePromise(resendOtp(values.email))

        if (!!error) {
            setShowSnackbar(true)
            setSnackbarMessage(error.response.data.message)
            return
        }

        setShowSnackbar(true)
        setSnackbarMessage('Código reenviado com sucesso!')
    }, [values])

    const onBack = () => {
        setStep(LoginSteps.REGISTER)
    }

    return (
        <RootStyle title="Signup | ProAtivos">
            <SimpleSnackbar
                show={showSnackbar}
                message={snackbarMessage}
                onDismiss={() => setShowSnackbar(false)}
            />

            <MHidden width="mdDown">
                <ContentlessSectionStyle>
                    <NewLogo imgStyle={{ width: '30%', minWidth: '390px' }}/>
                </ContentlessSectionStyle>
            </MHidden>
            <AsideWrapper>
                <AsideContentStyle>
                    <MDUpHidden>
                        <NewLogo imgStyle={{ width: '30%', minWidth: '300px' }}/>
                    </MDUpHidden>
                    {step == LoginSteps.REGISTER &&
                        <TitleStyles>
                            <Typography variant="h3">
                                CADASTRO
                            </Typography>
                            <SignupForm
                                typedValues={values}
                                handleSubmitProp={onSubmit}
                            />
                        </TitleStyles>
                    }
                    {
                        step == LoginSteps.CONFIRMATION && <VerifyEmail
                            onValidate={validateOtpCode}
                            onResend={resendOptCode}
                            onBack={onBack} />
                    }
                </AsideContentStyle>
            </AsideWrapper>
        </RootStyle>
    )
}

export default SignupPage