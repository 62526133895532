import {
    Button,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton
} from "@material-ui/core";
import * as React from "react";
import { setNotifyFormula } from "../../../../services/local-session-storage";
import { Close } from "@material-ui/icons";
import styled from "styled-components";

type Props = {
    open: boolean
    onClose: () => void
    onConfirm: () => void
}

const Title = styled(DialogTitle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const DoseNotifyDialog: React.FC<Props> = ({ open, onClose, onConfirm }) => {

    const handleOnChange = (checkableValue: boolean) => {
        setNotifyFormula(checkableValue)
    }

    const handleConfirm = () => {
        onConfirm()
        onClose()
    }

    return (
        <Dialog open={open} onClose={() => onClose} aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">
            <Title>
                Aviso
                <IconButton onClick={onClose}><Close/></IconButton>
            </Title>
            <DialogContent>
                <DialogContentText>
                    Ao concluir ou gerar pdf, sua fórmula será salva no histórico. Na aba ‘Histórico de Consultas’ você
                    pode conferir seu histórico de fórmulas montadas, editar consultas, agrupar fórmulas e gerar novos
                    pdfs.
                </DialogContentText>
                <DialogContentText>
                    <FormControlLabel
                        key={'dose-notify-checkbox'}
                        control={<Checkbox onChange={(_, checked) => handleOnChange(checked)}/>}
                        label={'Não exibir essa mensagem novamente'}
                    />
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleConfirm}>Ok</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}