export const removeEmptyAttributes = (obj: any) => Object.fromEntries(Object.entries(obj).filter(([key, val]) => val !== undefined && val !== '' && val !== null))

type MaskTypes = 'phone' | 'cep' | 'date' | 'cpf' | 'cardExp' | 'cvv' | 'cardNumber'
export const applyMask = (maskType: MaskTypes) => {
  const maskDict: any = {
    phone: (val: string) => 
      val.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1'),
    cep: (val: string) => 
      val.replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1'),
    date: (val: string) => 
      val.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{4})\d+?$/, '$1'),
    cpf: (val: string) =>
      val.replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\.\d{3})(\d)/, '$1.$2')
      .replace(/(\.\d{3})(\.\d{3}})(\d)/, '$1.$2.$3')
      .replace(/(\.\d{3}\.\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2}).+?$/, '$1'),
    cardExp: (val: string) =>
      val.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{2}).+?$/, '$1'),
    cvv: (val: string) =>
      val.replace(/\D/g, '')
      .replace(/(\d{4}).+?$/, '$1'),
    cardNumber: (val: string) =>
      val.replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1.$2')
      .replace(/(\d{4}\.\d{4})(\d)/, '$1.$2')
      .replace(/(\d{4}\.\d{4}\.\d{4})(\d)/, '$1.$2')
      .replace(/(\d{4}\.\d{4}\.\d{4}\.\d{4}).+?$/, '$1')
  }
  const onValue = (mask: MaskTypes, val: string) => {
    if(!!maskDict[mask]){
      return maskDict[mask](val)
    }else{
      console.warn(`Invalid mask type "${mask}" skiped`)
      return val
    }
  }
  return {
    onValue: (value: string) => onValue(maskType, value)
  }
}

export const arrayWithoutOneElement = (list: any[], index: number) => [
  ...list.slice(0, index),
  ...list.slice(index + 1, list.length)
]

interface TreatStringOPtions {
  onOverflowUseThreeDots?: boolean,
  capitalize?: boolean
}
export const treatString = (
  name: string, 
  maxLength: number, 
  props: TreatStringOPtions = {
    capitalize: false,
    onOverflowUseThreeDots: true
  }
) => {
  const { capitalize, onOverflowUseThreeDots} = props

  const trimedString = name.trim()
  const transformedString = capitalize ? 
    `${trimedString.substring(0, 1).toUpperCase()}${trimedString.substring(1, trimedString.length)}` :
    trimedString
  const cuttedString = maxLength > 0 && transformedString.length >= maxLength ? 
    transformedString.substring(0, maxLength) : 
    transformedString

  if(transformedString !== cuttedString){
    return onOverflowUseThreeDots === true || onOverflowUseThreeDots === undefined ?
      cuttedString.substring(0, cuttedString.length - 3).trim() + '...':
      cuttedString
  }
  return transformedString
}

export function createDebounce(){
  let timer: any
  return (fn: Function, time: number) => {
    clearTimeout(timer)
    timer = setTimeout(fn, time)
  }
}

export const exists = (item: any) => {
  if(typeof item === 'object'){
    return item !== null && item !== undefined && (Object.keys(item).length > 0 || item.length > 0)
  }
  return item !== null && item !== undefined
}

export type Merge<A, B> = {
  [K in keyof A]: K extends keyof B ? B[K] : A[K]
} & B

export const orderObjectArray = <T extends object, K extends keyof T>(objArr: T[]) => {
  type ObjectKeys = keyof T
  function format(val: any){
    return typeof val === 'string'
      ? val.toLowerCase().normalize('NFD')
      : val
  }
  function by(key: ObjectKeys) {
    return {
      asc: () => asc(key),
      desc: () => desc(key)
    }
  }
  function asc(key: ObjectKeys){
    return objArr.sort((a: T, b: T) => format(a[key]).localeCompare(format(b[key]), 'pt-BR'))
  }
  function desc(key: ObjectKeys){
    return objArr.sort((a: T, b: T) => {
      if(format(b[key]) < format(a[key])) return -1
      if(format(b[key]) > format(a[key])) return 1
      return 0
    })
  }
  return {
    by: (key: ObjectKeys) => by(key)
  }
}

export const flexIncludes = (item: string, filter: string) => {
  filter = filter.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()
      .trim().replaceAll(" ", ".* ") + ".*"
  const regex = new RegExp(filter, "g");

  return item.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase().match(regex)
}

export const objectMap = (baseObject: object, fn: (key: string, val: any) => any): {[ k in keyof typeof baseObject ]: any } => {
  const entries = Object.entries(baseObject)
  const mapped = entries.map((e) => [e[0], fn(e[0] as keyof typeof baseObject, e[1] as any)])
  return Object.fromEntries(mapped)
}

export const flexMultiFilter = <T>(arr: T[], keys: string[], filter: string): T[] => {
  const result: T[] = keys.flatMap(key => {
    return flexFilter(arr, key, filter);
  });

  return result.filter((elem, index, self) => index === self.indexOf(elem))
}

export const flexMultiFilterWithOrderBy = <T>(arr: T[], keys: string[], filter: string, order: string): object[] => {
  const result: object[] = keys.flatMap(key => {
    return orderObjectArray(flexFilter(arr, key, filter) as any).by(order as keyof object).asc()
  });

  return result.filter((elem, index, self) => index === self.indexOf(elem))
}


export const flexFilter = <T>(arr: T[], key: string, filter: string): T[] => {
  if (filter.length === 0) return arr
  filter = filter.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()
      .trim().replaceAll(" ", ".* ") + ".*"
  const regex = new RegExp(filter, "g");

  return arr.filter(obj => {
    const item = String(obj[key as keyof T]);
    return item.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase().match(regex)
  });
}

export const isNullable = (x: any): x is null => x === null || x === undefined