export const redirectToPDF = (base64: string) => {
    const bin = atob(base64.replace(/\s/g, ''))
    const buffer = new ArrayBuffer(bin.length)
    const view = new Uint8Array(buffer)
    for (let i = 0; i < bin.length; i++) {
        view[i] = bin.charCodeAt(i)
    }
    const blob = new Blob([view], { type: 'application/pdf' })

    const linkEl = document.createElement('a')
    linkEl.download = `${new Date().getTime()}.pdf`
    linkEl.target = '_blank'
    linkEl.href = URL.createObjectURL(blob)
    document.body.appendChild(linkEl)
    setTimeout(() => {
        linkEl.click()
        document.body.removeChild(linkEl)
    }, 500)
}
