import {ProductsWithFilterNPaginationResponse} from "./productService";

export default function getDescriptionPeriod(daytimeProduct?: number, nighttimeProduct?: number): string {
    if (!!daytimeProduct && !!nighttimeProduct) {
        return "AMBOS";
    }

    return !!daytimeProduct ? "DIURNO" : "NOTURNO";
}

export function getDescriptionPeriodByProduct(product: ProductsWithFilterNPaginationResponse): string {
    return getDescriptionPeriod(product.daytime_Product, product.nighttime_Product)
}