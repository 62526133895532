import http from "./http-common";
import {ProductModel} from "../models/Product.model";
import {AxiosResponse} from "axios";
import ActiveProductModel from "../models/activeProduct.model";
import SubclassProductCompositeModel from "../models/subclassProductComposite.model";
import {SearchProductModel} from "../models/SearchProduct.model";

export const saveProduct = (product: ProductModel) => {
    if (product.idProduct != null) {
        return http.put('/products/:idProduct', {product})
    }

    return http.post('/products', {product})
}

export const getProductsWithFilterNPagination = (offset: number, limit: number, searchProduct?: SearchProductModel) => http.get(`/products/${offset}/${limit}?${new URLSearchParams(searchProduct).toString()}`)

export const favoriteProduct = (entries: FavoriteEntries) => http.post('/products/favorite', entries)

export const postAnnotation = (idProduct:number, annotation:{}) => http.post(`/annotation/${idProduct}`, annotation)

export const putAnnnotation = (idProduct:number, annotation:{}) => http.put(`/annotation/${idProduct}`, annotation)

export const getAnnotation = (idProduct:number) => http.get(`/annotation/${idProduct}`) 

export const unFavoriteProduct = (idUser: number, idProduct: number) => http.delete(`/products/favorite/${idUser}/${idProduct}`)

export const getProducts = (entries: ProductEntries) => http.get<any, AxiosResponse<ProductResponseItem[]>>(`/products?${new URLSearchParams(entries as any).toString()}`,)

export const getActiveProducts = (productId: string) => http.get<any, AxiosResponse<ActiveProductModel>>(`/products/${productId}/actives`)

export const getSubclassProducts = (productId: string) => http.get<any, AxiosResponse<SubclassProductCompositeModel>>(`/products/${productId}/subclasses`)

export const enableProduct = (productId: string) => http.put(`/products/${productId}/enable`)

export const disableProduct = (productId: string) => http.put(`/products/${productId}/disable`)

export const getPaginatedProducts = (page: number, size: number, order?: string, orderBy?: string, filter?: string) => {
    let url = `/products/paginated?size=${size}&page=${page}`
    if (filter !== undefined) url = url + `&search=${filter}`
    if (order !== undefined && orderBy !== undefined) url = url + `&order=${order}&orderBy=${orderBy}`
    return http.get(url)
}

export const getProductDetail = (productId: number) => http.get(`/products/${productId}`)

export type ProductEntries = {
    daytime: boolean,
    nighttime: boolean,
    actives: number[],
    subclass: number[],
    pregnant: boolean,
    vegan: boolean,
    organic: boolean,
}

export type ProductResponseItem = {
    actives: string[],
    description_Product: string,
    favorite: boolean,
    id_Product: number,
    incompatibility_Product: string,
    maxDosage_Product: string,
    mechanismAction_Product: string,
    minimumDosage_Product: string,
    name_Product: string,
    pH_Product: string,
    source_Product: string,
}

export type FavoriteEntries = {
    id_User: number,
    id_Product: number,
}

export type ProductsWithFilterNPaginationResponse = {
    actives: string[],
    "id_Product": number,
    "name_Product": string,
    "description_Product": string,
    "mechanismAction_Product": string,
    "pH_Product": string,
    "incompatibility_Product": string,
    "minimumDosage_Product": string,
    "maxDosage_Product": string,
    "source_Product": string,
    "favorite": number,
    "totalProducts": number,
    "pregnant_Product": number,
    "vegan_Product": number,
    "organic_Product": number,
    "daytime_Product": number,
    "nighttime_Product": number,
    "classes": string,
    "disabled_Product": number
}