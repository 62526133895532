import http from './http-common'
import OrderModel from "../models/OrderModel";
import OrderUpdateModel from "../models/orderUpdate.model";
import PreapprovalModel from "../models/PreapprovalModel";
import UpdatePreapprovalModel from "../models/UpdatePreapprovalModel";

class OrderService {

    async create(entries: OrderModel) {
        return http.post('/order', entries)
    }

    async update(paymentId: string, entries: OrderUpdateModel) {
        return http.patch(`/order/${paymentId}`, entries)
    }

    async createPreapproval(entries: PreapprovalModel) {
        return http.post('/order/preapproval', entries)
    }

    async updatePreapproval(preapprovalId: string, entries: UpdatePreapprovalModel) {
        return http.patch(`/order/preapproval/${preapprovalId}`, entries)
    }

    async cancelPreapproval(preapprovalId: string) {
        return http.delete(`/order/preapproval/${preapprovalId}`)
    }
}

export default new OrderService();