type JustifyContent = 
  'flex-start' |
  'center' |
  'flex-end' |
  'space-evenly' |
  'space-around' |
  'space-between'

type AlignItems =
  'flex-start' |
  'center' |
  'flex-end'

function flexRow(horizontalAlignment: JustifyContent = 'center', verticalAlginment: AlignItems = 'center'){
  return `display: flex;
  flex-direction: row;
  justify-content: ${horizontalAlignment};
  align-items: ${verticalAlginment};
  `
}

function flexColumn(horizontalAlignment: AlignItems = 'center', verticalAlginment: JustifyContent = 'center'){
  return `display: flex;
  flex-direction: column;
  justify-content: ${verticalAlginment};
  align-items: ${horizontalAlignment};
  `
}

function circle(radius: number = 0, unit: 'px' | 'vw' | 'vh' = 'px'){
  return `width: ${radius}${unit};
  height: ${radius}${unit};
  border-radius: 50%;
  `
}

function square(width: number, unit: 'px' | 'vw' | 'vh' = 'px', borderRadiusPx: number = 0){
  return `width: ${width}${unit};
  height: ${width}${unit};
  border-radius: ${borderRadiusPx}px;
  `
}

export const globalStyleFunctions = {
  flexRow,
  flexColumn,
  circle,
  square
}