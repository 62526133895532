import { ILoginSocial, ILoginValues } from "../pages/authPages/login/loginForm";
import { ISignupValues } from "../pages/authPages/signup/signupForm";
import http from "./http-common";
import { updateUserToken } from "./userService";

export const signupWithEmail = (entries: ISignupValues) => {
  const { fullName, email, password } = entries
  return http.post('/user', {
    name_User: fullName,
    email_User: email,
    password_User: password
  })
}

export const loginWithEmail = (entries: ILoginValues) => {
  const { email, password } = entries
  return http.post('/user/auth', {
    email,
    password
  })
}

export const socialLogin = (entries: ILoginSocial) => {
  const { email, name } = entries
  return http.post('/user/auth/social', {
    email,
    name
  })
}

export const checkUserEmail = (email: string) => {
  return http.post('/user/check-email', { email })
}

export const updateToken = (token: string) => {
  updateUserToken(token)
}

export const recoverPassword = (email: string) => http.patch(`/user/forgot/password`, { email })

export const newPassword = (token: string, newPassword: string) => http.patch(`/user/reset/password`, { token, password: newPassword })

// export default new AuthService();
