import { Stack, Box } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { useModal } from '../../contexts/modal'
import { btnFill, btnModal } from '../../pages/main/profile/ModalAddress/styles'
import palette from '../../theme/palette'
import { stackFlexRow } from '../../utils/MUIShortcuts'
import MCBase from './MCBase'

type MCAlertProps = {
  title: string | string[],
  text?: string,
  onClose?: () => void,
  buttonText?: string,
}

const h2Style: React.CSSProperties = {
  color: palette.primary.dark, 
  textAlign: 'center'
}

const pStyle: React.CSSProperties = {
  color: palette.primary.dark, 
  textAlign: 'center',
  margin: '24px 0'
}

const MCAlert: FC<MCAlertProps> = ({title, text, onClose, buttonText}) => {

  const { setModalOpen } = useModal()
  const realTitle: string[] = useMemo(() => {
    if(typeof title === 'string'){
      return [ title ]
    }
    return title
  }, [ title ])

  return (
    <MCBase>
      {
        realTitle.map((e, i) => <h2 style={h2Style} key={'alert_title_' + i}>{ e }</h2>)
      }
      <p style={pStyle}>{text}</p>
      <Stack {...stackFlexRow('center', 'center')} style={{width: '100%'}}>
        <Box
          onClick={() => !!onClose ? onClose() : setModalOpen(false)}
          sx={{...btnModal, ...btnFill}}
        >
          {buttonText ?? 'fechar'}
        </Box>
      </Stack>
    </MCBase>
  )
}

export default MCAlert