import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ActiveResponse, SubClassModel } from "../../../services/decisionFlow";
import styled from "styled-components";
import { Delete, Edit } from "@material-ui/icons";
import { Button, Switch, TablePagination } from "@material-ui/core";

interface Column {
    id: 'name' | 'enabled' | 'actions';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (enabled: boolean) => string;
}

const columns: readonly Column[] = [
    { id: 'name', label: 'Nome', minWidth: 170 },
    { id: 'enabled', label: 'Ativo', minWidth: 120, format: (enabled: boolean) => enabled ? "Habilitado" : "Desabilitado" },
    { id: 'actions', label: 'Ações' }
];

type Props = {
    page: number
    size: number
    total: number
    data: SubClassModel[]
    onEditItem: (subclass: SubClassModel) => void
    switchSubclass: (subclass: SubClassModel) => void
    onPageChange: (newPage: number, size: number) => void
    onSizeChange: (newSize: number, page: number) => void
}

export const SubclassTable: React.FC<Props> =
    ({
         data, page, size,
         total, switchSubclass, onPageChange, onSizeChange,
         onEditItem
     }) => {

        const handleChangePage = (event: unknown, newPage: number) => {
            onPageChange(newPage, size)
        };

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            onSizeChange(+event.target.value, 0);
        };

        const actions = (subclass: SubClassModel) => {
            return(
                <>
                    <Button onClick={() => onEditItem(subclass)}><Edit/></Button>
                    <Switch checked={subclass?.enabled} onClick={() => switchSubclass(subclass)}/>
                </>
            )
        }

        const createRows = (column: Column, subclass: SubClassModel) => {
            const value = subclass[column.id];
            return (
                <TableCell key={column.id} align={column.align}>
                    {
                        column.id === "actions" ? actions(subclass) :
                            column.format && typeof value === 'boolean' ? column.format(value) : value
                    }
                </TableCell>
            );
        }

        return (
            <Paper>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.map((subclass) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={subclass.id}>
                                            {columns.map((column) => {
                                                return createRows(column, subclass)
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    page={page - 1}
                    component="div"
                    count={total}
                    rowsPerPage={size}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[10, 25, 100]}
                    onRowsPerPageChange={handleChangeRowsPerPage}/>
            </Paper>
        );
    }
