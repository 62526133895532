import React, { FC, useCallback, useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import ProfileForm, { IProfileValues } from "./Form/profileForm"
import { getUserProfile, IUpdateUserAPI, putUserProfile, UserProfileResponse } from "../../../services/profileService"
import { handlePromise } from "../../../utils/handlePromisse"
import { removeEmptyAttributes } from "../../../utils"
import { useAuth } from '../../../contexts/auth2'
import { useModal } from "../../../contexts/modal";
import MCAlert from "../../../components/ModalContents/MCAlert";
import { isFreePlan } from "../../../services/userService";
import { BaseLayout } from "../../../layouts/base/base.layout";
import palette from "../../../theme/palette";
import styled from "styled-components";
import { MessageDialog } from "../../../components/message-dialog";
import { SimpleSnackbar } from "../../../components/snackbar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 16px;
`

export const ProfilePage: FC = () => {

    const { user } = useAuth()

    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')

    const [profile, setProfile] = useState<UserProfileResponse>({} as UserProfileResponse)
    const [open, setOpen] = useState(false)

    const saveProfileChanges = async (idUser: number, entries: Partial<IProfileValues>, setReload: Function) => {
        const { fullName, phone, occupationArea, cep, address, city, state, district, number } = entries
        const updateObj: IUpdateUserAPI = {
            phone_User: phone,
            occupation_User: occupationArea,
            name_User: fullName,
            address: {
                zipcode: cep,
                street: address,
                city,
                state,
                neighbourhood: district,
                number,
            }
        }

        const [result, error] = await handlePromise(putUserProfile(idUser, removeEmptyAttributes(updateObj)))
        if (error) {
            setSnackbarMessage('Erro ao atualizar dados')
            setShowSnackbar(true)
            return;
        }

        setOpen(true)
        setReload(true)
    }

    const userInfo = useCallback(async () => {
        const [result, error] = await handlePromise(getUserProfile(user.idUser))
        if (error) {
            console.log({ error })
            return null
        }
        setProfile(result.data)
    }, [user])

    useEffect(() => {
        userInfo()
    }, [userInfo])

    return (
        <BaseLayout hasLogo={true}>
            <MessageDialog
                open={open}
                message={'Dados alterado com sucesso!'}
                onConfirm={() => setOpen(false)}
                confirmButtonText={'OK'}
            />

            <SimpleSnackbar
                show={showSnackbar}
                message={snackbarMessage}
                onDismiss={() => setShowSnackbar(false)}
            />

            <Container>
                {isFreePlan(profile.planName) && <span><Link to="/dashboard/pricing">FAZER UPGRADE</Link></span>}
                <ProfileForm handleSubmitProp={saveProfileChanges}/>
            </Container>
        </BaseLayout>
    )
}
