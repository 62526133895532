import { FormikProvider, useFormik, Form, FormikHelpers } from 'formik';
import * as Yup from "yup";
import { Link as RouterLink,} from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { TextField, Link, useMediaQuery, CircularProgress } from "@material-ui/core";
import palette from '../../../theme/palette';
import * as CSSType from 'csstype';
import CSS from '../../../utils/CSSShortCuts';
import { LoadingButton } from "@material-ui/lab";
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MDUpHidden = styled('div')(({theme}) => {
  return {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
})
const MDDownHidden = styled('div')(({theme}) => {
  return {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const BtnBack = styled('div')(({theme}) => ({
  ...CSS.flexRow('center', 'center'),
  width: '100%',
  minHeight: '46px',
  background: '#fff',
  color: theme.palette.primary.main,
  border: `2px solid ${theme.palette.primary.main}`,  
  boxShadow: '0 4px 12px rgba(0, 0, 0, .08)',
  borderRadius: '2px'
}))

const formStyles: CSSType.StandardProperties = {
  ...CSS.flexColumn('center', 'space-evenly'),
  width: '100%',
  maxWidth: '400px',
  height: 'auto',
  minHeight: '50vh',
  margin: '20px 0'
}

export interface IRecoverPasswordValues {
  email: string,
}

interface IRecoverPasswordProps {
  handleSubmitProp: (values: IRecoverPasswordValues, actions: FormikHelpers<IRecoverPasswordValues>) => void
}

const RecoverPasswordForm: React.FC<IRecoverPasswordProps> = ({handleSubmitProp}) => {

  const [showError, setError] = useState(false)
  const [showSuccess, setSuccess] = useState(false)

  const buttonColorStyles: CSSType.StandardProperties = {
    background: useMediaQuery((theme: any) => theme.breakpoints.down('md')) ? palette.primary.main : palette.primary.lighter,
    color: useMediaQuery((theme: any) => theme.breakpoints.down('md')) ? palette.primary.lighter : palette.primary.dark
  }

  const textColorStyles: CSSType.StandardProperties = {
    color: useMediaQuery((theme: any) => theme.breakpoints.down('md')) ? palette.primary.main : palette.primary.lighter,
  }

  const RecoverPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Endereço de e-mail inválido').required('E-mail obrigatorio')
  })

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: RecoverPasswordSchema,
    onSubmit: (values, actions) => handleSubmitProp(values, actions)
  })

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess])

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps} = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={formStyles}>
        <TextField 
          fullWidth
          autoComplete="email"
          type="email"
          label="Email"
          {...getFieldProps('email')}
          variant="filled"
          color='primary'
          sx={{
            background: palette.primary.lighter,
          }}
          inputProps={{'data-testid': 'email'}}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          loadingIndicator={
            <CircularProgress color='secondary' size={16} />
          }
          style={{...buttonColorStyles, fontSize: '1.2rem', borderRadius: '2px', boxShadow: '0 4px 12px rgba(0, 0, 0, .15)', margin: '20px 0 10px 0'}}
        >
          { isSubmitting ? '' : 'RECUPERAR' }
        </LoadingButton>

        <MDDownHidden>
          <Link
            component={RouterLink}
            variant='subtitle1'
            to="/login"
            sx={{...textColorStyles, fontWeight: '400', marginTop: '12px', ...CSS.flexRow('center', 'center'),
            textDecoration: 'underline'}}
          >
            <strong style={{marginLeft: '8px'}}>VOLTAR</strong>
          </Link>
        </MDDownHidden>
        <MDUpHidden>
          <BtnBack>
            VOLTAR
          </BtnBack>
        </MDUpHidden>

        <p style={{...textColorStyles, width: '90%', textAlign: 'center', marginTop: '60px'}}>
          Não possui uma conta?
          <Link
            component={RouterLink}
            variant='subtitle1'
            to="/signup"
            sx={{fontWeight: '400', marginTop: '12px'}}
          >
            <strong style={{...textColorStyles, marginLeft: '8px', textDecoration: 'underline'}}>Se inscreva aqui</strong>
          </Link>
        </p>
      </Form>
    </FormikProvider>
  )
}

export default RecoverPasswordForm