import http from './http-common'

export type UsersResponse = {
  "id_User": number,
  "name_User": string,
  "email_User": string,
  "phone_User": string,
  "occupation_User": string,
  "planName": string,
  "amountQueries_PlansPG": number,
  "amountQueriesAvailable": number,
  "amountQueriesUsed_User": number
  "totalUsers": number,
}
export const getAllUser = (offset: number, limit: number, filter: string) => 
  http.get(`/admin/users/${offset}/${limit}`, { params: { value: filter } })

export const deleteUser = (userId: number) => http.delete(`/admin/user/${userId}`)

export const getAllTransactions = (offset: number, limit: number, filter: string) => 
  http.get(`/user/transactions/${offset}/${limit}`, { params: { value: filter } })