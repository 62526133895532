import React, {FC, useEffect} from "react";
import {Container} from "@material-ui/core";
import MCAlert from "../../components/ModalContents/MCAlert";
import {useModal} from "../../contexts/modal";
import {useNavigate} from "react-router-dom";
import {getUserToken, refreshToken, removeUser, tokenToUser, updateUserToken} from "../../services/userService";
import {useAuth} from "../../contexts/auth2";
import OrderService from "../../services/orderService";
import OrderUpdateModel from "../../models/orderUpdate.model";
import {StatusEnum} from "../../models/status.enum";
import { SxProps } from "@material-ui/system";
import { flexColumn } from "../../utils/MUIShortcuts";

const containerStyles: SxProps = {
    ...flexColumn('center', 'flex-start'),
    minHeight: '90vh',
    padding: '0 8px !important',
    overflowY: 'auto'
}

const MercadoPagoPage: FC = () => {
    const {setModalContent, setModalOpen} = useModal()
    const navigate = useNavigate()
    const {setUser} = useAuth()

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const paymentId = queryParams.get("payment_id") as string
        const status = queryParams.get("status") as string
        const externalReference = queryParams.get("external_reference") as string ?? ""
        const statusAccepted = [StatusEnum.PENDING, StatusEnum.APPROVED, StatusEnum.IN_PROCESS]
        const isPreapproval = queryParams.get("is_preapproval") as string ?? "false"

        if (status == null) {
            setModalContent(<MCAlert title="Erro inesperado"
                                     text="Ocorreu um erro inesperado, por favor entrar em contato com o suporte"
                                     onClose={() => {
                                         setModalOpen(false)
                                         navigate('/dashboard/app')
                                     }}/>)
            setModalOpen(true)
            return;
        }

        if (statusAccepted.indexOf(status as StatusEnum)) {
            let message = "Não foi possível realizar o pagamento"
            let title = "Erro"

            if (status === 'approved') {
                message = 'O pagamento foi aprovado e você já pode utilizar os recursos do plano'
                title = "Parabéns!!! 🎉🎉"
            } else if (status === 'in_process' || status === 'pending') {
                message = 'Após o período de verificação você poderá aproveitar todos os benefícios'
                title = "Aguardando aprovação do pagamento"
            }

            setModalContent(
                <MCAlert
                    title={title}
                    text={message}
                    buttonText="Fechar"
                />
            )
            setModalOpen(true)
            OrderService.update(paymentId, {
                status,
                acquirerId: 1,
                externalReference,
                isPreapproval: isPreapproval === 'true'
            } as OrderUpdateModel).then(response => {
                refreshToken(getUserToken() ?? '').then(res => {
                    removeUser()
                    // @ts-ignore
                    updateUserToken(res.data.newToken)
                    setUser(tokenToUser())
                    navigate('/dashboard/app')
                })
            }).catch(error => {
                setModalContent(<MCAlert title="Erro inesperado"
                                         text="Ocorreu um erro inesperado, por favor entrar em contato com o suporte"
                                         onClose={() => {
                                             setModalOpen(false)
                                             navigate('/dashboard/app')
                                         }}/>)
                setModalOpen(true)
            })
        } else {
            setModalContent(<MCAlert title="Erro ao processar pagamento" text="Um erro ocorreu durante o pagamento"
                                     onClose={() => {
                                         setModalOpen(false)
                                         navigate('/dashboard/app')
                                     }}/>)
            setModalOpen(true)
        }

    }, [])

    return (
        <Container sx={containerStyles} />
    );
}

export default MercadoPagoPage