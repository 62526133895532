import React, {useCallback, useEffect, useState} from "react";
import {getActivesPlans, getPlanById} from "../../../services/plan-service";
import PlanModel from "../../../models/plan.model";
import {PlanItem} from "./plan-item";
import styled from "styled-components";
import {useAuth} from "../../../contexts/auth2";
import scriptjs from "scriptjs";
import {ACQUIRER} from "../../../models/AcquirerModel";
import OrderModel from "../../../models/OrderModel";
import {useModal} from "../../../contexts/modal";
import MCAlert from "../../../components/ModalContents/MCAlert";
import OrderService from "../../../services/orderService";
import MCPaymentInfo from "../../../components/ModalContents/MCPaymentInfo";
import {getUserProfile} from "../../../services/profileService";
import MCOrderInfo from "../../../components/ModalContents/MCOrderInfo";
import PreapprovalModel from "../../../models/PreapprovalModel";
import UpdatePreapprovalModel from "../../../models/UpdatePreapprovalModel";
import ResponsePreapprovalModel from "../../../models/ResponsePreapproval.model";
import MCConfirm from "../../../components/ModalContents/MCConfirm";
import {getUserToken, refreshToken, removeUser, tokenToUser, updateUserToken} from "../../../services/userService";
import {useNavigate} from "react-router";
import moment from 'moment';
import { LoadingLayout } from "../../../layouts/base/loading.layout";

const Rows = styled.div`
  display: flex;
  flex-direction: column;

@media screen and (max-height: 768px) {
    flex-wrap: wrap; 
    flex-direction: row;
    justify-content: center;
    width: 90%;
    align-self: center;
    display: flex;
  }

  @media screen and (min-width: 768px) {
    width: 90%;
    flex-direction: row;
  }
`

const {REACT_APP_MERCADO_PAGO_PUBLIC_KEY} = process.env;
let cardForm: any = null

export const PlansPage: React.FC = () => {
    const {user} = useAuth()
    const {setModalContent, setModalOpen} = useModal()
    const [loading, setLoading] = useState(false)
    let [plans, setPlans] = useState<PlanModel[]>([])
    const [dueDate, setDueDate] = useState<number>(0)
    const [planInVerification] = useState(user.unlocked === 0 && user.planId !== null)
    const [preapprovalId, setPreapprovalId] = useState<string>()
    const [isPlanCancelled] = useState(user.cancelled == 1)
    const navigate = useNavigate()
    const {setUser} = useAuth()

    const fetchPlans = useCallback(() => {
        setLoading(true)
        getActivesPlans().then(result => {
            setPlans(result.data)
        }).catch(_ => {
            //setDialogModel({...messageErrorConfirmDialog(dialogModel.onConfirm)})
        }).finally(() => {
            setLoading(false)
        });
    }, [getActivesPlans])

    const getUserPlan = useCallback(() => {
        Promise.all([getPlanById(user.planId), getActivesPlans()])
            .then(value => {
                const userPlan = value[0].data as PlanModel[]
                const availablePlans = value[1].data as PlanModel[]

                const plansSet = new Set<string>();

                plansSet.add(JSON.stringify(userPlan));
                availablePlans.forEach(plan => {
                    plansSet.add(JSON.stringify(plan))
                })

                setPlans(Array.from(plansSet).map(value => JSON.parse(value)))
            })
    }, [getPlanById, getActivesPlans, setPlans])

    const fetchProfile = () => {
        getUserProfile(user.idUser)
            .then(value => {
                const response = value.data;

                const lastDay = new Date(response.dat_end_plan).getTime();
                const today = new Date().getTime();
                const diffTime = Math.abs(lastDay - today);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                setPreapprovalId(response.preapproval_id)
                setDueDate(diffDays)
            })
    }

    useEffect(() => {
        fetchProfile()
        if (user.planId) {
            getUserPlan()
        } else {
            fetchPlans()
        }
    }, [fetchPlans, getUserPlan])

    const joinPlainMercadoPago = (document: string, planId: number) => {
        new Promise((resolve, reject) => {
            scriptjs('https://sdk.mercadopago.com/js/v2', async () => {
                try {
                    const orderModel = {
                        userId: user.idUser,
                        planId: planId,
                        acquirerId: ACQUIRER.MERCADO_PAGO.value,
                        document: document
                    } as OrderModel

                    const result = await OrderService.create(orderModel)
                    setModalContent(
                        <MCAlert
                            title='Pagamento'
                            text="Você será redirecionado para a página de pagamentos"
                        />
                    )
                    setModalOpen(true)
                    setTimeout(() => {
                        window.location.assign(result.data.redirect);
                    }, 2000)
                } catch (e) {
                    setModalContent(<MCAlert title="Erro"
                                             text="Um erro ocorreu durante o processamento das informações"/>)
                    setModalOpen(true)
                }
            })
        })
    }

    const handlePlanSelected = (plan: PlanModel, codPayment?: string) => {
        if (plan.preapproval_enabled) {
            new Promise((resolve, reject) => {
                scriptjs('https://sdk.mercadopago.com/js/v2', async () => {
                    const callback = () => setTimeout(() => {

                        if (cardForm != null) {
                            cardForm.unmount()
                        }

                        // @ts-ignore
                        const mercadopago = new MercadoPago(REACT_APP_MERCADO_PAGO_PUBLIC_KEY);

                        cardForm = mercadopago.cardForm({
                            amount: plan.value.toString(),
                            iframe: true,
                            form: {
                                id: "form-checkout",
                                cardNumber: {
                                    id: "form-checkout__cardNumber",
                                    placeholder: "* Número do cartão",
                                },
                                expirationDate: {
                                    id: "form-checkout__expirationDate",
                                    placeholder: "* Validade (mm/AA)",
                                },
                                securityCode: {
                                    id: "form-checkout__securityCode",
                                    placeholder: "* Código de segurança",
                                },
                                cardholderName: {
                                    id: "form-checkout__cardholderName",
                                    placeholder: "* Titular do cartão",
                                },
                                issuer: {
                                    id: "form-checkout__issuer",
                                    placeholder: "Bandeira",
                                },
                                installments: {
                                    id: "form-checkout__installments",
                                    placeholder: "Parcelas",
                                },
                                identificationType: {
                                    id: "form-checkout__identificationType",
                                    placeholder: "Tipo de documento",
                                },
                                identificationNumber: {
                                    id: "form-checkout__identificationNumber",
                                    placeholder: "* Número do documento",
                                },
                                cardholderEmail: {
                                    id: "form-checkout__cardholderEmail",
                                    placeholder: "E-mail",
                                },
                            },
                            callbacks: {
                                onError: (error: any) => {
                                    console.log(error)
                                },
                                onFormMounted: (error: any) => {
                                    if (error) {
                                        messageError()
                                        return;
                                    }

                                    setTimeout(() => {
                                        document.querySelectorAll("[id=form-checkout], .warning-fields").forEach((x: any) => x.style.display = '')
                                        // @ts-ignore
                                        document.querySelector("[id=circularProgress]").style.display = 'none'
                                    }, 1500)
                                },
                                onSubmit: async (event: { preventDefault: () => void; }) => {
                                    await submitPlanPreapproval(event, plan, codPayment)
                                },
                                onFetching: (resource: any) => {
                                    console.log("Fetching resource: ", resource);

                                    return () => {

                                    }
                                }
                            },
                        });
                    }, 1000)
                    setModalContent(
                        <MCPaymentInfo callback={callback}/>
                    )
                    setModalOpen(true)

                })
            })

            return
        }

        setModalContent(
            <MCOrderInfo onAssign={(values) => joinPlainMercadoPago(values.cpf, plan.id as number)}/>
        )
        setModalOpen(true)
    }

    const submitPlanPreapproval = async (event: { preventDefault: () => void; }, plan: PlanModel, codPayment?: string) => {
        event.preventDefault();

        try {
            const {token} = cardForm.getCardFormData();
            const updatePreapproval = {
                userId: user.idUser,
                cardToken: token
            } as UpdatePreapprovalModel

            if (codPayment != null && codPayment != "") {
                await updatePlanPreapproval(codPayment, updatePreapproval)
                return;
            }

            const preapproval = {
                userId: user.idUser,
                planId: plan.id,
                acquirerId: ACQUIRER.MERCADO_PAGO.value,
                cardToken: token
            } as PreapprovalModel
            await createPlanPreapproval(preapproval)
        } catch (e) {
            let message: string | undefined = undefined
            // @ts-ignore
            if (e.response != null && e.response.status >= 400 && e.response.status <= 499) {
                // @ts-ignore
                message = e.response.data.message
            }

            messageError(message)
        }
    }

    const updatePlanPreapproval = async(codPayment: string, updatePreapproval: UpdatePreapprovalModel) => {
        setModalOpen(false)
        setLoading(true)
        await OrderService.updatePreapproval(codPayment, updatePreapproval)
        setLoading(false)
        setModalContent(
            <MCAlert
                title='Pagamento'
                text="Os dados de pagamento foram alterados com sucesso!"
            />
        )
        setModalOpen(true)
        return
    }

    const createPlanPreapproval = async (preapproval: PreapprovalModel) => {
        setModalOpen(false)
        setLoading(true)
        const response = await OrderService.createPreapproval(preapproval)
        const {paymentId, status, externalReference} = response.data as ResponsePreapprovalModel

        window.location.assign(`/notification/mercadopago?payment_id=${paymentId}&status=${status}&external_reference=${externalReference}&is_preapproval=true`);
    }

    const messageError = (message?: string) => {
        setModalOpen(false)
        setLoading(false)
        setModalContent(
            <MCAlert
                title='Erro'
                text={message == null ? "Aconteceu um erro inesperado tente novamente" : message}
            />
        )
        setModalOpen(true)
    }

    const cancelPlanPreapproval = async (codPayment: string) => {
        setModalContent(
            <MCConfirm
                title="Cancelar Plano"
                text="<p>Deseja realmente cancelar sua assinatura? </p> <p><b>Essa ação é irreversível! </b> </p>"
                confirmButtonText="CONFIRMAR"
                cancelButtonText="CANCELAR"
                onConfirm={async () => {
                    setModalOpen(false)
                    setLoading(true)
                    await OrderService.cancelPreapproval(codPayment)
                    await messageSuccessPlanPreapprovalCancelled()
                }}
                onCancel={() => setModalOpen(false)}
            />
        )
        setModalOpen(true)
    }

    const messageSuccessPlanPreapprovalCancelled = async () => {
        const response = await getUserProfile(user.idUser)
        const result = response.data;
        let message = "Seu plano foi cancelado com sucesso!"

        if (result.dat_end_plan != null && result.dat_end_plan.toString().trim() != "") {
            message = `Seu plano foi cancelado com sucesso! Você pode continuar com seus benefícios até ${moment(result.dat_end_plan).format("DD/MM/YYYY")}`
        }

        setModalOpen(false)
        setModalContent(<MCAlert title="Plano Cancelado"
                                 text={message}
                                 onClose={() => {
                                     refreshToken(getUserToken() ?? '').then(res => {
                                         removeUser()
                                         // @ts-ignore
                                         updateUserToken(res.data.newToken)
                                         setUser(tokenToUser())
                                         setModalOpen(false)
                                         navigate('/dashboard/app')
                                     })
                                 }}/>)
        setModalOpen(true)
    }

    return (
        <LoadingLayout loading={loading} hasLogo={true}>
            <Rows >
                {
                    plans.map(plan =>
                        <PlanItem
                            plan={plan}
                            dueDatePlan={dueDate || 0}
                            planInCheck={planInVerification}
                            userHasPlan={user.planId !== undefined && user.planId !== null}
                            isActualPlan={user.planId === plan.id}
                            onPlanSelected={() => handlePlanSelected(plan, preapprovalId)}
                            onCancelPlan={() => cancelPlanPreapproval(preapprovalId as string)}
                            isPreapproval={plan.preapproval_enabled && preapprovalId != null && preapprovalId != ""}
                            isPlanCancelled={isPlanCancelled}
                            isPlanPreapproval={plan.preapproval_enabled}/>
                    )
                }
            </Rows>
        </LoadingLayout>
    )
}