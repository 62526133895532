import React, { useCallback, useEffect, useState } from 'react'
import {
    getConsultReviewProducts,
    updateConsultationPercentages,
    UpdateConsultationPercentagesEntries
} from "../../../services/history"
import { handlePromise } from "../../../utils/handlePromisse"
import Space from "../../../components/Space"
import { ArrowBack, Edit, PictureAsPdf, Save } from "@material-ui/icons"
import VehiclesModel from "../../../models/vehicles.model"
import { useNavigate, useParams } from "react-router"
import { QueryState } from "../query"
import { SimpleSnackbar } from "../../../components/snackbar"
import { getPdfByConsultationId } from "../../../services/decisionFlow"
import { Loading } from "../../../components/loading"
import { DoseWarningDialog } from "../query/dose/dose-warning.dialog"
import { Container } from "./index.styled";
import { ProductList } from "../../../components/product/product-list";
import { ProductModel } from "../../../models/Product.model";
import { redirectToPDF } from "../../../utils/pdf.util";
import { CircleButton, IconsContainer } from "../../../styles/styles";
import { TextField } from "@material-ui/core";

export const ReviewPage: React.FC = () => {
    const { id } = useParams()

    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [observation, setObservation] = useState("")
    const [hasChanges, setHasChanges] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [nameConsultation, setNameConsultation] = useState<string>('')
    const [vehicles, setVehicles] = useState<VehiclesModel|null>(null)
    const [products, setProducts] = useState<ProductModel[]>([])
    const [productsChanged, setProductsChanged] = useState<{id: number, percentage: string}[]>([])

    const fetchProducts = useCallback(async () => {
        const [result, error] = await handlePromise(getConsultReviewProducts(Number(id) || 0))
        if (!!error) {
            setSnackbarMessage('Erro ao carregar a consulta')
            return
        }
        setHasChanges(false)
        setProducts(result.data.products as ProductModel[])
        if (result.data.vehicles !== null) {
            setVehicles(result.data.vehicles.model as VehiclesModel)
            setObservation(result.data.vehicles.observation)
        }
        if (result.data.name) {
            setNameConsultation(result.data.name)
        }
    }, [setProducts, setVehicles, setObservation, setHasChanges, id])

    useEffect(() => {
        fetchProducts()
    }, [fetchProducts])

    const handleEdit = () => {
        navigate('/dashboard/query', {
            state: { consultationId: id } as QueryState,
        })
    }

    const handleSave = useCallback(async () => {
        const entries: UpdateConsultationPercentagesEntries = productsChanged.map(product => ({
            idProduct: product.id,
            percentage: product.percentage.replace(/%/g, '')
        }))

        const [_, error] = await handlePromise(updateConsultationPercentages(Number(id) || 0, entries, observation, nameConsultation))

        if (error) {
            setSnackbarMessage('Erro ao salvar alterações')
            setShowSnackbar(true)
        } else {
            await fetchProducts()
            setSnackbarMessage('Alterações salvas com sucesso')
            setShowSnackbar(true)
            setHasChanges(false)
        }
    }, [productsChanged, observation, nameConsultation, setHasChanges, updateConsultationPercentages])

    const handleCreatePdf = useCallback(async () => {
        const isValid = validateProducts()
        if (!isValid) {
            setShowWarning(true)
            return
        }

        await generatePdf()
    }, [products, productsChanged])

    const generatePdf = useCallback(async () => {
        setLoading(true)
        const [result, error] = await handlePromise(getPdfByConsultationId([Number(id) || 0], false))
        if (error) {
            setSnackbarMessage('Erro ao gerar PDF')
            setShowSnackbar(true)
            setLoading(false)
            return
        }

        const base64 = result.data.pdf
        redirectToPDF(base64)
        setLoading(false)
    }, [])

    const validateProducts = () => {
        for (const product of products) {
            if (product.percentage) {
                const percentage = parseFloat(product.percentage.replace(/%/g, ''))
                if (percentage < Number(product.minimumDosage) || percentage > Number(product.maxDosage)) {
                    return false
                }
            }
        }
        return true;
    };

    useEffect(() => {
        setHasChanges(productsChanged.length > 0)
    }, [productsChanged])

    const handleDoseChange = useCallback((id: number, percentage: string) => {
        setProductsChanged(prev => {
            const filtered = prev.filter(item => item.id !== id)
            return [...filtered, { id, percentage }]
        });
    }, [setProductsChanged]);


    const handleConfirmDialog = useCallback(async () => {
        setShowWarning(false)
        await generatePdf()
    }, [setShowWarning, handleCreatePdf])

    return (
        <Container>
            <DoseWarningDialog
                open={showWarning}
                onClose={() => setShowWarning(false)}
                onConfirm={handleConfirmDialog}/>

            <Loading open={loading}/>

            <SimpleSnackbar
                show={showSnackbar}
                message={snackbarMessage}
                onDismiss={() => setShowSnackbar(false)}
            />

            <TextField
                label={"Nome da consulta"}
                style={{ width: '100%', marginBottom: '16px' }}
                color={"primary"}
                value={nameConsultation}
                onChange={(e) => {
                    setHasChanges(true)
                    setNameConsultation(e.target.value)
                }}
                InputLabelProps={{
                    shrink: !!nameConsultation,
                }}
                inputProps={{
                    maxLength: 100,
                }}
                helperText={`${nameConsultation.length}/${100}`}
            />

            <ProductList
                products={products}
                vehicles={vehicles}
                observation={observation}
                onDoseChange={handleDoseChange}
                onObservationChange={obs => {
                    setHasChanges(true)
                    setObservation(obs)
                }}
            />

            <Space pixels={16} direction={'v'}/>

            <IconsContainer>
                <CircleButton onClick={()=>{navigate(-1)}}><ArrowBack/></CircleButton>

                <Space pixels={16} direction={'h'}/>
                <CircleButton onClick={handleEdit}><Edit/></CircleButton>

                <Space pixels={16} direction={'h'}/>
                <CircleButton disabled={!hasChanges} onClick={handleSave}><Save/></CircleButton>

                <Space pixels={16} direction={'h'}/>
                <CircleButton onClick={handleCreatePdf} disabled={hasChanges}><PictureAsPdf/></CircleButton>
            </IconsContainer>
            <Space pixels={16} direction={'v'}/>
        </Container>
    )
}
