import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { flexColumn } from "../../utils/MUIShortcuts";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;

const RootStyle = styled("div")({
  width: '100%',
  height: "100vh",
  overflow: "hidden"
});

const MainStyle = styled("div")(({ theme }) => ({
  ...flexColumn('center', 'flex-start'),
  width: '100%',
  height: '100vh',
  overflowY: "scroll",
  paddingTop: APP_BAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    paddingTop: '0',
    paddingLeft: '280px',
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {

  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
