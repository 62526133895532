import http from "./http-common"
import { Details } from "../pages/main/query/products/product-detail";

export const getConsult = (consultId: number) => http.get(`/aestheticConsultation/${consultId}`)

export const deletePatient = (idPatient: number) => http.delete(`/patient/${idPatient}`)

export const editPatientObservations = (entries: any, idPatient: number) => http.patch(`/patient/update/${idPatient}`, entries)

export const getConsultReviewProducts = (consultId: number) => http.get(`/aestheticConsultation/products/${consultId}`)
export type ConsultReviewProduct = {
  "id_Product": number,
  "name_Product": string,
  "description_Product": string,
  "mechanismAction_Product": string,
  "pH_Product": string,
  "incompatibility_Product": string,
  "minimumDosage_Product": string,
  "maxDosage_Product": string,
  "source_Product": string,
  "percentage_ACP": string,
}

export const consultReviewToDetails = (consultReview: ConsultReviewProduct) => {
  return {
    name: consultReview.name_Product,
    mechanism: consultReview.mechanismAction_Product,
    description: consultReview.description_Product,
    ph: consultReview.pH_Product,
    incompatibility: consultReview.incompatibility_Product,
    minimum: consultReview.minimumDosage_Product.toString(),
    maximum: consultReview.maxDosage_Product.toString(),
    source: consultReview.source_Product,
    id: consultReview.id_Product,
  } as Details
}

export const deleteConsult = (consultId: number) => http.delete(`/aestheticConsultation/${consultId}`)

export type UpdateConsultationPercentagesEntries = {
  idProduct: number,
  percentage: string
}[]
export const updateConsultationPercentages = (consultId: number, entries: UpdateConsultationPercentagesEntries, observation: string, name?: string) => {
  const request = { products: entries, observation: observation, name: name }
  return http.put(`/aestheticConsultation/product/${consultId}`, request)
}

export type ConsultationsFromPatientByIdUserResponse = {
  "id_AestheticConsultation": number,
  "date": string
}[]
export const getAllConsultationsFromPatientByIdUser = (userId: number, patientId: number) => http.get(`/aestheticConsultation/history/${userId}/${patientId}`)