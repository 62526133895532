import React, { useState } from "react";
import styled from "styled-components";
import palette from "../../../../theme/palette";
import { Button } from "@material-ui/core";
import { OTPInput } from "../otp/otp";
import Space from "../../../../components/Space";
import { Replay } from "@material-ui/icons";
import { Row } from "../../../main/review/index.styled";

type Props = {
    onValidate: (otp: string) => void;
    onResend: () => void;
    onBack: () => void;
};

const Container = styled.div`
  width: 100%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 16px;
  
  @media screen and (min-width: 960px) {
    margin-top: 50%;
  }
`;

const Title = styled.div`
  width: 100%;
  color: ${palette.primary.dark};
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;

  @media screen and (min-width: 960px) {
    color: ${palette.primary.lighter};
  }
`;

const Text = styled(Title)`
  font-size: 1rem;
  font-weight: 400;
`;

const ResendButton = styled(Button)`
  margin-left: 1rem;
  color: ${palette.primary.dark};

  @media screen and (min-width: 960px) {
    color: ${palette.primary.lighter};
  }
`

export const VerifyEmail: React.FC<Props> = ({ onValidate, onResend, onBack }) => {
    const [otp, setOtp] = useState('')

    const handleOtpChange = (value: string) => {
        setOtp(value);
    };

    return (
        <Container>
            <Title>CONFIRMAÇÃO DE EMAIL</Title>
            <Text>Para sua segurança, enviamos um e-mail para confirmação. Insira o código no campo abaixo exatamente como no e-mail para prosseguir.</Text>

            <Space pixels={16} direction={'v'}/>

            <ResendButton
                fullWidth
                size={'large'}
                color={'info'}
                onClick={onResend}
                variant="text">
                <Replay/>Reenviar
            </ResendButton>

            <Space pixels={16} direction={'v'}/>

            <OTPInput value={otp} onChange={handleOtpChange}/>
            <Text style={{ fontSize: '0.8rem' }}>Por favor, insira o código exatamente como recebeu</Text>

            <Space pixels={16} direction={'v'}/>

            <Button
                fullWidth
                size={'large'}
                onClick={() => onValidate(otp)}
                style={{ backgroundColor: '#627217' }}
                variant="contained">
                Validar Código
            </Button>

            <Row style={{ marginTop: '1rem', width: '100%' }}>
                <Button
                    fullWidth
                    size={'large'}
                    onClick={onBack}
                    color={'secondary'}
                    variant="contained">
                    Voltar
                </Button>
            </Row>

        </Container>
    )
}
