import React from "react";
import styled from "styled-components";
import { LoadingLayout } from "./loading.layout";

type Props = {
    hasLogo?: boolean
    loading: boolean
}

const MainContent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  max-width: 1240px;
  height: 100%;
  margin: 0 auto;
`

export const AdmLayout: React.FC<Props> = ({ hasLogo, loading, children }) => {
    return (
        <MainContent>
            <LoadingLayout loading={loading} hasLogo={hasLogo}/>

            <Content>{children}</Content>
        </MainContent>
    );
}