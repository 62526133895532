import { Container, Modal } from "@material-ui/core"
import { SxProps } from "@material-ui/system"
import React, { createContext, FC, useContext, useState } from "react"
import { flexColumn } from "../utils/MUIShortcuts"

type ModalContextProps = {
  setModalContent: (contentElement: JSX.Element) => void, 
  setModalContainerSxProps: (sxProps: SxProps) => void, 
  setModalOpen: (val: boolean) => void,
  modalOpen: boolean
}

const ModalContext = createContext<ModalContextProps>({
  setModalOpen: () => {},
  setModalContainerSxProps: () => {},
  setModalContent: () => {},
  modalOpen: false
})

export const Modalprovider: FC = ({children}) => {
  const [modalContent, setModalContent] = useState(<div>asd</div>)
  const [modalContainerSxProps, setModalContainerSxProps] = useState<SxProps>({})
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <ModalContext.Provider value={{
      setModalContent,
      setModalContainerSxProps,
      setModalOpen,
      modalOpen
    }}>
      {children}
      <Modal
        open={modalOpen}
      >
        <Container sx={{
          minHeight: '100vh',
          maxWidth: 'none !important',
          border: 'none !important',
          outline: 'none !important',
          ...flexColumn('center', 'center'),
          ...modalContainerSxProps
        }}>
          {modalContent}
        </Container>
      </Modal>
    </ModalContext.Provider>
  )
}

export const useModal = () => useContext(ModalContext)