import React from "react";
import { RibbonBox, RibbonRight } from "./ribbon.styled";

type RibbonProps = {
    text: string
    show: boolean
}
export const RibbonComponent: React.FC<RibbonProps> = ({ text, show, children }) => {
    return (
        <RibbonBox>
            {show && <RibbonRight><span>{text}</span></RibbonRight>}
            {children}
        </RibbonBox>
    )
}