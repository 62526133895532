import React from 'react'
import { SxProps } from '@material-ui/system'
import { flexColumn, flexRow, vh } from '../../../../utils/MUIShortcuts'
import palette from '../../../../theme/palette'

export const containerStyles: SxProps = {
  width: '100%',
  maxWidth: '480px !important',
  minWidth: '300px',
  height: '80vh',
  maxHeight: '680px',
  background: 'white',
  padding: '0 !important'
}

export const btnModal: SxProps = {
  ...flexColumn('center', 'center'),
  width: 'auto',
  minWidth: '130px',
  padding: '2px 8px',
  height: '40px',
  cursor: 'pointer',
  textTransform: 'uppercase',
  borderRadius: '2px'
}

export const formStyles: React.CSSProperties = {
  marginTop: '40px',
  padding: '8px 12px',
}

export const titleStyles: SxProps = {
  width: '100%',
  textAlign: 'center',
  color: palette.primary.dark,
  textTransform: 'uppercase',
  marginTop: '20px'
}

export const btnWrapper: SxProps = {
  ...flexRow('space-between', 'center'),
  height: '80px',
  padding: '20px',
  marginTop: '6px',
}

export const btnFill: SxProps = {
  background: palette.primary.main,
  border: `1px solid ${palette.primary.main}`,
  color: 'white',
  borderRadius: '2px'
}

export const btnOutlined: SxProps = {
  background: 'white',
  border: `1px solid ${palette.primary.main}`,
  color: palette.primary.main,
  borderRadius: '2px'
}

export const btnPrimary: SxProps = {
  background: palette.primary.main,
  ":hover": {
    background: palette.primary.darker
  },
  border: 'none'
}

export const btnSecondary: SxProps = {
    background: palette.secondary.lighter,
    ":hover": {
        background: palette.secondary.main
    }
}