import React, { useCallback, useEffect, useState } from "react";
import { Title } from "../../../../theme/globalStyles"
import { SubClassesObject } from "../../../../services/decisionFlow";
import { CheckableList, CheckableValue } from "../../../../components/checkable-list";
import { getQuery, setQuery } from "../../../../services/local-session-storage";
import { SubClassType } from "../index";
import { ListContainer, QueryContainer } from "../index.styled";
import Space from "../../../../components/Space";
import { CircleButton, SpacedIconsContainer } from "../../../../styles/styles";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { BaseLayout } from "../../../../layouts/base/base.layout";
import TemporaryDrawer from "../products/sidebar";

type CheckableSubclass = {
    active: string
    checkableSubClass: CheckableValue<SubClassesObject>[]
}

type Props = {
    onFinish: () => void
    onBack: () => void
    subclass: SubClassType[]
}

export const SubClass: React.FC<Props> = ({ onFinish, onBack, subclass }) => {

    const model = getQuery()
    const [subClassType, setSubClassType] = useState<CheckableSubclass[]>([])

    const transformSubclass = useCallback((toTransform: SubClassType[]) => {
        const transformedList = toTransform.flatMap(value => {
            const checkable = value.subClass.map((subclass) => {

                return {
                    value: subclass,
                    isSelected: false
                } as CheckableValue<SubClassesObject>
            })
            return {
                active: value.active,
                checkableSubClass: checkable
            } as CheckableSubclass
        })
        setSubClassType(transformedList)
    }, [setSubClassType])

    useEffect(() => {
        transformSubclass(subclass)
    }, [transformSubclass, subclass])

    function getSelectedItems() {
        return subClassType
            .flatMap(list => list.checkableSubClass
                .filter(check => check.isSelected)
                .map(value => value.value)
            )
    }

    const handleContinue = () => {
        setQuery({ subclass: getSelectedItems() })
        onFinish()
    }

    const handleOnChange = useCallback((value, isSelected) => {
        const newList = [...subClassType]
        for (let i = 0; i < newList.length; i++) {
            for (let j = 0; j < newList[i].checkableSubClass.length; j++) {
                const checkableSubClass = newList[i].checkableSubClass[j];
                if (checkableSubClass.value.id === value.id) {
                    checkableSubClass.isSelected = isSelected
                    break
                }
            }
        }
        getSelectedItems()
        setSubClassType(newList)
    }, [subClassType, setSubClassType])

    return (
        <BaseLayout hasLogo={true}>
            <QueryContainer>
                <div>
                    <Title style={{position: 'relative',marginBottom: '20px' }}>
                        SUBCLASSES (Opcional)
                       <TemporaryDrawer/>
                    </Title>
                    <ListContainer>
                        {
                            subClassType.map((types, index) => {
                                return <>
                                    <Title key={index}>{types.active}</Title>
                                    <CheckableList
                                        values={types.checkableSubClass}
                                        onValueChange={handleOnChange}
                                        getElementKey={(value) => value.id}
                                        getElementLabel={(value) => value.name}
                                    />
                                </>

                            })
                        }
                    </ListContainer>
                </div>

                <Space pixels={16} direction={'v'}/>

                <SpacedIconsContainer>
                    <CircleButton onClick={onBack}><ArrowBack/></CircleButton>
                    <CircleButton onClick={handleContinue}><ArrowForward/></CircleButton>
                </SpacedIconsContainer>
            </QueryContainer>
        </BaseLayout>
    )
}