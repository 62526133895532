import React from "react"
import TableCell from "@mui/material/TableCell"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import { IconButton, TablePagination } from "@material-ui/core"
import { UserModel } from "./user.model"
import { applyMask } from "../../../../utils"
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt'
import moment from "moment/moment";

interface Column {
    id: 'name'|'email'|'phone'|'planName'|'datEndPlan'|'actions'
    label: string
    minWidth?: number
    align?: 'right'
    format?: (key: string) => string
}

const columns: readonly Column[] = [
    { id: 'name', label: 'Nome' },
    { id: 'email', label: 'Email' },
    { id: 'planName', label: 'Plano' },
    { id: 'phone', label: 'Telefone', format: (phone: string|null) => phone ? applyMask('phone').onValue(phone) : '' },
    { id: 'datEndPlan', label: 'Fim plano', format: (date: string|null) => date ? moment(date).utcOffset(-180).format('DD/MM/YYYY') : '' },
    { id: 'actions', label: 'Ações', minWidth: 20 },
]

type Props = {
    page: number
    size: number
    total: number
    data: UserModel[]
    onCancelPlan: (userId: number) => void
    onPageChange: (newPage: number, size: number) => void
    onSizeChange: (newSize: number, page: number) => void
}

export const UserTable: React.FC<Props> = ({
   data, page, size, total,
   onCancelPlan, onPageChange, onSizeChange,
}) => {

    const handleChangePage = (event: unknown, newPage: number) => {
        onPageChange(newPage + 1, size)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSizeChange(+event.target.value, 0)
    }

    const actions = (model: UserModel) => {
        return (
            <>
                {
                    model.planName ? 
                        <IconButton color="primary" onClick={() => onCancelPlan(model.id)}><DoNotDisturbAltIcon/></IconButton>
                        : <IconButton disabled><DoNotDisturbAltIcon/></IconButton>
                }
            </>
        )
    }

    const createRows = (column: Column, model: UserModel) => {
        const value = model[column.id]
        if (column.id === 'datEndPlan') console.log(value)
        return (
            <TableCell key={column.id} align={column.align}>
                {
                    column.id === "actions" ? actions(model) :
                        column.format ? column.format(value) : value
                }
            </TableCell>
        )
    }

    const tableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}>
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    const tableBody = () => {
        return (
            <TableBody>
                {
                    data.map((gift) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={gift.email}>
                                {columns.map((column) => createRows(column, gift))}
                            </TableRow>
                        )
                    })}
            </TableBody>
        )
    }

    return (
        <Paper>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="active table">
                    {tableHeader()}
                    {tableBody()}
                </Table>
            </TableContainer>

            <TablePagination
                page={page - 1}
                component="div"
                count={total}
                rowsPerPage={size}
                onPageChange={handleChangePage}
                labelRowsPerPage={"Linhas por página"}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
        </Paper>
    )
}