import { ThemeOptions } from "@material-ui/core";
import palette from "../palette";

// "MuiOutlinedInput-notchedOutline": {borderRadius: '2px'}
export default function TextField(theme: any): ThemeOptions["components"] {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: palette.primary.main,
          outlineColor: palette.primary.main,
          // background: 'red'
        }
      }
    }
  }
}