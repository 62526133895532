import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Emoji from "./emoji";
import { useNavigate } from "react-router-dom";

type BaseMessageDialog = {
    open: boolean
    onClose?: () => void
}

export type PropsMessageDialog = BaseMessageDialog&{
    emoji?: any
    title?: string
    message: string
    onConfirm: () => void
    confirmButtonText: string,
    cancelButtonText?: string,
}

export const messageErrorConfirmDialog = (onConfirm: () => void): PropsMessageDialog => {
    return {
        title: "Erro!",
        message: "Ocorreu um erro inesperado",
        confirmButtonText: "Ok",
        open: true,
        emoji: "😢",
        onConfirm
    }
}

export const messageSuccessConfirmDialog = (onConfirm: () => void, message: string): PropsMessageDialog => {
    return {
        title: "Sucesso!",
        message: message,
        confirmButtonText: "Ok",
        open: true,
        onConfirm
    }
}

export const MessageDialog: React.FC<PropsMessageDialog> = ({ emoji, open, title, message, confirmButtonText, cancelButtonText, onClose, onConfirm }) => {
    return (
        <Dialog open={open} onClose={() => onClose} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            {title && <DialogTitle>{title}{!!emoji && <Emoji symbol={emoji}/>}</DialogTitle>}
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!!onClose && <Button onClick={onClose}>
                    {cancelButtonText}
                </Button> }
                <Button onClick={onConfirm}>
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const MessagePayedResourceDialog: React.FC<BaseMessageDialog> = ({ open, onClose }) => {
    const navigate = useNavigate()

    const handleConfirm = () => {
        navigate('/dashboard/pricing')
        onClose?.()
    }

    return (
        <MessageDialog
            open={open}
            onClose={onClose}
            onConfirm={handleConfirm}
            confirmButtonText="Fazer agora"
            cancelButtonText="Fazer depois"
            title="Você acabou de encontrar um recurso pago"
            message="Deseja fazer um upgrade de plano para continuar?"
        />
    )
}

export const MessageLockedResourceDialog: React.FC<BaseMessageDialog> = ({ open, onClose }) => {
    const handleConfirm = () => {
        onClose?.()
    }
    return (
        <MessageDialog
            open={open}
            onConfirm={handleConfirm}
            confirmButtonText="Ok"
            title="Sua assinatura ainda está em verificação."
            message="Em breve este recurso estará disponível."
        />
    )
}
