import React, {useCallback, useState} from "react";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField
} from "@material-ui/core";
import PlanModel from "../../../models/plan.model";
import CurrencyTextField from "../../../components/currency-text-field";
import styled from "styled-components";

type Props = {
    open: boolean
    onClose: () => void
    plan?: PlanModel
    onConfirm: (plan: PlanModel, id?: number) => void
}

type Options = {
    label: string
    value: string
}

const options: Options[] = [
    { label: '1 mês', value: '30' },
    { label: '2 meses', value: '60' },
    { label: '3 meses', value: '90' },
    { label: '4 meses', value: '120' },
    { label: '6 meses', value: '180' },
    { label: '12 meses', value: '365' }
]

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 8px;
  flex-wrap: wrap;
`

export const NewPlan: React.FC<Props> = ({ open, onClose, onConfirm, plan }) => {
    const [name, setName] = useState<string>(plan?.name || '');
    const [value, setValue] = useState<number>(plan?.value || 0);
    const [option, setOption] = useState<Options>(
        options.find(element => plan?.daysOfUse?.toString() == element.value) || options[0]
    );
    const [newFeat, setNewFeat] = useState("")
    const [features, setFeatures] = useState<string[]>(plan?.features || [])
    const [isPreapprovalRegistered] = useState<boolean>(plan?.preapproval_enabled || false)
    const [preapproval, setPreapproval] = useState<boolean>(plan?.preapproval_enabled || false)

    const handleChange = useCallback((event: SelectChangeEvent) => {
        const find = options.find(element => element.value === event.target.value);
        setOption(find || options[0])
    }, [setOption])

    const onHandleConfirm = useCallback(() => {
        const newPlan = {
            name: name || "",
            value: value || 0,
            daysOfUse: parseInt(option.value),
            features: features,
            preapproval_enabled: preapproval
        } as PlanModel
        onConfirm(newPlan, plan?.id)
    }, [name, value, option, preapproval, features])

    const handleKeyPress = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && newFeat.length > 0) {
            event.preventDefault()
            setFeatures([...features, newFeat])
            setNewFeat("")
        }
    }, [setFeatures, features, newFeat, setNewFeat])

    const handleChipDelete = useCallback((feat: string) => {
        const newFeatures = features.filter(value1 => value1 !== feat);
        setFeatures(newFeatures)
    },[features, setFeatures])

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Novo Plano</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Nome"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{ marginTop: "16px", width: '100%', minWidth: '320px' }}/>

                <CurrencyTextField
                    label={"Valor total"}
                    value={value}
                    onChange={(value) => setValue(value)}
                    sx={{ marginTop: "16px", width: '100%', minWidth: '320px' }}
                />

                <FormControl sx={{ marginTop: "16px", width: '100%', minWidth: '320px' }}>
                    <InputLabel id="simple-select">Periodo</InputLabel>
                    <Select
                        label="Periodo"
                        value={option.value}
                        labelId="simple-select"
                        onChange={handleChange}>
                        {options.map(option =>
                            <MenuItem value={option.value}>{option.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>

                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={preapproval}
                                         disabled={isPreapprovalRegistered}
                                         onChange={(event) => setPreapproval(event.target.checked as unknown as boolean)}
                                         color="primary"/>} label="Pagamento recorrente?"/>
                </FormGroup>

                <TextField
                    fullWidth
                    label="Caracteristicas"
                    variant="outlined"
                    value={newFeat}
                    onKeyPress={event => handleKeyPress(event)}
                    onChange={(e) => setNewFeat(e.target.value)}
                    sx={{ marginTop: "16px", width: '100%', minWidth: '320px' }}/>

                <ChipContainer>
                    {features.map((feat) => {
                        return <Chip
                            size="small"
                            label={feat}
                            variant="filled"
                            color={"primary"}
                            onDelete={_ => handleChipDelete(feat)}
                            style={{ marginRight: '16px', marginTop: '8px' }}/>
                    })}
                </ChipContainer>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={onHandleConfirm}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}
