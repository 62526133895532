import http from './http-common'

export type GiftRequest = {
    email: string
    plan: number
}

export type GiftFilter = {
    page: number,
    size: number,
    search?: string
}

export const createGifts = (gift: GiftRequest[]) => http.post('/gift', gift)

export const getGiftsPaginated = (filter: GiftFilter) => {
    let request = `/gift?`
    if (filter.search !== undefined) request = request + `search=${encodeURIComponent(filter.search)}&`
    request = request + `size=${filter.size}&page=${filter.page}`
    return http.get(request)
}


